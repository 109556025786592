import { TEXT_DIRECTION } from "../../../helper/helperFunctions";
import CustomMultiSelect from "../../../components/form-input/multi-select";
import { CountryListDTO, GenaricListDTO } from "../DTO/GenericListDTO";
import CustomDateSelect from "../../../components/form-input/multi-date-select";
import dateIcon from "../../../assets/images/select-date.svg";
interface FilterFormIProps {
  countryList: CountryListDTO[];
  statusList?: GenaricListDTO[];
  planList?: GenaricListDTO[];
  seatsList?: GenaricListDTO[];
  daysList?: any;
  setCountryList: any;
  setSeatsList?: any;
  setstatusList?: any;
  setPlanList?: any;
  handleSelectCountry: any;
  handleSelectAllCountry: any;
  handleSelectAllStatus?: any;
  handleSelectAllSeats?: any;
  handleSelectAllPlan?: any;
  handleSelectPlan?: any;
  handleSelectSeats?: any;
  handleSelectStatus?: any;
  handleReset: any;
  handleSelectDays?: any;
  handleSelectAllDays?: any;
  selectedDate?: any;
  handleCountrySearch: any;
  setselectedValue?: any;
  setIsOpen?: any;
  isOpen?: boolean;
  setIsOpen2?: any;
  isOpen2?: boolean;
  selectedValue?: any;
  setSelectedDate?: any;
  options?: any;
  slectedLable?: any;
  setslectedLable?: any;
  usersFrom?: any;
  handleUserFrom?: any;
  usersTo?: any;
  handlerUserTo?: any;
  selectedLastActivityValue?: any;
  setselectedLastActivityValue?: any;
  slectedLastActivityLable?: any;
  setslectedLastActivityLable?: any;
  setSelectedLastActivityDate?: any;
  selectedLastActivityDate?: any;
  id?: string;
  deletedCompany?: boolean;
  daysMin?: number | string;
  daysMax?: number | string;
  handleDaysMin?: any;
  handleDaysMax?: any;
  countrySearchText?: string;
  setCountrySearchText?: any;
  setselectedRegisterState?: any;
}
function FilterForm({
  statusList,
  planList,
  seatsList,
  countryList,
  setCountryList,
  setSeatsList,
  setstatusList,
  setPlanList,
  daysList,
  countrySearchText,
  handleSelectCountry,
  handleSelectAllCountry,
  handleCountrySearch,
  handleSelectAllStatus,
  handleSelectAllSeats,
  handleSelectAllPlan,
  handleSelectPlan,
  handleSelectSeats,
  handleReset,
  handleSelectStatus,
  handleSelectAllDays,
  handleSelectDays,
  setselectedValue,
  setselectedRegisterState,
  selectedDate,
  deletedCompany,
  setIsOpen,
  isOpen,
  setIsOpen2,
  isOpen2,
  selectedValue,
  setSelectedDate,
  options,
  slectedLable,
  setslectedLable,
  usersFrom,
  handleUserFrom,
  usersTo,
  handlerUserTo,
  selectedLastActivityValue,
  setselectedLastActivityValue,
  selectedLastActivityDate,
  setSelectedLastActivityDate,
  setslectedLastActivityLable,
  slectedLastActivityLable,
  id,
  daysMin,
  daysMax,
  handleDaysMin,
  handleDaysMax,
  setCountrySearchText,
}: FilterFormIProps) {
  let options2 = options;
  // console.log(slectedLastActivityLable, "slectedLastActivityLable");
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        position: "absolute",
        left: "2px",
        width: "270px",
        marginTop: "10px",
        paddingLeft: "15px",
        paddingTop: "27px",
        zIndex: 100,
      }}
      className="shadow"
    >
      <div>
        <div
          className={`reset ${TEXT_DIRECTION() === "rtl" ? "float-left" : "float-right"}`}
          onClick={() => {
            handleReset();
          }}
          style={{
            color: "#2D9CDB",
            fontWeight: 500,
            fontSize: "14px",
            cursor: "pointer",
            lineHeight: "15.92px",
            marginTop: "-36px",
            paddingLeft: "3px",
            paddingRight: "12px",
          }}
        >
          {/* {i18n("Analytics.ResetAll")} */}
          Reset All
        </div>

        <div className="custom-multi-select mt-4 ">
          {/* <span className="form-lable">{i18n("Analytics.Form")}</span> */}
          <span className="form-lable">Country:</span>
          <CustomMultiSelect
            options={countryList}
            onSelect={() => {}}
            handleSelectAll={handleSelectAllCountry}
            handleSelectedItem={handleSelectCountry}
            handleSearch={handleCountrySearch}
            formField={"COUNTRY"}
            countrySearchText={countrySearchText}
            setCountrySearchText={setCountrySearchText}
          />
        </div>
        {deletedCompany ? (
          // <div className="custom-multi-select my-4">
          //   {/* <span className="form-lable">Days:</span>
          //   <CustomMultiSelect options={daysList} onSelect={() => {}} handleSelectAll={handleSelectAllDays} handleSelectedItem={handleSelectDays} /> */}
          // </div>
          <div className="custom-multi-select mt-4 pb-4">
            <span className="form-lable">Days:</span>
            <div className="d-flex align-items-center justify-content-between w-100" style={{ gap: "1rem" }}>
              <input type="text" className="w-50 short-select-field" placeholder="Min" value={daysMin} onChange={handleDaysMin} />
              <input type="text" className="w-50 short-select-field" placeholder="Max" value={daysMax} onChange={handleDaysMax} />
            </div>
          </div>
        ) : (
          <>
            <div className="custom-multi-select mt-4">
              <span className="form-lable ">Plan:</span>
              <CustomMultiSelect options={planList} onSelect={() => {}} handleSelectAll={handleSelectAllPlan} handleSelectedItem={handleSelectPlan} />
            </div>
          </>
        )}
        {!deletedCompany && (
          <>
            {/* <div className="custom-multi-select mt-4">
              <span className="form-lable">Status:</span>
              <CustomMultiSelect
                options={statusList}
                onSelect={() => {}}
                handleSelectAll={handleSelectAllStatus}
                handleSelectedItem={handleSelectStatus}
              />
            </div> */}

            <div className="custom-multi-select my-4">
              <span className="form-lable">Register Date:</span>
              <div className="date-wrapper-main pr-2">
                <div>
                  {selectedDate?.startDate}-{selectedDate?.endDate}
                </div>
                <div>
                  <img src={dateIcon} alt="date icon" onClick={() => setIsOpen(!isOpen)} />
                </div>
              </div>
              <CustomDateSelect
                setselectedValue={setselectedValue}
                selectedValue={selectedValue}
                options={options}
                setslectedLable={setslectedLable}
                slectedLable={slectedLable}
                setSelectedDate={setSelectedDate}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                formField="register"
                filtersView={true}
                // selectedDate={selectedDate}
              />
            </div>
            <div className="custom-multi-select my-4">
              <span className="form-lable">Last Activity Date:</span>
              <div className="date-wrapper-main pr-2" style={{ width: "100%", justifyContent: "space-between" }}>
                <div>
                  {selectedLastActivityDate?.startDate}-{selectedLastActivityDate?.endDate}
                </div>
                <div>
                  <img src={dateIcon} alt="date icon" onClick={() => setIsOpen2(!isOpen2)} />
                </div>
              </div>
              <CustomDateSelect
                setselectedValue={setselectedLastActivityValue}
                selectedValue={selectedLastActivityValue}
                options={options2}
                setslectedLable={setslectedLastActivityLable}
                slectedLable={slectedLastActivityLable}
                setSelectedDate={setSelectedLastActivityDate}
                isOpen={isOpen2}
                setIsOpen={setIsOpen2}
                formField="LastActivity"
                filtersView={true}

                // selectedDate={selectedDate}
              />
            </div>

            <div className="custom-multi-select mt-4 pb-4">
              <span className="form-lable">Number Of Users:</span>
              <div className="d-flex align-items-center justify-content-between w-100" style={{ gap: "1rem" }}>
                <input type="text" className="w-50 short-select-field" placeholder="From" value={usersFrom} onChange={handleUserFrom} />
                <input type="text" className="w-50 short-select-field" placeholder="To" value={usersTo} onChange={handlerUserTo} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default FilterForm;
