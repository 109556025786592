import { useSelector } from "react-redux";
import Table from "../table/Table";
import { StoreState } from "../../../../redux/storeState/storeState";

interface StoreDetailsIProp {
  setSelectedOption: any;
  rolesHeadData: any;
  roles: any;
  page: number;
  size: number;
  setpage: any;
  setsize: any;
  total: number;
  handleRolesSearch: any;
  handleSortRolesClick: any;
  handleRolesSort: any;
  rolesSearchText: any;
  selectedRolesHeader: any;
  setRolesSearchText: any;
  resetHandler: any;
}
function RolesDetails({
  setSelectedOption,
  page,
  setpage,
  setsize,
  size,
  total,
  handleRolesSearch,
  handleRolesSort,
  handleSortRolesClick,
  roles,
  rolesHeadData,
  rolesSearchText,
  selectedRolesHeader,
  setRolesSearchText,
  resetHandler,
}: StoreDetailsIProp) {
  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; Roles</div>
        <button
          className="back-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Business ID {companyInfo?.businessId}</div>
      <div className="mt-3">
        <Table
          setSelectedOption={setSelectedOption}
          tableHeadData={rolesHeadData}
          tableBodyData={roles}
          page={Number(page)}
          size={Number(size)}
          setPage={setpage}
          setSize={setsize}
          total={total}
          handleSiteSearch={handleRolesSearch}
          handleArrowSortClick={handleSortRolesClick}
          handleSort={handleRolesSort}
          searchText={rolesSearchText}
          selectedSiteHeader={selectedRolesHeader}
          setsiteSearchText={setRolesSearchText}
          subscriptionView={false}
          placeholder="Search roles"
          singleDetailPath="roles_single"
          resetHandler={resetHandler}
        />
      </div>
    </div>
  );
}

export default RolesDetails;
