import { useSidebar } from "../../Pages/useSidebarProvider";

function Footer() {
  const { collapsed } = useSidebar();
  return (
    <div className="companies-footer" style={{ paddingLeft: !collapsed ? "19rem" : "4.9rem" }}>
      <span>2023 © Fatoraty </span>
    </div>
  );
}

export default Footer;
