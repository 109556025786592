import React, { useRef } from "react";
import successIcon from "../../assets/images/successIcon.svg";
function SuccessMessage({ message, setshowSuccessCompletion, style, }: { message: string; setshowSuccessCompletion: any; style?:any}) {
  const successModalOpenRef = useRef<any>();

  const handleOutsideClickDeleteSuccessModal = (event: any) => {
    if (successModalOpenRef.current && !successModalOpenRef.current.contains(event.target)) {
      setshowSuccessCompletion(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickDeleteSuccessModal);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickDeleteSuccessModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  setTimeout(() => {
    setshowSuccessCompletion(false);
  }, 1500);

  return (
    <div className="success-modal-main shadow" style={style} ref={successModalOpenRef}>
      <div className="message-wrap">
        <div>
          <img src={successIcon} alt="success-icon" />
        </div>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default SuccessMessage;
