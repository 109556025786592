import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "../Pages/auth/Login";
import ForgotPassword from "../Pages/auth/ForgotPassword";
import ResetPassword from "../Pages/auth/ResetPassword";
import Footer from "../components/Layout/Footer";
// import CompanyOverview from "../Pages/company/companyOverview/CompanyOverview";
// import ViewInvoice from "../Pages/company/companyOverview/subscription/ViewInvoice";
import { useEffect } from "react";
import Companies from "../Pages/companies/companies-main/Companies";
import MainHeader from "../components/Layout/MainHeader";
import { whoAmI } from "../redux/auth/authCrud";
import DeletedCompanies from "../Pages/companies/deleted-companies/DeletedCompanies";
import CompanyOverview from "../Pages/company/companyOverview/CompanyOverview";

function AppRoutes() {
  const navigate = useNavigate();
  useEffect(() => {
    whoAmI()
      .then(() => {
        if (
          window.location.pathname === "/" ||
          window.location.pathname.includes("forgot-password") ||
          window.location.pathname.includes("reset-password")
        )
          navigate("/companies");
      })
      .catch(() => {
        if (!window.location.pathname.includes("forgot-password") && !window.location.pathname.includes("reset-password")) {
          navigate("/");
        }
      });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {window.location.pathname.includes("companies") || window.location.pathname.includes("company") ? <MainHeader /> : null}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/deleted-companies" element={<DeletedCompanies />} />
        <Route path="/company/:id" element={<CompanyOverview />} />
        {/* <Route path="/view-invoice/:id" element={<ViewInvoice />} /> */}
      </Routes>
      {window.location.pathname.includes("companies") || window.location.pathname.includes("company") ? <Footer /> : null}
    </>
  );
}

export default AppRoutes;
