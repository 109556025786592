import { useSelector } from "react-redux";
import { StoreState } from "../../../../redux/storeState/storeState";
function SingleStoreDetails({ setSelectedOption, storeForEdit }: { setSelectedOption: any; storeForEdit: any }) {
  let storeData = [
    {
      label: "Logo",
      value: storeForEdit?.image ? (
        <div className="store-img">
          <img src={storeForEdit?.image} alt="logo" width={200} height={120} style={{ objectFit: "contain" }} />{" "}
        </div>
      ) : (
        ""
      ),
    },
    {
      label: "Name",
      value: storeForEdit?.name,
    },
    {
      label: "Address",
      value: storeForEdit?.address,
    },
    {
      label: "Tax ID Number",
      value: storeForEdit?.taxNumber,
    },
    {
      label: "Details",
      value: storeForEdit?.storeDetails,
    },
    {
      label: "Invoice Foter",
      value: storeForEdit?.description ? storeForEdit?.description : "Thank you for dealing with us!",
    },
    {
      label: "Invoice Language",
      value: storeForEdit?.invoiceLanguage === "en" ? "English" : "Arabic",
    },
    {
      label: "Store Code",
      value: storeForEdit?.identityPrefix,
    },
    {
      label: "Show note",
      value: storeForEdit?.showNotes ? "Enable" : "Disable",
    },
    {
      label: "Show customer information",
      value: storeForEdit?.showCustomerInformation ? "Enable" : "Disable",
    },
  ];
  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">
          Companies &gt; {companyInfo?.businessName} &gt; Stores &gt; {storeForEdit?.name}
        </div>
        <button
          className="back-btn"
          onClick={() => {
            setSelectedOption("stores");
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Business ID {storeForEdit?.businessId}</div>

      <div className="store-wrap mt-3">
        {storeData.map((item: any, index: number) => {
          return (
            <>
              <div key={index}>
                <div>{item.label}</div>
              </div>
              <div>
                <div style={{ color: item.value === "Enable" ? "#2D9CDB" : item.value === "Disable" ? "#EB5757" : "#4F4F4F" }}>{item.value}</div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default SingleStoreDetails;
