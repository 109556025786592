import toast from "react-hot-toast";
import {
  CompanyLogsSearchDTO,
  CompanySearchDTO,
  DeleteCompanySearchDTO,
  PaymentChannelSearchDTO,
  SendEmailDTO,
  SendInvoiceEmailDTO,
  StoreSearchDTO,
  TaxesSearchDTO,
  UserDataDTO,
} from "../../interfaces/companiesInterface";
import * as requestFromServer from "../companies/companiesCrud";
import { AppDispatch } from "../store";
import { companiesSlice, callTypes } from "./companiesSlice";
import { InvoicesSearchDTO, PrinterSearchDTO, SubscriptionSearchDTO } from "../../interfaces/companyInterface";

const { actions } = companiesSlice;

export const getAllCompanies = (searchData: CompanySearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getAllCompanies(searchData)
    .then((res) => {
      dispatch(
        actions.getAllComanies({
          companies: res.data.results,
          page: res.data.page,
          size: res.data.size,
          totalCount: res.data.totalResults,
          filterCount: res.data.totalFilterConunt,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getAllCountries = (searchName?: string, countrySearch?: any) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getAllCountries(searchName)
    .then((res) => {
      if (countrySearch) {
        let data = res?.data;
        let countries;
        if (data?.restrictedData?.length > 0) {
          countries = data?.restrictedData?.map((item: any) => item.label);
        } else {
          countries = undefined;
        }
        if (countrySearch) {
          countrySearch(countries);
        }
      }
      dispatch(
        actions.getAllCountries({
          countries: res.data.restrictedData,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};

export const deleteSelectedCompanies = (ids: any) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .deleteSelectedCompanies(ids)
    .then((res: any) => {})
    .catch((error: any) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const restoreDeletedCompanies = (ids: any) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .restoreDeletedCompanies(ids)
    .then((res: any) => {})
    .catch((error: any) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const suspendSelectedCompanies = (ids: string[], reason: string, isSuspended: boolean) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .suspendSelectedCompanies(ids, reason, isSuspended)
    .then((res: any) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      // toast.error(error?.response?.data?.message);
    });
};

export const getAllDeletedCompanies = (searchData: DeleteCompanySearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getAllDeletedCompanies(searchData)
    .then((res) => {
      dispatch(
        actions.getAllDeletedCompanies({
          companies: res.data.results,
          page: res.data.page,
          size: res.data.size,
          totalCount: res.data.totalCount,
          filterCount: res.data.filteredCount,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getAllDeletedCountries = (searchName?: string, countrySearch?: any) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getAllDeletedCountries(searchName)
    .then((res) => {
      if (countrySearch) {
        let data = res?.data;
        let countries = data?.restrictedData?.map((item: any) => item?.label);
        if (countrySearch) {
          countrySearch(countries);
        }
      }
      dispatch(
        actions.getAllDeletedCountries({
          countries: res.data.restrictedData,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getCompanyInfoById = (id: string, startDate: string, endDate: string) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getCompanyInfoById(id, startDate, endDate)
    .then((res) => {
      dispatch(
        actions.getCompanyInfoById({
          companyInfo: res.data.results[0],
          companyChartsData: {
            chartsData: res.data.userChartToShow,
            xAxisData: res.data.xAxisData,
          },
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};

export const updatedCompanyInfo = (id: string, info: any) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .updatedCompanyInfo(id, info)
    .then((res) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getAllModuleLogs = (searchData?: CompanyLogsSearchDTO) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getAllModuleLogs(searchData!)
    .then((res) => {
      dispatch(
        actions.getAllModuleLogs({
          logs: res.data.results.logs,
          total: res.data.results.totalCount,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};
export const getAllStores = (searchData?: StoreSearchDTO) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getAllStores(searchData!)
    .then((res) => {
      dispatch(
        actions.getAllStores({
          stores: res.data.results,
          total: res.data.totalResults,
          page: res.data.page,
          size: res.data.size,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};
export const getStoreById = (storeId: string) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getStoreById(storeId)
    .then((res) => {
      dispatch(
        actions.getStoreById({
          storeForEdit: res.data.store,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};

export const getAllPos = (searchData?: StoreSearchDTO) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getAllPos(searchData!)
    .then((res) => {
      dispatch(
        actions.getAllPos({
          pos: res.data.results,
          total: res.data.totalResults,
          page: res.data.page,
          size: res.data.size,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};
export const getAllPrintersByPosId = (searchData?: PrinterSearchDTO) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getAllPrintersByPosId(searchData!)
    .then((res) => {
      dispatch(
        actions.getAllPrintersByPosId({
          printers: res.data.results,
          total: res.data.totalResult,
          page: res.data.page,
          size: res.data.size,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};
export const getAllUsers = (searchData?: StoreSearchDTO) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getAllUsers(searchData!)
    .then((res) => {
      dispatch(
        actions.getAllUsers({
          users: res?.data?.results,
          total: res.data.totalResults,
          page: res.data.page,
          size: res.data.size,
          userChartsData: {
            chartsData: res?.data?.userChartToShow,
            xAxisData: res?.data?.xAxisData,
          },
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};
export const getUserById = (userId: string) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getUserById(userId)
    .then((res) => {
      dispatch(
        actions.getUserById({
          user: res.data.results[0],
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};

export const getAllRoles = (searchData?: StoreSearchDTO) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getAllRoles(searchData!)
    .then((res) => {
      dispatch(
        actions.getAllRoles({
          roles: res.data.results,
          total: res.data.totalResults,
          page: res.data.page,
          size: res.data.size,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
    });
};

export const getRoleById = (roleId: string) => (dispatch: AppDispatch | any) => {
  actions.startCall({ callTypes: callTypes.list });
  return requestFromServer
    .getRoleById(roleId!)
    .then((res) => {
      dispatch(
        actions.getRoleById({
          roleForEdit: res.data.role,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );

      toast.error(error?.message);
    });
};
export const getAllPaymentChannels = (searchData: PaymentChannelSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getPaymentChannelsById(searchData)
    .then((res) => {
      dispatch(
        actions.getAllPaymentChannels({
          paymentChannels: res.data.results,
          page: res.data.page,
          size: res.data.size,
          total: res.data.totalResults,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );

      toast.error(error?.message);
    });
};
export const getAllTaxesById = (searchData: TaxesSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getAllTaxesById(searchData)
    .then((res) => {
      dispatch(
        actions.getAllTaxesById({
          allTaxes: res.data.results,
          page: res.data.page,
          size: res.data.size,
          total: res.data.totalResults,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const sendEmailToCompany = (emails: string[], emailsData: SendEmailDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .sendEmailToCompany(emails, emailsData)
    .then((res) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const sendInvoiceEmailToCompany = (email: string, emailsData: SendInvoiceEmailDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .sendInvoiceEmailToCompany(email, emailsData)
    .then((res) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getSubscriptionChartsData = (companyId: string, startDate: string, endDate: string) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getSubscriptionChartsData(companyId, startDate, endDate)
    .then((res) => {
      dispatch(actions.getSubscriptionChartsData({ subscriptionData: [res?.data?.chartsToShow, res?.data?.xAxisData] }));
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getPaymentCardByCompanyId = (companyId: string) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getPaymentCardByCompanyId(companyId)
    .then((res) => {
      dispatch(actions.getPaymentCardByCompanyId({ paymentCard: res.data }));
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getSubscriptionsByCompanyId = (searchData: SubscriptionSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getSubscriptionsByCompanyId(searchData)
    .then((res) => {
      dispatch(
        actions.getSubscriptionsByCompanyId({
          subscriptions: res.data.results,
          page: res.data.page,
          size: res.data.size,
          total: res.data.totalResult,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getSubscriptionInvoicesBySubscriptionId = (searchData: InvoicesSearchDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getSubscriptionInvoicesBySubscriptionId(searchData)
    .then((res) => {
      dispatch(
        actions.getSubscriptionInvoicesBySubscriptionId({
          invoices: res.data.results,
          page: res.data.page,
          size: res.data.size,
          total: res.data.totalResult,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const getSubscriptionInvoiceById = (invoiceId: string) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .getSubscriptionInvoiceById(invoiceId)
    .then((res) => {
      dispatch(
        actions.getSubscriptionInvoiceById({
          invoiceForEdit: res.data.invoice,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
export const updateAdminUserById = (data: UserDataDTO) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callTypes: callTypes.list }));
  return requestFromServer
    .updateAdminUserById(data)
    .then((res) => {})
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
        })
      );
      toast.error(error?.message);
    });
};
