import React from "react";
interface RadioButtonIProps {
  isSelected?: boolean;
  onChange?: any;
  label?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  simpleRadio?: any;
  roundRadio?: any;
}
export function RadioButton({ isSelected, onChange, label, id, name, disabled, simpleRadio, roundRadio }: RadioButtonIProps) {
  return (
    <>
      {!simpleRadio && !roundRadio && (
        <label className="switch">
          <input className="custom-slider" checked={isSelected} name={name} onChange={onChange} id={id} disabled={disabled} type="checkbox" />
          <span className="slider round"></span>
        </label>
      )}

      {simpleRadio && !roundRadio && (
        <>
          <div className="custom-control custom-radio ">
            <input className="custom-control-input " id={id} type="radio" checked={isSelected} onChange={onChange} name={name} disabled={disabled} />
            <label htmlFor={id} className="custom-control-label">
              {label}
            </label>
          </div>
        </>
      )}
      {roundRadio && !simpleRadio && (
        <div>
          <label className="round-switch">
            <input type="checkbox" checked={isSelected} onChange={onChange} id={id} disabled={disabled} />
            <span className="round-slider round"></span>
          </label>
        </div>
      )}
    </>
  );
}
