import moment from "moment";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../redux/storeState/storeState";
import { getCode } from "country-list";

function SingleUserDetails({ setSelectedOption, userData }: { setSelectedOption: any; userData: any }) {
  let accessInfo = [
    {
      heading: { label: "All stores", isChecked: userData?.storeAccess?.length > 0 ? true : false },
      subItems: userData?.storeAccess?.map((item: any) => {
        return { label: item.name, isChecked: true };
      }),
    },
  ];
  let countryCode;

  let { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));

  if (companyInfo?.country) {
    countryCode = getCode(companyInfo?.country);
  }

  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">
          Companies &gt; {companyInfo?.businessName} &gt; Users &gt; {userData?.user?.name}
        </div>
        <button className="back-btn" onClick={() => setSelectedOption("users")}>
          Back
        </button>
      </div>

      <div className="subscription-id">User ID: {userData?.user?.businessId}</div>
      {/* user info */}
      <div className="subscription-invoice-wrap mt-3">
        <div className="title pt-2 ">User info</div>
        <div className="user-detail-wrap">
          <div>Name</div>
          <div>{userData?.user?.name}</div>
          <div>Email</div>
          <div>{userData?.user?.email}</div>
          <div>Mobile</div>
          <div>{userData?.user?.mobile}</div>
          <div>Time Zone</div>
          <div> {userData?.user?.platformInfo?.timeZone}</div>
          <div>Status</div>
          <div>{userData?.user?.isDeleted ? "Inactive" : "Active"}</div>
        </div>
      </div>
      {/* presence */}
      <div className="subscription-invoice-wrap mt-2">
        <div className="title pt-2 ">Presence </div>
        <div className="user-detail-wrap">
          <div>Last seen</div>
          <div>
            {moment(userData?.user?.updatedAt).format("DD MMM YYYY")}{" "}
            <span className="ml-1">{moment(userData?.user?.updatedAt).format("HH:mm:ss")}</span>{" "}
          </div>
          <div>Login Attempts</div>
          <div>{userData?.user?.loginAttempts}</div>
          <div>IP Address</div>
          <div className="d-flex align-items-center">
            {userData?.user?.platformInfo?.ipAddress && countryCode ? (
              <>
                <span>
                  <ReactCountryFlag countryCode={countryCode} className="emojiFlag" svg style={{ borderRadius: "50%", fontSize: "20px" }} />
                </span>
                <span className="ml-1 " style={{ marginTop: "1px" }}>
                  {userData?.user?.platformInfo?.ipAddress}
                </span>
              </>
            ) : (
              "-"
            )}
          </div>
          <div>Platform</div>
          {userData?.user?.platformInfo?.platform ? <div>{userData?.user?.platformInfo?.platform}</div> : "-"}
        </div>
      </div>
      <div className="subscription-invoice-wrap my-2">
        <div className="title pt-2 ">Store Access </div>

        {accessInfo.map((item: any, index: number) => {
          return (
            <div className="roles-wrap" key={index}>
              <div className="role-checkbox-wrap mt-3 d-flex">
                <input
                  type="checkbox"
                  className="custom-user-checkbox"
                  style={{
                    height: "15px",
                    width: "15px",
                    border: "none",
                    background: "#F2F2F2",
                  }}
                  checked={item?.heading?.isChecked}
                />
                <div className="pl-2">{item?.heading?.label}</div>
              </div>
              {item?.subItems?.map((element: any, index: number) => {
                return (
                  <div className="sub-items d-flex ml-4 pt-2" key={index}>
                    <input
                      type="checkbox"
                      className="custom-user-checkbox"
                      style={{
                        height: "15px",
                        width: "15px",
                        border: "none",
                        background: "#F2F2F2",
                      }}
                      checked={element?.isChecked}
                      disabled={element?.disabled}
                    />
                    <div className="pl-2">{element?.label}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {/* role */}
      <div className="subscription-invoice-wrap mt-2">
        <div className="title">Role</div>
        <div className="user-detail-wrap">
          <div>Role Name</div>
          <div>{userData?.role?.name}</div>
        </div>
      </div>
    </div>
  );
}

export default SingleUserDetails;
