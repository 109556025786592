import sendIcon from "../../../../assets/images/sendIcon.svg";
import moment from "moment";
import ConfirmationModal from "../../../../components/modal/ConfirmationModal";
import SuccessMessage from "../../../../components/modal/SuccessMessage";
import { invoiceTranslation } from "../../../../helper/helperFunctions";
import ViewInvoice from "./ViewInvoice";
function InvoiceDetails({
  setSelectedOption,
  invoiceData,
  companyDetails,
  handleSendInvoice,
  showInvoiceSendModal,
  onCancel,
  handleOpenInvoice,
  setShowSuccessModal,
  showSuccessModal,
  subscriptionData,
}: {
  setSelectedOption: any;
  invoiceData: any;
  companyDetails: any;
  handleSendInvoice: any;
  showInvoiceSendModal: any;
  onCancel: any;
  handleOpenInvoice: any;
  setShowSuccessModal: any;
  showSuccessModal: any;
  subscriptionData: any;
}) {
  let SubTotal = Number(invoiceData?.paymentMetaData?.seats) * Number(invoiceData?.paymentMetaData?.basePrice?.toFixed(2));
  let taxPrice = (15 / 100) * SubTotal;
  let total = taxPrice + SubTotal;
  return (
    <div>
      <div className="main-overview-wrap" style={{ position: "relative" }}>
        <div className="title-subscription">Companies &gt; {invoiceData?.company?.companyName} &gt; Subscription &gt; Invoice</div>
        <div className="d-flex ">
          <button
            className="back-btn"
            onClick={() => setSelectedOption(`subscription_details_${invoiceData?.paymentMetaData?.subscriptionBusinessId}`)}
          >
            Back
          </button>
          <button className="send-btn" style={{ background: "#2D9CDB" }} onClick={handleOpenInvoice}>
            <span>Send</span>
            <img src={sendIcon} alt="sendIcon" />
          </button>
        </div>
        <div style={{ position: "absolute", top: 39, right: 330 }}>
          {showInvoiceSendModal ? (
            <>
              <ConfirmationModal
                title="Are you sure you want send this invoice to company email ?"
                btnText="Send"
                btnColor="#20639B"
                onCancel={onCancel}
                onConfirmation={handleSendInvoice}
                actions
                handleSucccessConfirmation={onCancel}
              />
            </>
          ) : null}
          {showSuccessModal && <SuccessMessage message="The invoice was successfully sent" setshowSuccessCompletion={setShowSuccessModal} />}
        </div>
      </div>
      <div className="subscription-id">Invoice ID {invoiceData?.invoiceNumber}</div>
      <div className="data-wrap mt-4">
        <div className="subscription-invoice-wrap ">
          <div className="title">Details</div>
          <div className="d-flex justify-content-between align-items-center my-2">
            <span>Description:</span>
            <span>{invoiceTranslation(invoiceData?.paymentMetaData?.planType)}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center my-2">
            <span>Subscription ID :</span>
            <span>{invoiceData?.paymentMetaData?.subscriptionBusinessId}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-2">
            <span>Date :</span>
            <span>{moment(invoiceData?.created_at).format("DD MMM YYYY")}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-2">
            <span>Invoice number : </span>
            {/* <span>A1-{invoiceData?.invoiceNumber}</span> */}
            <span>{invoiceData?.invoiceNumber}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center w-100 my-2">
            <span>Reference number : </span>
            <span>{invoiceData?.paymentMetaData?.referenceNumber}</span>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center w-100 my-2">
            <span>Total: </span>
            <span>
              {" "}
              {invoiceData?.company?.currency}{" "}
              {total
                ?.toFixed(2)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            </span>
          </div> */}
        </div>
      </div>
      <div className="subscription-invoice-wrap my-2 ">
        <div className="title">For</div>
        <div className="my-2">{invoiceData?.company?.companyName}</div>
        <div className="my-2">Tax no. {invoiceData?.company?.taxNumber}</div>
        <div className="my-2">{invoiceData?.company?.country}</div>
        <div className="my-2">{invoiceData?.company?.address}</div>
      </div>
      <div className="subscription-invoice-wrap ">
        <div className="title">Summary :</div>
        <div className="d-flex justify-content-between align-items-center my-2">
          <span>Plan:</span>
          <span>{invoiceData?.paymentMetaData?.planType}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Price:</span>
          <span>SAR {invoiceData?.paymentMetaData?.basePrice?.toFixed(2)}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Users: </span>
          <span> {invoiceData?.paymentMetaData?.seats}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Billing Period</span>
          <span>
            {moment(invoiceData?.billing_cycle?.from).format("DD MMM")} -&nbsp;
            {moment(invoiceData?.billing_cycle?.till).format("DD MMM YYYY")}
          </span>
        </div>
      </div>
      <div className="subscription-invoice-wrap mt-2 ">
        {/* <div className="title">Sub total :</div> */}
        <div className="d-flex justify-content-between align-items-center my-2">
          <span className="title-bold">Sub total:</span>
          <span className="title-bold">
            SAR
            {SubTotal.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Discount</span>
          <span> SAR 0.00</span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span className="title-bold">Total befor tax </span>
          <span className="title-bold">
            {" "}
            SAR
            {SubTotal?.toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span>Vat. 15%</span>
          <span> SAR {taxPrice.toFixed(2)}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100 my-2">
          <span className="title-bold">Total</span>
          <span className="title-bold">
            {" "}
            SAR
            {total
              .toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        </div>
      </div>
      <ViewInvoice />
    </div>
  );
}

export default InvoiceDetails;
