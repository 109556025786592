import React, { useRef, useState } from "react";
// import dropdownIcon from "../../assets/images/dropdownIcon.svg";
function CustomSingleSelect({
  handleSelectedItem,
  options,
  selectedOption,
  maxHeight,
  isEditCase,
  disabled,
  searchValue,
  setsearchValue,
}: {
  handleSelectedItem: any;
  options: any;
  selectedOption: any;
  maxHeight?: string;
  isEditCase?: boolean;
  disabled?: boolean;
  searchValue?: string;
  setsearchValue?: any;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<any>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="custom-multi-select mt-0" ref={selectRef}>
      <div className={isEditCase ? "my-select-field " : "select-field-readonly"} onClick={toggleDropdown} style={{ backgroundColor: "#ffffff" }}>
        <span className="px-3">{selectedOption}</span>
        <div className={`dropdown-icon ${isOpen ? "open" : ""}`}></div>
      </div>
      {isOpen && !disabled && (
        <div className="single-select-dropdown pt-0 mt-0 h-auto" style={{ padding: "0px !important", height: "100% !important", overflowY: "auto" }}>
          <>
            <>
              <div className="my-options-container custom-label w-100 " style={{ maxHeight: maxHeight }}>
                <div className="m-0 p-0 w-100">
                  <input
                    type="text"
                    className="modify-input"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e: any) => setsearchValue(e.target.value)}
                  />
                </div>
                {options?.length > 0 ? (
                  options.map((option: any, index: number) => (
                    <label key={index} className="d-block px-3">
                      {" "}
                      <div
                        onClick={() => handleSelectedItem(option)}
                        className="d-flex my-auto "
                        style={{ alignItems: "center", cursor: "pointer", height: "40px" }}
                      >
                        <span>
                          {option?.code ? `(${option?.code})` : ""} {option.name}
                        </span>
                      </div>
                    </label>
                  ))
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      color: "#4f4f4f",
                      width: "100%",
                      height: "100%",
                      marginTop: "1rem",
                    }}
                  >
                    No Options
                  </div>
                )}
              </div>
            </>
          </>
        </div>
      )}
    </div>
  );
}

export default CustomSingleSelect;
