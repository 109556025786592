import React, { useRef } from "react";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import emailIcon from "../../assets/images/emailIcon.svg";
import suspendIcon from "../../assets/images/suspendIcon.svg";
import ConfirmationModal from "./ConfirmationModal";
import resumeIcon from "../../assets/images/resumeIcon.svg";
import redDeleteIcon from "../../assets/images/redDeleteIcon.svg";
import restoreIcon from "../../assets/images/restoreIcon.svg";

interface ComanyActionIProps {
  deleteModaOpen: boolean;
  emailModalOpen: boolean;
  emailMessage?: string;
  emailSubject?: string;
  setEmailSubject?: any;
  setEmailMessage?: any;
  reason?: string;
  message?: string;
  setMessage?: any;
  style?: any;
  tableView?: boolean;
  onCancel?: any;
  onConfirmation?: any;
  openDeleteModalHandler?: any;
  handleSuccessConfirmation?: any;
  suspendModalOpen?: any;
  openSuspendModalHandler?: any;
  openEmailModalHandler?: any;
  deletedCompanyView?: boolean;
  restoreModalOpen?: boolean;
  openRestoreModalHandler?: any;
  handleReason?: any;
  showResumeOption?: boolean;
  openResumeHandler?: any;
  isCompanySuspended?: boolean;
  textDeleteColor?: string;
  left?: any;
  top?: any;
  width?: any;
  companyOverview?: any;
  disabled?: any;
}

function CompanyActionModal({
  deleteModaOpen,
  suspendModalOpen,
  emailModalOpen,
  reason,
  message,
  setMessage,
  style,
  tableView,
  onCancel,
  onConfirmation,
  openDeleteModalHandler,
  handleSuccessConfirmation,
  openEmailModalHandler,
  openSuspendModalHandler,
  deletedCompanyView,
  restoreModalOpen,
  openRestoreModalHandler,
  handleReason,
  showResumeOption,
  openResumeHandler,
  isCompanySuspended,
  textDeleteColor,
  setEmailMessage,
  setEmailSubject,
  emailSubject,
  emailMessage,
  left,
  top,
  companyOverview,
  width,
  disabled,
}: ComanyActionIProps) {
  const deleteModalOpenRef = useRef<any>();
  const suspendModalRef = useRef<any>();
  const emailModalRef = useRef<any>();
  const restoreModalRef = useRef<any>();
  const resumeModalRef = useRef<any>();
  const handleOutsideClickDeleteModal = (event: any) => {
    if (deleteModalOpenRef.current && !deleteModalOpenRef.current.contains(event.target)) {
      onCancel();
    }
  };
  const handleOutsideClickSuspendodal = (event: any) => {
    if (suspendModalRef.current && !suspendModalRef.current.contains(event.target)) {
      onCancel();
    }
  };
  const handleOutsideClickEmailModal = (event: any) => {
    if (emailModalRef.current && !emailModalRef.current.contains(event.target)) {
      onCancel();
    }
  };
  const handleOutsideClickResumeModal = (event: any) => {
    if (resumeModalRef.current && !resumeModalRef.current.contains(event.target)) {
      onCancel();
    }
  };

  const handleOutsideClickRestoreModal = (event: any) => {
    if (restoreModalRef.current && !restoreModalRef.current.contains(event.target)) {
      onCancel();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickDeleteModal);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickDeleteModal);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickResumeModal);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickResumeModal);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickRestoreModal);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickRestoreModal);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickSuspendodal);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickSuspendodal);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickEmailModal);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickEmailModal);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div
      className={`${
        tableView ? "table-action-dropdown shadow" : companyOverview ? "company-overview-action-dropdown shadow" : "group-action-dropdown shadow"
      }`}
      style={{ position: "relative", left: left, top: top, width: width }}
    >
      {deletedCompanyView ? (
        <div className="icon-wrap" ref={restoreModalRef} style={style}>
          <img src={restoreIcon} alt="delete-icon" />
          <span style={{ color: "#20639B" }} onClick={openRestoreModalHandler}>
            Restore
          </span>
          {restoreModalOpen ? (
            <ConfirmationModal
              handleSucccessConfirmation={handleSuccessConfirmation}
              actions
              title="Are you sure you want restored this company ?"
              btnText="Restore"
              btnColor="#20639B"
              onCancel={onCancel}
              tableView={tableView ? true : false}
              onConfirmation={onConfirmation}
              companyOverview={companyOverview}
            />
          ) : null}
        </div>
      ) : (
        <div className="icon-wrap" ref={deleteModalOpenRef} style={style}>
          <img src={textDeleteColor === "#EB5757" ? redDeleteIcon : deleteIcon} alt="delete-icon" />

          <span onClick={openDeleteModalHandler} style={{ color: textDeleteColor, pointerEvents: textDeleteColor === "#EB5757" ? "all" : "none" }}>
            Delete
          </span>
          {deleteModaOpen ? (
            <ConfirmationModal
              handleSucccessConfirmation={handleSuccessConfirmation}
              actions
              title="Are you sure you want deleted this company ?"
              btnText="Delete"
              btnColor="rgba(235, 87, 87, 1)"
              onCancel={onCancel}
              tableView={tableView ? true : false}
              onConfirmation={onConfirmation}
              companyOverview={companyOverview}
            />
          ) : null}
        </div>
      )}
      {!deletedCompanyView && (
        <>
          {isCompanySuspended ? (
            <div className="icon-wrap" ref={resumeModalRef} style={style}>
              <img src={resumeIcon} alt="resume-icon" />
              <span onClick={openResumeHandler}>Resume</span>
              {showResumeOption ? (
                <div style={{ position: "absolute", top: tableView ? "-32px" : "-45px", left: 0 }}>
                  <ConfirmationModal
                    handleSucccessConfirmation={handleSuccessConfirmation}
                    actions
                    title="Are you sure you want resume this company?"
                    btnText="Resume"
                    btnColor="#828282"
                    // reason=""
                    // handleReason={handleReason}
                    onCancel={onCancel}
                    onConfirmation={onConfirmation}
                    tableView={tableView ? true : false}
                    showResumeOption={showResumeOption}
                    companyOverview={companyOverview}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div className="icon-wrap" ref={suspendModalRef} style={style}>
              <img src={suspendIcon} alt="delete-icon" />
              <span onClick={openSuspendModalHandler} style={{ color: !tableView && !disabled ? "#828282" : "#4F4F4F" }}>
                Suspend
              </span>
              {suspendModalOpen ? (
                <div style={{ position: "absolute", top: tableView ? "-32px" : companyOverview ? "-36px" : "-45px", left: 0 }}>
                  <ConfirmationModal
                    handleSucccessConfirmation={handleSuccessConfirmation}
                    actions
                    title="Are you sure you want suspend this company?"
                    btnText="Suspend"
                    btnColor={reason && reason?.length > 0 ? "#F2994A" : "#828282"}
                    handleReason={handleReason}
                    reason={reason}
                    onCancel={onCancel}
                    onConfirmation={onConfirmation}
                    tableView={tableView ? true : false}
                    companyOverview={companyOverview}
                    disabled={reason && reason.length > 0 ? false : true}
                  />
                </div>
              ) : null}
            </div>
          )}
        </>
      )}

      <div className="icon-wrap" ref={emailModalRef} style={style}>
        <img src={emailIcon} alt="delete-icon" />
        <span onClick={openEmailModalHandler}>Send email</span>
        {emailModalOpen ? (
          <div style={{ position: "absolute", top: "-60px", left: 0 }}>
            <ConfirmationModal
              handleSucccessConfirmation={handleSuccessConfirmation}
              btnText="SEND"
              btnColor="#20639B"
              subject={emailSubject}
              message={emailMessage}
              setEmailMessage={setEmailMessage}
              setEmailSubject={setEmailSubject}
              onCancel={onCancel}
              onConfirmation={onConfirmation}
              tableView={tableView ? true : false}
              companyOverview={companyOverview}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CompanyActionModal;
