interface InputIProps {
  type: string;
  placeholder: string;
  suffix?: string;
  suffixAction?: () => void;
  label?: string;
  validations?: any;
  id?: string;
  register?: any;
  otherProps?: any;
  error?: any;
  name?: string;
  value?: any;
  onChange?: any;
  className?: string;
}
function Input({
  type,
  placeholder,
  suffix,
  suffixAction,
  label,
  validations,
  id,
  register,
  error,
  name,
  otherProps,
  value,
  onChange,
  className,
}: InputIProps) {
  return (
    <div>
      {label && (
        <label className="form-label" htmlFor={id}>
          {label} {validations?.required && <span className="required"></span>}
        </label>
      )}
      <div className="input-field">
        <input
          type={type}
          placeholder={placeholder}
          {...(register ? register(name, { ...validations }) : {})}
          {...otherProps}
          {...(value ? (value = { value }) : "")}
          {...(onChange ? (onChange = { onChange }) : "")}
          className={className}
        />

        {suffix && (
          <div className="show-password" onClick={suffixAction}>
            <img src={suffix} alt="suffix" />
          </div>
        )}
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default Input;
