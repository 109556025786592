import toast from "react-hot-toast";
import { AppDispatch } from "../store";
import * as requestFromServer from "./authCrud";
import { authSlice, callTypes } from "./authSlice";
const { actions } = authSlice;
export const login = (data: object, navigate: (to: string | Location) => void) => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .login(data)
    .then((response) => {
      dispatch(
        actions.loginState({
          token: response?.data?.token,
        })
      );

      toast.success("Logged in successfully");
      navigate("/companies");
    })
    .catch((error: any) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "loginError",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const changePassword = (updatedData: object, valueRemoveHandler: void | any) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .changePassword(updatedData)
    .then((response) => {
      valueRemoveHandler();
      toast.success("password changed successfully");
    })

    .catch((error) => {
      error.clientMessage = error.message;
      let errorData = error?.response?.data?.message;

      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "signupError",
          errorData,
        })
      );
      toast.error(errorData);
    });
};

export const forgotPassword = (data: object) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .passwordForgot(data)
    .then((response) => {
      toast.success("Email sent successfully");
      dispatch(
        actions.passwordForgot({
          token: response?.data?.access?.token,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "emailNotSent",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const resetPassword = (data: object, token: string, navigate: (to: string | Location) => void) => (dispatch: AppDispatch | any) => {
  dispatch(
    actions.startCall({
      callType: callTypes.list,
    })
  );
  return requestFromServer
    .passwordReset(data, token)
    .then((response) => {
      toast.success("Password reset successfully");
      dispatch(
        actions.resetPassword({
          token: response?.data?.access?.token,
        })
      );
      navigate("/");
    })
    .catch((error) => {
      error.clientMessage = error.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "passwordNotReset",
        })
      );
      toast.error(error?.response?.data?.message);
    });
};

export const logout = () => (dispatch: AppDispatch) => {
  dispatch(actions.logoutState());
};

export const tokenTypeLogout = () => (dispatch: AppDispatch) => {
  dispatch(actions.tokenState());
};

export const resetSignUpError = () => (dispatch: AppDispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  let errorData = null;
  dispatch(
    actions.catchError({
      callType: callTypes.action,
      errorType: "signupError",
      errorData,
    })
  );
};

export const whoAmI = () => (dispatch: AppDispatch | any) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .whoAmI()
    .then((response) => {
      dispatch(actions.whoAmIState({ entities: response?.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error: "error", callType: callTypes.list }));
      if (error.code === 401 && window.location.pathname !== "/") {
        toast.error(error?.message);
      }
    });
};
