import axios from "axios";
import {
  CompanyLogsSearchDTO,
  CompanySearchDTO,
  DeleteCompanySearchDTO,
  StoreSearchDTO,
  PaymentChannelSearchDTO,
  TaxesSearchDTO,
  SendEmailDTO,
  UserDataDTO,
  SendInvoiceEmailDTO,
} from "../../interfaces/companiesInterface";
import { refactorCompanyCountryQuery, refactorCompanyPlanListQuery } from "../../helper/helperFunctions";
import { InvoicesSearchDTO, PrinterSearchDTO, SubscriptionSearchDTO } from "../../interfaces/companyInterface";
export const AUTH_URL = "admin";

export const getAllCompanies = (searchData: CompanySearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company?${searchData?.isSuspended ? `isSuspended=${searchData.isSuspended}` : ""}${
      searchData?.lastActivityStartDate ? `&lastActivityStartDate=${searchData.lastActivityStartDate}` : ""
    }${searchData?.lastActivityEndDate ? `&lastActivityEndDate=${searchData.lastActivityEndDate}` : ""}${
      searchData?.registerStartDate ? `&registerStartDate=${searchData.registerStartDate}` : ""
    }${searchData?.registerEndDate ? `&registerEndDate=${searchData.registerEndDate}` : ""}${
      searchData?.minUsers ? `&minUsers=${searchData.minUsers}` : ""
    }${searchData?.maxUsers ? `&maxUsers=${searchData.maxUsers}` : ""}${searchData?.sortAt ? `&sortAt=${searchData.sortAt}` : ""}${
      searchData?.sortBy ? `&sortBy=${searchData.sortBy}` : ""
    }${searchData?.page ? `&page=${searchData.page}` : 1}${searchData?.size ? `&size=${searchData.size}` : 10}${
      searchData?.searchName ? `&searchName=${searchData.searchName}` : ""
    }${refactorCompanyCountryQuery(searchData?.countries)}${searchData?.subscriptionPlanLength === searchData?.planList?.length ? "" : refactorCompanyPlanListQuery(searchData?.planList)}`
  );
};

export const getAllCountries = (searchName?: string) => {
  return axios.get(`${AUTH_URL}-company-countries?isDeleted=false${searchName ? `&searchName=${searchName}` : ""}`);
};
export const getAllDeletedCountries = (searchName?: string) => {
  return axios.get(`${AUTH_URL}-company-countries?isDeleted=true${searchName ? `&searchName=${searchName}` : ""}`);
};

export const deleteSelectedCompanies = (ids: any) => {
  return axios.patch(`${AUTH_URL}-company-deleted?isDeleted=true${ids && ids.length > 0 ? `&ids=${ids.join(",")}` : ""}`);
};
export const restoreDeletedCompanies = (ids?: string[]) => {
  return axios.patch(`${AUTH_URL}-company-deleted?isDeleted=false${ids && ids.length > 0 ? `&ids=${ids.join(",")}` : ""}`);
};
export const suspendSelectedCompanies = (ids: any, reason?: string, isSuspended?: boolean) => {
  return axios.patch(`${AUTH_URL}-company-suspend?isSuspended=${isSuspended}${ids && ids.length > 0 ? `&ids=${ids.join(",")}` : ""}`, {
    reasonForSuspend: reason,
  });
};

export const getAllDeletedCompanies = (searchData: DeleteCompanySearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-deleted?${searchData?.dayMin ? `&daysMin=${searchData?.dayMin}` : ""}${
      searchData?.daysMax ? `&daysMax=${searchData?.daysMax}` : ""
    }${searchData?.page ? `&page=${searchData?.page}` : ""}${searchData?.size ? `&size=${searchData?.size}` : ""}${
      searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""
    }${searchData?.sortAt ? `&sortAt=${searchData?.sortAt}` : ""}${searchData?.sortBy ? `&sortBy=${searchData?.sortBy}` : ""}${
      searchData?.countries && searchData?.countries.length > 0
        ? `&countries=${searchData?.countries.join(",")}`
        : // : searchData?.countries && searchData?.countries[0] === undefined
          // ? ""
          "&countries=undefined"
    }`
  );
};

export const getCompanyInfoById = (companyId: string, startDate: string, endDate: string) => {
  return axios.get(`${AUTH_URL}-company/${companyId}${startDate ? `?startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`);
};

export const updatedCompanyInfo = (companyId: string, info: { country: string; currency: string }) => {
  return axios.patch(`${AUTH_URL}-company/${companyId}`, info);
};

export const getAllModuleLogs = (searchData: CompanyLogsSearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-allModuleLogs/${searchData?.companyId}?logSearchFor=${searchData?.logSearchFor ? `${searchData?.logSearchFor}` : ""}${
      searchData?.actionId ? `&actionId=${searchData?.actionId}` : ""
    }${searchData?.limit ? `&limit=${searchData?.limit}` : ""}${searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""}${
      searchData?.startDate ? `&startDate=${searchData?.startDate}` : ""
    }${searchData?.endDate ? `&endDate=${searchData?.endDate}` : ""}`
  );
};

export const getAllStores = (searchData: StoreSearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-stores/${searchData?.companyId}${searchData?.page ? `?page=${searchData?.page}` : ""}${
      searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""
    }${searchData?.sortAt ? `&sortAt=${searchData?.sortAt}` : ""}${searchData?.sortBy ? `&sortBy=${searchData?.sortBy}` : ""}${
      searchData?.size ? `&size=${searchData?.size}` : ""
    }`
  );
};

export const getStoreById = (storeId: string) => {
  return axios.get(`store/${storeId}`);
};

export const getAllPos = (searchData: StoreSearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-pointOfSales/${searchData?.companyId}${searchData?.page && searchData?.page > 0 ? `?page=${searchData?.page}` : "?page=1"}${
      searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""
    }${searchData?.sortAt ? `&sortAt=${searchData?.sortAt}` : ""}${searchData?.sortBy ? `&sortBy=${searchData?.sortBy}` : ""}${
      searchData?.size && searchData?.size > 0 ? `&size=${searchData?.size}` : "&size=10"
    }`
  );
};

export const getAllUsers = (searchData: StoreSearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-users/${searchData?.companyId}${searchData?.page ? `?page=${searchData?.page}` : ""}${
      searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""
    }${searchData?.sortAt ? `&sortAt=${searchData?.sortAt}` : ""}${searchData?.sortBy ? `&sortBy=${searchData?.sortBy}` : ""}${
      searchData?.size ? `&size=${searchData?.size}` : ""
    }${searchData?.startDate ? `&startDate=${searchData?.startDate}` : ""}${searchData?.endDate ? `&endDate=${searchData?.endDate}` : ""}`
  );
};

export const getUserById = (userId: string) => {
  return axios.get(`${AUTH_URL}-user/${userId}`);
};

export const getAllRoles = (searchData: StoreSearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-roles/${searchData?.companyId}${searchData?.page ? `?page=${searchData?.page}` : ""}${
      searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""
    }${searchData?.sortAt ? `&sortAt=${searchData?.sortAt}` : ""}${searchData?.sortBy ? `&sortBy=${searchData?.sortBy}` : ""}${
      searchData?.size ? `&size=${searchData?.size}` : ""
    }`
  );
};

export const getRoleById = (roleId: string) => {
  return axios.get(`roles/${roleId}`);
};
export const getPaymentChannelsById = (searchData: PaymentChannelSearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-paymentChannels/${searchData?.companyId}?page=${searchData?.page ? `${searchData.page}` : "1"}&size=${
      searchData?.size ? `${searchData.size}` : "10"
    }&sortAt=${searchData?.sortAt ? `${searchData?.sortAt}` : ""}&sortBy=${searchData?.sortBy ? `${searchData?.sortBy}` : ""}&searchName=${
      searchData?.searchName ? `${searchData.searchName}` : ""
    }`
  );
};
export const getAllTaxesById = (searchData: TaxesSearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-taxes/${searchData?.companyId}?page=${searchData?.page ? `${searchData.page}` : "1"}&size=${
      searchData?.size ? `${searchData.size}` : "10"
    }&sortAt=${searchData?.sortAt ? `${searchData?.sortAt}` : ""}&sortBy=${searchData?.sortBy ? `${searchData?.sortBy}` : ""}&searchName=${
      searchData?.searchName ? `${searchData.searchName}` : ""
    }`
  );
};

export const sendEmailToCompany = (emails: string[], emailData: SendEmailDTO) => {
  return axios.patch(`${AUTH_URL}-company-sendEmail?${emails?.length > 0 ? `companyEmails=${emails?.join(",")}` : ""}`, emailData);
};
export const sendInvoiceEmailToCompany = (email: string, emailData: SendInvoiceEmailDTO) => {
  return axios.patch(`${AUTH_URL}-company-sendInvoiceEmail?${email?.length > 0 ? `companyEmail=${email}` : ""}`, emailData);
};

export const getSubscriptionChartsData = (companyId: string, startDate: string, endDate: string) => {
  return axios.get(
    `${AUTH_URL}-company/subscription-charts/${companyId ? companyId : ""}${startDate ? `?startDate=${startDate}` : ""}${
      endDate ? `&endDate=${endDate}` : ""
    }`
  );
};

export const getPaymentCardByCompanyId = (companyId: string) => {
  return axios.get(`payment/getPaymentCard/${companyId ? companyId : ""}`);
};
export const getSubscriptionsByCompanyId = (searchData: SubscriptionSearchDTO) => {
  return axios.get(
    `${AUTH_URL}-company-subscription/${searchData?.companyId ? searchData?.companyId : ""}${searchData?.page ? `?page=${searchData?.page}` : ""}${
      searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""
    }${searchData?.sortAt ? `&sortAt=${searchData?.sortAt}` : ""}${searchData?.sortBy ? `&sortBy=${searchData?.sortBy}` : ""}${
      searchData?.size ? `&size=${searchData?.size}` : ""
    }${searchData?.startDate ? `&startDate=${searchData?.startDate}` : ""}${searchData?.endDate ? `&endDate=${searchData?.endDate}` : ""}`
  );
};
export const getSubscriptionInvoicesBySubscriptionId = (searchData: InvoicesSearchDTO) => {
  return axios.get(
    `subscriptionInvoice/admin-company/${searchData?.subscriptionId ? searchData?.subscriptionId : ""}${
      searchData?.page ? `?page=${searchData?.page}` : ""
    }${searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""}${searchData?.sortAt ? `&sortAt=${searchData?.sortAt}` : ""}${
      searchData?.sortBy ? `&sortBy=${searchData?.sortBy}` : ""
    }${searchData?.size ? `&size=${searchData?.size}` : ""}${searchData?.startDate ? `&startDate=${searchData?.startDate}` : ""}${
      searchData?.endDate ? `&endDate=${searchData?.endDate}` : ""
    }`
  );
};
export const getAllPrintersByPosId = (searchData: PrinterSearchDTO) => {
  return axios.get(
    `admin-printers/${searchData?.posId ? searchData?.posId : ""}${searchData?.page > 0 ? `?page=${searchData?.page}` : "?page=1"}${
      searchData?.searchName ? `&searchName=${searchData?.searchName}` : ""
    }${searchData?.sortAt ? `&sortAt=${searchData?.sortAt}` : ""}${searchData?.sortBy ? `&sortBy=${searchData?.sortBy}` : ""}${
      searchData?.size > 0 ? `&size=${searchData?.size}` : "&size=10"
    }`
  );
};
export const updateAdminUserById = (updatedData: UserDataDTO) => {
  return axios.patch(`${AUTH_URL}-user/${updatedData?.id ? updatedData?.id : ""}`, updatedData);
};
export const getSubscriptionInvoiceById = (invoiceId: string) => {
  return axios.get(`subscriptionInvoice/${invoiceId}`);
};
