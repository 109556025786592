import SingleDummyChart from "../../charts/SingleChart";
import Skeleton from "react-loading-skeleton";
import Table from "../table/Table";
import moment from "moment";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../redux/storeState/storeState";
function SubscriptionDetails({
  setSelectedOption,
  subscriptionData,
  chartsLoading,
  chartsToShow,
  paymentCardData,
  invoiceHeadData,
  companyInvoices,
  page,
  size,
  setPage,
  setSize,
  total,
  handleInvoiceSearch,
  handleSortInvoiceClick,
  handleInvoiceSort,
  invoiceSearchText,
  selectedInvoiceHeader,
  setInvoiceSearchText,
  isOpen,
  options,
  selectedDate,
  selectedValue,
  setIsOpen,
  setSelectedDate,
  setselectedValue,
  setslectedLable,
  slectedLable,
  resetHandler,
}: {
  setSelectedOption: any;
  subscriptionData: any;
  chartsLoading?: boolean;
  chartsToShow: any;
  paymentCardData?: any;
  invoiceHeadData?: any;
  companyInvoices: any;
  page: number;
  size: number;
  setPage: any;
  setSize: any;
  total: number;
  handleInvoiceSearch: any;
  handleSortInvoiceClick: any;
  handleInvoiceSort: any;
  selectedInvoiceHeader: any;
  setInvoiceSearchText: any;
  selectedDate?: any;
  setIsOpen?: any;
  isOpen?: boolean;
  setselectedValue?: any;
  selectedValue?: any;
  options?: any;
  setslectedLable?: any;
  slectedLable?: any;
  setSelectedDate?: any;
  invoiceSearchText?: any;
  resetHandler?: any;
}) {
  let refactoredInvoices = companyInvoices?.map((item: any) => {
    return {
      ...item,
      plan: item?.paymentMetaData?.planType,
      amount: Number(item?.paymentMetaData?.credit)?.toFixed(2),
      billingPeriod: `${moment(item?.billing_cycle?.from).format("MMM DD - ")} ${moment(item?.billing_cycle?.till).format("MMM DD, YYYY")}`,
      service: "User management",
      date: moment(item.created_at).format("MMM DD, YYYY, HH:mm"),
    };
  });

  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));

  let totalAmount = (
    Number(subscriptionData?.invoiceData?.paymentMetaData?.seats) * Number(subscriptionData?.invoiceData?.paymentMetaData?.basePrice?.toFixed(2))
  ).toFixed(2);
  return (
    <>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; Subscription details</div>
        <button
          className="back-btn"
          onClick={() => {
            setSelectedOption("subscription");
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Subscription ID {subscriptionData?.subscriptionId}</div>
      <div className="mt-2" style={{ display: "flex", gap: "10px", width: "100%" }}>
        {!chartsLoading && chartsToShow && chartsToShow[0] ? (
          <>
            <SingleDummyChart
              title={chartsToShow[0][1]?.title}
              analyticsXaxisData={chartsToShow[1]}
              className=" shadow-lg mt-3  w-100 "
              selectedItem={chartsToShow[0][1]}
              color="#219653"
              totalResult={chartsToShow[0][1]?.value}
            />
          </>
        ) : (
          <>
            <div className="bg-white circle p-2 w-100">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
          </>
        )}
      </div>
      <div className="payment-card-wrap mt-2 subs-details">
        <div className="title my-1">Service: User management:</div>
        <div className="mb-1">Status: {subscriptionData?.currentPlan?.status === "Running" ? "Active" : subscriptionData?.currentPlan?.status}</div>
        <div className="mb-1">Next Bill: {moment(subscriptionData?.invoiceData?.billing_cycle?.till).format("DD MMM YYYY")}</div>
        <div className="mb-1">
          Total: SAR {totalAmount} ({subscriptionData?.invoiceData?.paymentMetaData?.seats}x
          {subscriptionData?.invoiceData?.paymentMetaData?.basePrice?.toFixed(2)})
        </div>
      </div>
      <div>
        <Table
          setSelectedOption={setSelectedOption}
          tableHeadData={invoiceHeadData}
          tableBodyData={refactoredInvoices}
          page={page}
          size={size}
          setPage={setPage}
          setSize={setSize}
          total={total}
          handleSiteSearch={handleInvoiceSearch}
          handleArrowSortClick={handleSortInvoiceClick}
          handleSort={handleInvoiceSort}
          searchText={invoiceSearchText}
          selectedSiteHeader={selectedInvoiceHeader}
          setsiteSearchText={setInvoiceSearchText}
          subscriptionView={true}
          singleDetailPath={`subscription_single`}
          isOpen={isOpen}
          options={options}
          selectedDate={selectedDate}
          selectedValue={selectedValue}
          setIsOpen={setIsOpen}
          setSelectedDate={setSelectedDate}
          setselectedValue={setselectedValue}
          setslectedLable={setslectedLable}
          slectedLable={slectedLable}
          subscriptionTitle="Invoices"
          resetHandler={resetHandler}
        />
      </div>
    </>
  );
}

export default SubscriptionDetails;
