import Table from "../table/Table";
import tickIcon from "../../../../assets/images/blackTick.svg";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../redux/storeState/storeState";

interface PaymentChannelIProp {
  setSelectedOption: any;
  paymentChannelHeadData: any;
  paymentChannels: any;
  page: number;
  size: number;
  setpage: any;
  setsize: any;
  total: number;
  handlePaymentChannelSearch: any;
  handleSortChannelClick: any;
  handleChannelsSort: any;
  channelsSearchText: any;
  selectedPaymentChannelHeader: any;
  setChannelsSearchText: any;
  resetHandler: any;
}
function PaymentChannels({
  setSelectedOption,
  paymentChannels,
  paymentChannelHeadData,
  page,
  setpage,
  setsize,
  size,
  total,
  handlePaymentChannelSearch,
  handleSortChannelClick,
  handleChannelsSort,
  channelsSearchText,
  selectedPaymentChannelHeader,
  setChannelsSearchText,
  resetHandler,
}: PaymentChannelIProp) {
  let refactoredData = paymentChannels.map((item: any) => {
    return {
      ...item,
      default: item.paymentTypeDefault ? <img src={tickIcon} alt="check icon" /> : "",
      isDeleted: item?.isDeleted,
      status: item?.active ? "Enabled" : "Disabled",
    };
  });

  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; Payment Channels</div>
        <button
          className="back-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Business ID {companyInfo?.businessId}</div>
      <div>
        <Table
          setSelectedOption={setSelectedOption}
          tableHeadData={paymentChannelHeadData}
          tableBodyData={refactoredData}
          page={page}
          size={size}
          setPage={setpage}
          setSize={setsize}
          total={total}
          handleSiteSearch={handlePaymentChannelSearch}
          handleArrowSortClick={handleSortChannelClick}
          handleSort={handleChannelsSort}
          searchText={channelsSearchText}
          selectedSiteHeader={selectedPaymentChannelHeader}
          setsiteSearchText={setChannelsSearchText}
          subscriptionView={false}
          placeholder="Search payment channels"
          //  singleDetailPath={`paymentChannel_single`}
          singleDetailPath="none"
          resetHandler={resetHandler}
        />
      </div>
    </div>
  );
}

export default PaymentChannels;
