import React from "react";
import ReactDOM from "react-dom/client";
import "../src/assets/css/styles.min.css";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
import store from "./redux/store";
import setupAxiosInterceptors from "./config/axios-interceptor";
import { logout } from "./redux/auth/authAction";
import { bindActionCreators } from "redux";
import { SidebarProvider } from "./Pages/useSidebarProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
bindActionCreators({}, store.dispatch);

setupAxiosInterceptors(logout);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <SidebarProvider>
        <Toaster position="top-center" reverseOrder={false} />
        <App />
      </SidebarProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
