import { RadioButton } from "../../../../components/radio-button/RadioButton";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../redux/storeState/storeState";
interface SingleRoleDetailIProp {
  setSelectedOption: any;
  roleDetails: any;
}
function SingleRoleDetails({ setSelectedOption, roleDetails }: SingleRoleDetailIProp) {
  let roleInfo = [
    {
      backOffice: {
        isSelected: roleDetails?.backOffice,
        subItems: roleDetails?.backOfficePayload?.map((item: any) => {
          return { ...item, isChecked: item?.selected, label: item?.name, type: "checkbox" };
        }),
      },
    },
    {
      pos: {
        isSelected: roleDetails?.pos,
        subItems: [
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[0]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[0]?.selected,
            type: "checkbox",

            innerItems: [
              {
                label: roleDetails?.posPayload && roleDetails?.posPayload[0]?.subItem[0]?.name,
                isChecked: roleDetails?.posPayload && roleDetails?.posPayload[0]?.subItem[0]?.selected,
                type: "radio",
              },
              {
                label: roleDetails?.posPayload && roleDetails?.posPayload[0]?.subItem[1]?.name,
                isChecked: roleDetails?.posPayload && roleDetails?.posPayload[0]?.subItem[1]?.selected,
                type: "radio",
              },
            ],
          },
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[1]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[1]?.selected,
            type: "checkbox",
          },
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[2]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[2]?.selected,
            type: "checkbox",
          },
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[3]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[3]?.selected,
            type: "checkbox",
          },
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[4]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[4]?.selected,
            type: "checkbox",
          },
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[5]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[4]?.selected,
            type: "checkbox",
          },
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[5]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[5]?.selected,
            type: "checkbox",
          },
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[6]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[6]?.selected,
            type: "checkbox",
          },
          {
            label: roleDetails?.posPayload && roleDetails?.posPayload[7]?.name,
            isChecked: roleDetails?.posPayload && roleDetails?.posPayload[7]?.selected,
            type: "checkbox",
          },
        ],
      },
    },
  ];

  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">
          Companies &gt; {companyInfo?.businessName} &gt; Roles &gt; {roleDetails?.name}
        </div>
        <button
          className="back-btn"
          onClick={() => {
            setSelectedOption("roles");
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Role ID {roleDetails?.businessId}</div>
      <div className="payment-card-wrap mt-3 w-100 h-100">
        <div className="roles-main-wrap">
          <div className="title-main">User Permissions</div>
          <div className="mt-3">
            <div className="role-details-wrap">
              <div className="title">Back Office</div>
              <div className="pt-4">
                <RadioButton isSelected={roleInfo[0]?.backOffice?.isSelected} />
              </div>
            </div>
            {roleInfo?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item?.backOffice?.subItems?.map((subItem: any, index: number) => {
                    return (
                      <div key={index} className="role-details-wrap">
                        <div></div>
                        <div className="my-1">
                          <input
                            type="checkbox"
                            className="custom-user-checkbox mr-2"
                            style={{
                              height: "15px",
                              width: "15px",
                              border: "none",
                              background: "#F2F2F2",
                            }}
                            checked={subItem?.isChecked}
                          />
                          <span> {subItem.label}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="mt-2">
            <div className="role-details-wrap">
              <div className="title">POS</div>
              <div className="pt-4">
                <RadioButton isSelected={roleInfo[1]?.pos?.isSelected} disabled />
              </div>
            </div>
            {roleInfo?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item?.pos?.subItems?.map((subItem: any, index: number) => {
                    return (
                      <div key={index} className="role-details-wrap">
                        <div></div>
                        <div className="my-1">
                          <input
                            type="checkbox"
                            className="custom-user-checkbox mr-2"
                            style={{
                              height: "15px",
                              width: "15px",
                              border: "none",
                              background: "#F2F2F2",
                            }}
                            checked={subItem?.isChecked}
                          />
                          <span>{subItem.label}</span>
                          {subItem?.innerItems?.map((innerItem: any, index: number) => {
                            return (
                              <div key={index} className="d-flex pt-1">
                                <RadioButton simpleRadio={true} roundRadio={false} isSelected={innerItem?.isChecked} />
                                <div>{innerItem?.label}</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleRoleDetails;
