import { useState } from "react";
import logoLeft from "../../../../assets/images/logoLeftImg.svg";
import logoText from "../../../../assets/images/logoText.svg";
import qrCode from "../../../../assets/images/qrCode.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../redux/storeState/storeState";

function ViewInvoice() {
  //eslint-disable-next-line
  const [isTaxInvoice, setisTaxInvoice] = useState(true);
  const { companyInvoiceForEdit, companyForEdit, subscriptionData, paymentCard } = useSelector((state: StoreState) => ({
    companyInvoiceForEdit: state.companies.invoiceForEdit,
    companyForEdit: state.companies.companySideInfoData,
    paymentCard: state.companies.companyPaymentCardData,
    subscriptionData: state.companies.companySubscriptionData,
  }));

  const seats = companyInvoiceForEdit?.paymentMetaData?.seats || 0;
  const basePrice = companyInvoiceForEdit?.paymentMetaData?.basePrice || 0;
  const subtotal = seats * basePrice;
  const appliedBalance = Math.min(companyInvoiceForEdit?.paymentMetaData?.creditUsed, subtotal) || 0;
  const amountBeforeTax = Math.abs(subtotal - appliedBalance);
  let tax = amountBeforeTax === 0 ? 0 : companyInvoiceForEdit?.paymentMetaData?.tax;
  let totalWithTax = amountBeforeTax === 0 ? 0 : amountBeforeTax + tax;
  const paymentAmount = totalWithTax > 0 ? totalWithTax : 0;
  const amountDue = 0;

  return (
    <div className="invoice-main-wrapper w-100" style={{ position: "absolute", left: "-9999px" }} id="invoice-content">
      <div className="invoice-wrapper py-5 w-100 ">
        <div className="sub-wrap pt-3">
          <div>
            <div className="d-flex align-items-center" style={{ marginTop: "-1.2rem" }}>
              <img src={logoLeft} width={46} height={61} alt="company-logo" />
              <img src={logoText} width={158} height={43} className="ml-2" alt="company-logo" />
            </div>
            <div style={{ marginTop: "2rem" }}>
              <div>Numu It Est. - Fatoraty</div>
              <div className="my-2">Dammam - Saudi Arabia</div>
              <div>CR .2050139742</div>
              <div className="mt-2">Tax No.</div>

              <div className="hr" style={{ marginTop: "1rem", width: "50%" }}></div>
            </div>
          </div>

          <div className="ml-auto custom-border-b mr-5">
            <div className="main-title ">{isTaxInvoice ? "Tax Invoice" : "Credit Note For Tax Invoice"}</div>
            <div className="mt-5">
              <div className="d-flex " style={{ marginTop: "59px" }}>
                <div>Invoice number:&nbsp;</div>
                <div> {companyInvoiceForEdit?.invoiceNumber} </div>
              </div>
              <div className="d-flex my-2 ">
                <div>Date:&nbsp;</div>
                <div> {moment(companyInvoiceForEdit?.created_at).format("DD MMM YYYY")}</div>
              </div>

              <div className="d-flex my-2 ">
                <div>Customer No.&nbsp;</div>
                <div>{companyForEdit?.businessId}</div>
              </div>
              <div style={{ color: "#27AE60", fontWeight: 600 }}>
                {companyInvoiceForEdit?.status ? companyInvoiceForEdit?.status?.toUpperCase() : <span style={{ visibility: "hidden" }}>status</span>}
              </div>
            </div>
            {/* <div className="hr" style={{ width: "330px", marginTop: "1rem" }}></div> */}
          </div>
        </div>
        <div className="sub-wrap mt-5">
          <div>
            <span style={{ fontWeight: 700 }}>Bailed To </span>
            <div className="mt-2">
              <div>{companyForEdit?.businessName}</div>
              <div className="my-2">Tax no. {companyForEdit?.taxNumber}</div>
              <div>{companyForEdit?.country}</div>
            </div>
          </div>
          <div className="ml-auto" style={{ marginTop: "-4px", paddingTop: "5px", marginLeft: "0rem" }}>
            <b className="" style={{ fontWeight: 700 }}>
              Subscription
            </b>
            <div className="mt-2">
              <div className="d-flex">
                <div>ID:&nbsp;</div>
                <div>{subscriptionData[0]?.subscriptionId}</div>
              </div>
              <div className="d-flex my-2 w-100">
                <div className="">Billing Period&nbsp;</div>
                <div>
                  {moment(companyInvoiceForEdit?.billing_cycle?.from).format("DD MMM")} -{" "}
                  {moment(companyInvoiceForEdit?.billing_cycle?.till).format("DD MMM YYYY")}
                </div>
              </div>
              <div className="d-flex">
                <div>Next Bill:&nbsp;</div>
                <div>{moment(companyInvoiceForEdit?.billing_cycle?.till).format("DD MMM YYYY")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 ">
          <div className="w-100 mt-3">
            <table className="text-start w-100">
              <thead className="w-100 ">
                <tr>
                  <th className="pb-3 custom-border-b custom-header-color">Description</th>
                  <th className="pb-3 custom-border-b custom-header-color">Units</th>
                  <th className="pb-3 custom-border-b custom-header-color">Unit Price</th>
                  <th className="pb-3 custom-border-b custom-header-color">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pt-3"> Users management - {companyInvoiceForEdit?.paymentMetaData?.planType}</td>
                  <td className="pt-3">{companyInvoiceForEdit?.paymentMetaData?.seats} User</td>
                  <td className="pt-3">SAR {Number(companyInvoiceForEdit?.paymentMetaData?.basePrice).toFixed(2)} </td>
                  <td className="pt-3">SAR {Number(subtotal).toFixed(2)} </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div className="summary-detail-wrap">
              <div style={{ paddingLeft: "27rem" }}>
                <div style={{ fontWeight: 700 }} className="title">
                  Sub total
                </div>
                {appliedBalance > 0 && <div>Credits Applied</div>}
                <div>Vat. 15%</div>
                <div style={{ fontWeight: 700 }}>Total</div>
              </div>
              <div style={{ textAlign: "end", direction: "ltr" }}>
                <div style={{ fontWeight: 700 }}> SAR {Number(subtotal).toFixed(2)} </div>
                {appliedBalance > 0 && <div style={{ fontWeight: 700 }}>(SAR {Number(appliedBalance).toFixed(2)})</div>}
                <div>SAR {Number(tax).toFixed(2)} </div>
                <div style={{ fontWeight: 700 }}>SAR {Number(totalWithTax).toFixed(2)}</div>
              </div>
            </div>
            {/* {isTaxInvoice && ( */}
            <>
              <div className="summary-detail-wrap text-align-end">
                <div style={{ paddingLeft: "27rem" }}>
                  <div>Payments</div>
                </div>
                <div className="text-right">
                  <div>(SAR {Number(paymentAmount).toFixed(2)})</div>
                </div>
              </div>
              <hr style={{ width: "23rem", marginRight: "0rem", marginBottom: ".5rem", marginTop: ".5rem", direction: "ltr" }} />
              <div className="summary-detail-wrap">
                <div style={{ paddingLeft: "27rem", fontWeight: 700 }}>Amount due</div>
                <div style={{ fontWeight: 700 }}>SAR {amountDue.toFixed(2)}</div>
              </div>
              <hr style={{ width: "23rem", marginRight: "0rem", marginTop: ".5rem", direction: "ltr" }} />
            </>
            {/* // )} */}

            <div className="credit-wrap mt-5">
              <div>
                <div className="font-bolder pb-3">
                  {" "}
                  <span className="custom-border-b" style={{ paddingBottom: "10px" }}>
                    Credits & Payments:
                  </span>
                </div>

                <div className="font-normal">
                  {appliedBalance > 0 && (
                    <p>
                      <span>A credit of</span> <span className="font-bolder">SAR {Number(appliedBalance).toFixed(2)}</span>{" "}
                      <span>was applied on {moment(companyInvoiceForEdit?.paymentMetaData?.createdAt).format("DD MMM, YYYY hh:mm")} KSA.</span>
                    </p>
                  )}
                  {paymentAmount > 0 && (
                    <div>
                      SAR <span className="font-bolder"> {Number(paymentAmount).toFixed(2)}</span> was paid on{" "}
                      {moment(companyInvoiceForEdit?.paymentMetaData?.createdAt).format("DD MMM, YYYY hh:mm")} KSA by&nbsp;
                      {companyInvoiceForEdit?.paymentMetaData?.sourceMethod} ending&nbsp;
                      {paymentCard?.last_four}.
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="qr-wrap">
              <img src={qrCode} alt="qrCode" />
            </div>
          </div>
        </div>
      </div>
      {/* ) : null} */}
    </div>
  );
}

export default ViewInvoice;
