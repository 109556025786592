import { useSelector } from "react-redux";
import Table from "../table/Table";
import { StoreState } from "../../../../redux/storeState/storeState";

interface StoreDetailsIProp {
  setSelectedOption: any;
  storesHeadData: any;
  stores: any;
  page: number;
  size: number;
  setpage: any;
  setsize: any;
  total: number;
  handleStoreSearch: any;
  handleSortStoreClick: any;
  handleStoresSort: any;
  storeSearchText: any;
  selectedStoreHeader: any;
  setStoreSearchText: any;
  resetHandler: any;
}
function StoreDetails({
  setSelectedOption,
  stores,
  storesHeadData,
  page,
  setpage,
  setsize,
  size,
  total,
  handleStoreSearch,
  handleSortStoreClick,
  handleStoresSort,
  storeSearchText,
  selectedStoreHeader,
  setStoreSearchText,
  resetHandler,
}: StoreDetailsIProp) {
  let refactoredStores = stores?.map((item: any) => {
    return { ...item, pos: item?.numberOfPos };
  });

  let { companyInfo } = useSelector((store: StoreState) => ({
    companyInfo: store.companies.companySideInfoData,
  }));
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; Stores</div>
        <button
          className="back-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Business ID {companyInfo?.businessId}</div>
      <div className="mt-3">
        <Table
          setSelectedOption={setSelectedOption}
          tableHeadData={storesHeadData}
          tableBodyData={refactoredStores}
          page={Number(page)}
          size={Number(size)}
          setPage={setpage}
          setSize={setsize}
          total={total}
          handleSiteSearch={handleStoreSearch}
          handleArrowSortClick={handleSortStoreClick}
          handleSort={handleStoresSort}
          searchText={storeSearchText}
          selectedSiteHeader={selectedStoreHeader}
          setsiteSearchText={setStoreSearchText}
          subscriptionView={false}
          placeholder="Search stores"
          singleDetailPath="stores_single"
          showEmptyScreen={refactoredStores?.length > 0 ? false : true}
          resetHandler={resetHandler}
        />
      </div>
    </div>
  );
}

export default StoreDetails;
