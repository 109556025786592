import React, { useEffect, useRef, useState } from "react";
import Table from "../table/Table";
import { GenaricListDTO } from "../DTO/GenericListDTO";
import closeIcon from "../../../assets/images/CloseIcon.svg";
import SearchImage from "../../../assets/images/Search.svg";
import FilterForm from "../filterForm/filterForm";
import * as authActions from "../../../redux/auth/authAction";
import * as deletedCompanyActions from "../../../redux/companies/companiesAction";
import { useSidebar } from "../../useSidebarProvider";
import CompaniesHeader from "../companies-main/CompaniesHeader";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../redux/storeState/storeState";
import { AppDispatch } from "../../../redux/store";

function DeletedCompanies() {
  const { companiesData, companiesPage, totalCount, companiesSize, countryData, user, filterCount } = useSelector((state: StoreState) => ({
    companiesData: state?.companies?.deletedCompanies,
    totalCount: state?.companies?.deletedTotalCount,
    companiesPage: state?.companies?.deletedPage,
    user: state?.auth?.user,
    companiesSize: state?.companies?.deletedSize,
    countryData: state?.companies?.deletedCountries,
    filterCount: state?.companies?.deletedFilterCount,
  }));
  //eslint-disable-next-line
  const [columnHeaders, setColumnHeaders] = useState<GenaricListDTO[]>([
    {
      id: "1",
      label: "BUSINESS ID",
      value: "businessId",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "2",
      label: "BUSINESS NAME",
      value: "businessName",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "3",
      label: "OWNER NAME",
      value: "ownerName",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "4",
      label: "EMAIL",
      value: "email",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "5",
      label: "COUNTRY",
      value: "country",
      isSelected: true,
      sortBy: "desc",
    },
    {
      id: "6",
      label: "DAYS",
      value: "daysLeft",
      isSelected: true,
      sortBy: "desc",
    },
  ]);
  const [deletedCompanyLoading, setdeletedCompanyLoading] = useState(true);
  const [isDeletedCompany, setisDeletedCompany] = useState(true);
  const [daysMin, setdaysMin] = useState(user?.deletedCompaniesFilter?.daysMin || "");
  const [daysMax, setdaysMax] = useState(user?.deletedCompaniesFilter?.daysMax || "");
  //eslint-disable-next-line
  const [companiesList, setcompaniesList] = useState<any[]>([]);
  const [countryList, setCountryList] = useState<any[]>([]);
  const [filterDropdownOpen, setfilterDropdownOpen] = useState(false);
  const [firstCallLoading, setfirstCallLoading] = useState(true);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [countrySearchText, setcountrySearchText] = useState("");
  const [countrySearchLoading, setCountrySearchLoading] = useState(false);
  //eslint-disable-next-line
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(0);
  const [deletedFilterCount, setDeletedFilterCount] = useState(0);
  const [isfilterBtnColorApplied, setIsfilterBtnColorApplied] = useState(false);
  const [closeSearchBarIfValue, setCloseSearchBarIfValue] = useState(true);
  //eslint-disable-next-line
  const [feildToSort, setfeildToSort] = useState("buisnessId");
  const [countryLoading, setcountryLoading] = useState(false);
  //eslint-disable-next-line
  const [sortBy, setSortBy] = useState({ label: "buisnessIdDeleted", value: "asc" });
  const [selectedHeader, setselectedHeader] = useState<GenaricListDTO>(columnHeaders[0]);
  const [resetLoading, setResetLoading] = useState(false);
  const filterRef = useRef<any>();
  const searchRef = useRef<any>();

  let deltedCompany = true;
  const dispatch: AppDispatch | any = useDispatch();

  const handleOutsideClick = (event: any) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setfilterDropdownOpen(false);
    }
  };
  const handleOutsideClickSearch = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      if (!closeSearchBarIfValue) {
        setSearchBarOpen(true);
      } else {
        setSearchBarOpen(false);
      }
    }
  };
  useEffect(() => {
    let country = countryData?.map((item) => {
      let userCountries = user && user?.deletedCompaniesFilter?.countries?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);

      return {
        ...item,
        isSelected:
          user &&
          user?.deletedCompaniesFilter &&
          ((user?.deletedCompaniesFilter?.countries[0] && user?.deletedCompaniesFilter?.countries[0] !== "undefined") ||
            (user?.deletedCompaniesFilter?.countries[0] && user?.deletedCompaniesFilter?.countries[0] !== ""))
            ? userCountries?.includes(item?.label)
              ? true
              : false
            : true,
      };
    });
    setCountryList(country);

    //eslint-disable-next-line
  }, [user, countryLoading, countryData]);

  useEffect(() => {
    if (firstCallLoading) {
      dispatch(authActions.whoAmI()).then(() => {
        dispatch(deletedCompanyActions.getAllDeletedCountries(""))
          .then(() => {
            setdaysMax(user?.deletedCompaniesFilter?.daysMax!);
            setdaysMin(user?.deletedCompaniesFilter?.daysMin!);
            setselectedHeader({
              ...selectedHeader,
              sortBy: user && user?.deletedCompaniesFilter?.sortBy !== "" ? user?.deletedCompaniesFilter?.sortBy : "desc",
              value: user && user?.deletedCompaniesFilter?.sortAt !== "" ? user?.deletedCompaniesFilter?.sortAt : "businessId",
            });
          })
          .then(() => {
            setfirstCallLoading(false);
          });
      });
    }

    // eslint-disable-next-line
  }, [user, firstCallLoading]);

  useEffect(() => {
    if (user) {
      setdaysMax(user?.deletedCompaniesFilter?.daysMax);
      setdaysMin(user?.deletedCompaniesFilter?.daysMin);
      let header = columnHeaders?.find((item) => item?.value === user?.deletedCompaniesFilter?.sortAt);
      if (header) {
        setselectedHeader(header);
      }
      setselectedHeader({
        ...selectedHeader,
        sortBy: user && user?.deletedCompaniesFilter?.sortBy !== "" ? user?.deletedCompaniesFilter?.sortBy : "desc",
        value: user && user?.deletedCompaniesFilter?.sortAt !== "" ? user?.deletedCompaniesFilter?.sortAt : "businessId",
      });
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (!firstCallLoading && user) {
      let countries = countryList?.filter((item) => item.isSelected)?.map((item) => item.label);
      dispatch(
        deletedCompanyActions.getAllDeletedCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries ? countries : undefined,
          dayMin: daysMin,
          daysMax: daysMax,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
        })
      );
    }

    // eslint-disable-next-line
  }, [firstCallLoading]);

  useEffect(() => {
    if (countryLoading) {
      dispatch(authActions.whoAmI()).then(() => {
        let country = countryData?.map((item) => {
          let userCountries = user && user?.deletedCompaniesFilter?.countries?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);

          return {
            ...item,
            isSelected:
              user &&
              user?.deletedCompaniesFilter &&
              (user?.deletedCompaniesFilter?.countries[0] !== "undefined" || user?.deletedCompaniesFilter?.countries[0] !== "")
                ? userCountries?.includes(item?.label)
                  ? true
                  : false
                : true,
          };
        });
        setCountryList(country);
      });

      setcountryLoading(false);
    }

    // eslint-disable-next-line
  }, [countryData, countryLoading]);

  useEffect(() => {
    const refactorCompanies = companiesData?.map((item: any) => {
      return { ...item, isSelected: false };
    });
    setcompaniesList(refactorCompanies);
  }, [companiesData]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickSearch);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickSearch);
    };
    // eslint-disable-next-line
  }, [closeSearchBarIfValue]);
  const handleDaysMin = (e: any) => {
    setdaysMin(e.target.value);
  };
  const handleDaysMax = (e: any) => {
    setdaysMax(e.target.value);
  };

  const handleSelectCountry = (id: string, label: string) => {
    if (!countrySearchLoading) {
      let refactoredArr = countryList.map((item) => {
        if (item.label === label) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return item;
        }
      });
      setCountryList([...refactoredArr]);
      let countries = refactoredArr?.filter((item) => item.isSelected)?.map((subItem) => subItem.label);
      dispatch(
        deletedCompanyActions.getAllDeletedCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries.length > 0 ? countries : undefined,
          dayMin: daysMin,
          daysMax: daysMax,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
        })
      );
    } else if (countrySearchLoading) {
      let userCountries = user?.deletedCompaniesFilter?.countries[0] !== "" && user?.deletedCompaniesFilter?.countries;
      let refactoredArrs = userCountries.map((item: any) => {
        if (item.label === label) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return item;
        }
      });
      let countries = refactoredArrs?.filter((item: any) => item.isSelected)?.map((subItem: any) => subItem.label);
      setCountryList([...refactoredArrs]);
      dispatch(
        deletedCompanyActions.getAllDeletedCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries.length > 0 ? countries : undefined,
          dayMin: daysMin,
          daysMax: daysMax,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
        })
      );
      setcountrySearchText("");
    }
  };
  const handleSelectAllCountry = () => {
    let refactoredArr;
    if (!countrySearchLoading) {
      if (countryList?.filter((item) => !item.isSelected).length > 0) {
        refactoredArr = countryList.map((item) => {
          return { ...item, isSelected: true };
        });
      } else {
        refactoredArr = countryList.map((item) => {
          return { ...item, isSelected: false };
        });
      }
      let countries = refactoredArr?.filter((item) => item.isSelected)?.map((item) => item.label);
      dispatch(
        deletedCompanyActions.getAllDeletedCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries.length > 0 ? countries : undefined,
          dayMin: daysMin,
          daysMax: daysMax,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
        })
      );
      setCountryList([...refactoredArr]);
    } else {
      let userCountries = user?.deletedCompaniesFilter?.countries[0] !== "" && user?.deletedCompaniesFilter?.countries;
      let selectedCountry = countryList?.filter((item: any) => item?.label?.toLowerCase()?.includes(countrySearchText?.toLocaleLowerCase()));
      let refactoredArrs: any = [];
      userCountries?.forEach((userCountry: any) => {
        let matchingCountry = selectedCountry?.find((mainItem: any) => mainItem.label === userCountry.label);
        if (matchingCountry) {
          refactoredArrs.push({
            ...userCountry,
            isSelected: !userCountry?.isSelected,
          });
        } else {
          refactoredArrs.push(userCountry);
        }
      });
      setCountryList([...refactoredArrs]);

      let countries = refactoredArrs?.filter((item: any) => item.isSelected)?.map((subItem: any) => subItem.label);
      dispatch(
        deletedCompanyActions.getAllDeletedCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries.length > 0 ? countries : undefined,
          dayMin: daysMin,
          daysMax: daysMax,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
        })
      );
      setcountrySearchText("");
    }
  };

  const handleCountrySearch = (() => {
    return (searchString: string) => {
      setTimeout(() => {
        dispatch(deletedCompanyActions.getAllDeletedCountries(searchString)).then((res: any) => {
          setcountryLoading(true);
        });
      }, 200);
    };
  })();

  useEffect(() => {
    if (countrySearchText?.length > 0) {
      setCountrySearchLoading(true);
    } else setCountrySearchLoading(false);
  }, [countrySearchText]);

  useEffect(() => {
    if (!firstCallLoading) {
      let countries = countryList?.filter((item) => item.isSelected)?.map((item) => item.label);
      if (countries?.length > 0) {
        dispatch(
          deletedCompanyActions.getAllDeletedCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : undefined,
            dayMin: daysMin,
            daysMax: daysMax,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
          })
        );
      }
    }

    // eslint-disable-next-line
  }, [page, size]);

  useEffect(() => {
    // if (totalCount > 0 && total === 0) {
    setPage(Number(companiesPage));
    setTotal(totalCount);
    setSize(Number(companiesSize));
    setDeletedFilterCount(filterCount);
    // }
    // eslint-disable-next-line
  }, [totalCount, filterCount]);

  //filters
  useEffect(() => {
    if (!firstCallLoading && user && !countrySearchLoading) {
      let countries = countryList?.filter((item) => item.isSelected)?.map((item) => item.label);
      if (countries?.length > 0) {
        dispatch(
          deletedCompanyActions.getAllDeletedCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : undefined,
            dayMin: daysMin,
            daysMax: daysMax,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
          })
        );
      }
    }

    // eslint-disable-next-line
  }, [daysMin, daysMax]);

  //company search
  const handleSearchCompany = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected)?.map((item) => item.label);
        dispatch(
          deletedCompanyActions.getAllDeletedCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : undefined,
            dayMin: daysMin,
            daysMax: daysMax,
            searchName: searchString,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
          })
        );
        if (searchString !== "") {
          setCloseSearchBarIfValue(false);
        } else {
          setCloseSearchBarIfValue(true);
        }
      }, 200);
    };
  })();

  //reset handler for company search
  const resetSearchingHandler = () => {
    let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected)?.map((item) => item.label);
    dispatch(
      deletedCompanyActions.getAllDeletedCompanies({
        page: page === 0 ? 1 : page,
        size: size === 0 ? 10 : size,
        countries: countries ? countries : [],
        dayMin: daysMin,
        daysMax: daysMax,
        searchName: "",
        sortAt: selectedHeader.value,
        sortBy: selectedHeader.sortBy,
      })
    );
    setsearchValue("");
  };

  const getSelectedFilters = () => {
    let countries: any = countryList?.filter((item) => item?.isSelected).map((item) => item?.label);
    let deletedPage = page === 0 ? 1 : page;
    let deletedSize = size === 0 ? 10 : size;
    let sortAt = selectedHeader.value;
    let sortBy = selectedHeader.sortBy;

    return { countries, deletedPage, deletedSize, sortAt, sortBy, daysMax, daysMin, searchValue };
  };

  //filter color
  useEffect(() => {
    if (countryList?.length > 0 && countryList?.filter((item) => !item.isSelected)?.length > 0) {
      setIsfilterBtnColorApplied(true);
    } else if (daysMax?.length > 0 || daysMin?.length > 0) {
      setIsfilterBtnColorApplied(true);
    } else {
      setIsfilterBtnColorApplied(false);
    }
  }, [countryList, daysMax, daysMin]);

  //sorting
  const handleSort = (item: any) => {
    setselectedHeader(item);
    setselectedHeader(item);
    if (selectedHeader?.value === item.value) {
      if (selectedHeader?.sortBy === "asc") {
        setselectedHeader({ ...selectedHeader, sortBy: "desc" });
      } else {
        setselectedHeader({ ...selectedHeader, sortBy: "asc" });
      }
    }
  };
  useEffect(() => {
    if (!firstCallLoading) {
      let countries = countryList?.length > 0 && countryList?.filter((item) => item.isSelected)?.map((item) => item.label);
      if (countries && countries?.length > 0) {
        dispatch(
          deletedCompanyActions.getAllDeletedCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : [],
            dayMin: daysMin,
            daysMax: daysMax,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
          })
        );
      }
      let sortIcon = document.getElementById("sort-icon");
      sortIcon?.classList.toggle("toggle-icon");
    }

    // eslint-disable-next-line
  }, [selectedHeader?.sortBy, selectedHeader?.value]);

  useEffect(() => {
    if (!firstCallLoading && user) {
      dispatch(deletedCompanyActions.getAllDeletedCountries(countrySearchText)).then(() => {
        let countries = countryList?.filter((item) => item.isSelected)?.map((item) => item.label);
        if (countries?.length > 0) {
          dispatch(
            deletedCompanyActions.getAllDeletedCompanies({
              page: page === 0 ? 1 : page,
              size: size === 0 ? 10 : size,
              countries: countries,
              dayMin: daysMin,
              daysMax: daysMax,
              searchName: searchValue,
              sortAt: selectedHeader.value,
              sortBy: selectedHeader.sortBy,
            })
          );
        }
      });
    }

    // eslint-disable-next-line
  }, [isDeletedCompany]);

  const handleReset = () => {
    setcountrySearchText("");
    setdaysMax("");
    setdaysMin("");
    dispatch(deletedCompanyActions.getAllDeletedCountries("")).then(() => {
      setResetLoading(true);
      let countries = countryData?.map((item) => item?.label);
      setcountrySearchText("");
      let resetCountryList = countryData?.map((item) => {
        return { ...item, isSelected: true };
      });
      setCountryList(resetCountryList);
      dispatch(
        deletedCompanyActions.getAllDeletedCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries,
          dayMin: "",
          daysMax: "",
          searchName: "",
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
        })
      ).then(() => {
        setResetLoading(false);
      });
    });
  };

  useEffect(() => {
    if (resetLoading) {
      let resetCountryList = countryData?.map((item) => {
        return { ...item, isSelected: true };
      });
      setCountryList(resetCountryList);
      let countries = countryData?.map((item) => item?.label);
      dispatch(
        deletedCompanyActions.getAllDeletedCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries,
          dayMin: "",
          daysMax: "",
          searchName: "",
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
        })
      ).then(() => {
        setResetLoading(false);
      });
    }
    //eslint-disable-next-line
  }, [resetLoading]);

  const handleArrowSortClick = () => {
    if (selectedHeader?.value) {
      setfeildToSort(selectedHeader.value!);
    }
    if (selectedHeader?.sortBy === "asc") {
      selectedHeader.sortBy = "desc";
    } else {
      selectedHeader.sortBy = "asc";
    }
  };
  const { collapsed, setselectedItem } = useSidebar();
  setselectedItem("");
  return (
    <>
      <div className="pb-2" style={{ overflowX: "hidden", background: "rgb(242, 243, 248)" }}>
        <CompaniesHeader isDeleteCompanies={isDeletedCompany} setisDeleteCompanies={setisDeletedCompany} />
        <div className="companies-main-wrap" style={{ marginLeft: collapsed ? "4.5rem" : "18.7rem" }}>
          <div className="d-flex justify-content-between align-items-center" style={{ gap: "25px" }}>
            <div className="filters ml-3">
              <div
                className={`${isfilterBtnColorApplied ? "active filter-wrap" : "normal filter-wrap"}`}
                ref={filterRef}
                style={{ position: "relative" }}
              >
                <span
                  onClick={() => {
                    setfilterDropdownOpen(!filterDropdownOpen);
                  }}
                >
                  Filter
                </span>
                {filterDropdownOpen ? (
                  <FilterForm
                    countryList={countryList}
                    setCountryList={setCountryList}
                    countrySearchText={countrySearchText}
                    setCountrySearchText={setcountrySearchText}
                    daysMin={daysMin}
                    daysMax={daysMax}
                    handleDaysMax={handleDaysMax}
                    handleDaysMin={handleDaysMin}
                    handleSelectAllCountry={handleSelectAllCountry}
                    handleSelectCountry={handleSelectCountry}
                    handleReset={handleReset}
                    handleCountrySearch={handleCountrySearch}
                    deletedCompany={true}
                  />
                ) : null}
              </div>
            </div>
            <div className="searchIcon-wrap" ref={searchRef} style={{ position: "relative", width: searchBarOpen ? "100%" : "inherit" }}>
              <div style={{ width: "100%", height: "34px" }}>
                {searchBarOpen ? (
                  <input
                    value={searchValue}
                    onChange={(e: any) => {
                      setsearchValue(e.target.value);
                      handleSearchCompany(e.target.value);
                    }}
                    type="text"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      outline: "none",
                      background: "#F2F2F2",
                      borderRadius: "6px",
                      padding: "1rem",
                      color: searchValue.length ? "#4F4F4F" : "#BDBDBD",
                      fontWeight: !searchValue.length ? 500 : 700,
                    }}
                    className="searchBar"
                    placeholder="Search"
                  />
                ) : null}

                <div style={{ position: "absolute", top: 3, right: 20 }}>
                  {searchValue.length && searchBarOpen ? (
                    <img
                      src={closeIcon}
                      alt="search icon"
                      onClick={() => {
                        setsearchValue("");
                        setCloseSearchBarIfValue(true);
                        resetSearchingHandler();
                      }}
                    />
                  ) : (
                    <img
                      src={SearchImage}
                      alt="search icon"
                      onClick={() => {
                        setSearchBarOpen(!searchBarOpen);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <Table
              tableHeaderState={columnHeaders}
              companiesList={companiesList}
              handleSelectTableCheckbox={() => {}}
              handleSelectAllTableCheckbox={() => {}}
              deltedCompany={deltedCompany}
              total={total}
              page={page === 0 ? 1 : page}
              size={size === 0 ? 10 : size}
              setPage={setPage}
              setSize={setSize}
              handleSort={handleSort}
              deleteCompanyLoading={deletedCompanyLoading}
              setDeleteCompanyLoading={setdeletedCompanyLoading}
              setSortField={setfeildToSort}
              setSortBy={setSortBy}
              handleArrowSortClick={handleArrowSortClick}
              searchValue={searchValue}
              getSelectedFilters={getSelectedFilters}
              selectedHeader={selectedHeader}
              filterCount={deletedFilterCount}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DeletedCompanies;
