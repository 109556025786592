import CustomSingleSelect from "../../../../components/form-input/single-select";
import ConfirmationModal from "../../../../components/modal/ConfirmationModal";
import SuccessMessage from "../../../../components/modal/SuccessMessage";
import { refactorSelectedModule } from "../../../../helper/helperFunctions";
import countryList from "react-select-country-list";
import currencyCodes from "currency-codes";

function CompanyInformation({
  setSelectedOption,
  handleBack,
  isEditCase,
  showExitModal,
  handleCancel,
  handleConfirmation,
  handleEdit,
  showSuccessUpdation,
  setshowSuccessUpdation,
  handleSelectedCurrency,
  handleSelectedItem,
  selectedCurrency,
  selectedOption,
  companyState,
  selectedModule,
  searchCountryValue,
  searchCurrencyValue,
  setSearchCountryValue,
  setSearchCurrencyValue,
}: {
  setSelectedOption: any;
  handleBack: any;
  isEditCase: boolean;
  showExitModal: boolean;
  handleCancel: any;
  handleConfirmation: any;
  handleEdit: any;
  showSuccessUpdation: any;
  setshowSuccessUpdation: any;
  companyInitialState?: any;
  handleSelectedItem: any;
  selectedOption: any;
  handleSelectedCurrency: any;
  selectedCurrency: any;
  companyState?: any;
  selectedModule?: any;
  searchCurrencyValue?: string;
  searchCountryValue?: string;
  setSearchCountryValue?: any;
  setSearchCurrencyValue?: any;
}) {
  const options = countryList().getData();
  const currencies = currencyCodes.codes();

  let refactoredCountries = options?.map((item, index) => {
    return { id: index, name: item.label };
  });
  let refactoredCurrencies = currencies.map((item, index) => {
    const currency = currencyCodes.code(item);
    return {
      id: index,
      code: currency?.code,
      name: currency?.currency,
    };
  });

  let allCountries = refactoredCountries.filter((item) => item.name.toLowerCase().startsWith(searchCountryValue!));
  let allCucrrency = refactoredCurrencies.filter((item) => item?.code?.toLowerCase().startsWith(searchCurrencyValue!));
  return (
    <>
      <div className="main-overview-wrap">
        <div className="title-subscription">
          Companies &gt; {companyState?.businessName} &gt; {refactorSelectedModule(selectedModule)}
        </div>
        <div className="d-flex ">
          <button className="back-btn" onClick={handleBack} style={{ flex: "none" }}>
            {isEditCase ? "Cancel" : "Back"}
          </button>
          <div style={{ position: "absolute", top: 50, right: 377 }}>
            {showExitModal ? (
              <>
                <ConfirmationModal
                  title="You have unsaved changes, are you sure you want to continue without saving?"
                  btnText="Continue"
                  btnColor="#828282"
                  primaryBtnColor="#2D9CDB"
                  onCancel={handleCancel}
                  onConfirmation={handleConfirmation}
                  actions
                  handleSucccessConfirmation={handleCancel}
                />
              </>
            ) : null}
          </div>

          <button className="edit-btn ml-2" onClick={handleEdit}>
            {!isEditCase ? "Edit" : "Save Changes"}
          </button>
          <div style={{ position: "absolute", top: 40, right: 360 }}>
            {showSuccessUpdation && <SuccessMessage message="Update completed successfully" setshowSuccessCompletion={setshowSuccessUpdation} />}
          </div>
        </div>
      </div>
      <div className="subscription-id">ID {companyState.businessId}</div>
      <div className="info-detail-wrap-main  mt-2">
        <div className="info-detail-wrap h-100">
          <div className="mt-2">Business Name</div>
          <input type="text" className={`${isEditCase ? "info-input" : "readonly-input"} mt-2`} value={companyState?.businessName} disabled />
          <div className="mt-2">Tax Number</div>
          <input type="text" className={`${isEditCase ? "info-input" : "readonly-input"} mt-2`} value={companyState?.taxNumber} disabled />
         
          <div className="mt-2">Country</div>
          <div className="mt-2">
            <CustomSingleSelect
              options={allCountries}
              handleSelectedItem={handleSelectedItem}
              selectedOption={selectedOption}
              maxHeight="45px"
              isEditCase={isEditCase}
              disabled={!isEditCase}
              setsearchValue={setSearchCountryValue}
              searchValue={searchCountryValue}
            />
          </div>
          <div className="mt-2">Currency</div>
          <div className="mt-2">
            <CustomSingleSelect
              options={allCucrrency}
              handleSelectedItem={handleSelectedCurrency}
              selectedOption={selectedCurrency}
              maxHeight="45px"
              isEditCase={isEditCase}
              disabled={!isEditCase}
              setsearchValue={setSearchCurrencyValue}
              searchValue={searchCurrencyValue}
            />
          </div>
          <div className="mt-2">Address</div>
          <input type="text" className={`${isEditCase ? "info-input" : "readonly-input"} mt-2`} value={companyState?.address} disabled />
         
        </div>
      </div>
    </>
  );
}

export default CompanyInformation;
