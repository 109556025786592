import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
export const TEXT_DIRECTION = () => {
  let direction = document.getElementsByTagName("html")[0].getAttribute("dir");
  return direction;
};

export const sortTableColumnsForDeletedCompanies = (item: any, setSortField: any) => {
  if (item.value === "businessNameDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("companyName");
  } else if (item.value === "daysDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("days");
  } else if (item.value === "ownerNameDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("ownerName");
  } else if (item.value === "countryDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("country");
  } else if (item.value === "companyEmailDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("email");
  } else if (item.value === "buisnessIdDeleted") {
    if (item.sortBy === "asc") {
      item.sortBy = "desc";
    } else {
      item.sortBy = "asc";
    }
    setSortField("buisnessId");
  }
};
export const refactoredSelectedOption = (selectedItem: any) => {
  if (selectedItem === "overview") {
    return " / OVERVIEW";
  } else if (selectedItem.includes("subscription")) {
    return " / SUBSCRIPTION";
  } else if (selectedItem.includes("users")) {
    return " / USERS";
  } else if (selectedItem.toLowerCase().includes("site")) {
    return " / SITES";
  } else return "";
};
export const sortTableHeader = (item: any, setSortField: any) => {
  if (item.value === "siteId") {
    setSortField("siteId");
  } else if (item.value === "name") {
    setSortField("name");
  }
};

export const refactorLogsTilte = (selectedOption: string) => {
  if (selectedOption === "overview") {
    return "company information";
  } else if (selectedOption === "subscription") {
    return "subscription";
  } else if (selectedOption === "sites") {
    return "sites";
  } else if (selectedOption.includes("sites_single")) {
    return "site";
  } else if (selectedOption.includes("users")) {
    return "users";
  } else if (selectedOption.includes("users_single")) {
    return "user";
  } else return "";
};
export const sortSiteHeader = (item: any, setSortField: any) => {
  if (item.value === "siteId") {
    setSortField("siteId");
  } else if (item.value === "name") {
    setSortField("name");
  }
};
export const refactorSuspendStatus = (status: string, isSuspended: boolean, isTrialCancelled: boolean) => {
  if (isSuspended === false && status === "Free") {
    return "Running";
  } else if (status === "Free" && isSuspended === true) {
    return "Suspended";
  } else if (isTrialCancelled && status === "Running") {
    return "Reverted";
  } else {
    return status;
  }
};

export const refactorUserManagement = (
  plan: string,
  status: string,
  isSuspended: boolean,
  isTrialCancel: boolean,
  isTrialStart: boolean,
  isTrialExpire: boolean
) => {
  if (!isSuspended && status === "Free" && !isTrialCancel) {
    return "Free";
  } else if (status === "Free" && isSuspended === true) {
    return "Free, Suspended";
  } else if (plan === "Free" && status === "Running" && isTrialCancel) {
    return "Free, Reverted";
  } else if (status === "Running" && plan === "Free" && !isTrialCancel) {
    return "Free";
  } else if (status === "Running" && plan === "Monthly" && (!isTrialStart || (isTrialStart && (isTrialCancel || isTrialExpire)))) {
    return "Monthly";
  } else if (status === "Running" && plan === "Annual" && (!isTrialStart || (isTrialStart && (isTrialCancel || isTrialExpire)))) {
    return "Annual";
  } else if (status === "Running" && plan === "Monthly" && isTrialStart && !isTrialCancel && !isTrialExpire) {
    return "Monthly, Trial";
  } else if (status === "Running" && plan === "Annual" && isTrialStart && !isTrialCancel && !isTrialExpire) {
    return "Annual, Trial";
  } else if (status === "Running" && plan === "Trial") {
    return "Trial";
  } else if (status === "Free" && plan === "Free") {
    return "Free";
  } else {
    return `${plan}, ${status}`;
  }
};

export const invoiceTranslation = (text: any) => {
  if (text === "Monthly") {
    return "Monthly Invoice";
  } else if (text === "Annual") {
    return "Yearly Invoice";
  } else if (text === "Additional seats") {
    return "Additional Seats Invoice";
  }
};

export const handleLogsMargin = (selectedOption: string) => {
  if (selectedOption.includes("info") || selectedOption.includes("paymentChannel")) {
    return "1.5rem";
  } else if (
    selectedOption === "subscription" ||
    selectedOption.includes("stores") ||
    selectedOption.includes("pointOfSale") ||
    selectedOption.includes("users") ||
    selectedOption.includes("roles")
  ) {
    return "2rem";
  } else return ".5rem";
};
export const handleTableDataColor = (item: any, subItem: any) => {
  if (item?.isDeleted) {
    return "#EB5757";
  } else if (item?.printNotes === "Enable" && !item?.isDeleted && subItem?.value === "printNotes") {
    return "#2D9CDB";
  } else if (item?.printNotes === "Disable" && !item.isDeleted && subItem?.value === "printNotes") {
    return "#EB5757";
  } else if (item?.printCustomerInfo === "Enable" && !item?.isDeleted && subItem?.value === "printCustomerInfo") {
    return "#2D9CDB";
  } else if (item?.printCustomerInfo === "Disable" && !item?.isDeleted && subItem?.value === "printCustomerInfo") {
    return "#EB5757";
  } else return "#4F4F4F";
};

export const refactorCompanyCountryQuery = (countries: any) => {
  if (countries && countries.length > 0) {
    return `&countries=${countries.join(",")}`;
  } else return "&countries=undefined";
};
export const refactorCompanyPlanListQuery = (planList: any) => {
  if (planList && planList.length > 0) {
    return `&planList=${planList.join(",")}`;
  } else return "&planList=undefined";
};
export const refactorSelectedModule = (selectedOption: string, singleDetail?: any) => {
  if (selectedOption === "info") {
    return "Company Information";
  } else if (selectedOption === "overview") {
    return "Company Overview";
  } else if (selectedOption === "subscription") {
    return "Subscription";
  } else if (selectedOption === "subscription_details") {
    return "Subscription details";
  } else if (selectedOption === "subscription_single") {
    return " > Invoice";
  } else if (selectedOption === "paymentChannel") {
    return "Payment Channels";
  } else if (selectedOption === "taxes") {
    return "Taxes";
  } else if (selectedOption === "stores") {
    return "Taxes";
  } else if (selectedOption === "stores_single") {
    return ` > ${singleDetail}`;
  } else if (selectedOption === "pointOfSales") {
    return "POS's";
  } else if (selectedOption.includes("pointOfSales_single")) {
    return "POS";
  } else if (selectedOption === "users") {
    return "Users";
  } else if (selectedOption.includes("users_single")) {
    return ` > ${singleDetail}`;
  } else if (selectedOption === "roles") {
    return "Roles";
  } else if (selectedOption.includes("roles_single")) {
    return `${singleDetail}`;
  } else return "";
};

export const refactorLogsAdditionalInfo = (selectedOption: string) => {
  if (selectedOption === "taxes" || selectedOption.includes("taxes_single")) {
    return true;
  } else if (selectedOption === "stores" || selectedOption.includes("stores_single")) {
    return true;
  } else if (selectedOption === "pointOfSales" || selectedOption.includes("pointOfSales_single")) {
    return true;
  } else if (selectedOption === "users" || selectedOption.includes("users_single")) {
    return true;
  } else if (selectedOption === "roles" || selectedOption.includes("roles_single")) {
    return true;
  } else if (selectedOption === "overview") {
    return true;
  } else if (selectedOption === "paymentChannel") {
    return true;
  } else if (selectedOption === "subscription") {
    return true;
  } else if (selectedOption.includes("subscription_details")) {
    return true;
  }
};
export const refactorLogsAdditionalFeilds = (selectedOption: string) => {
  if (selectedOption === "taxes") {
    return "Tax Name,Tax Id;taxName_taxId";
  } else if (selectedOption === "stores") {
    return "Store Name,Store ID;storeName_storeId";
  } else if (selectedOption === "pointOfSales") {
    return "POS Name, POS Id;posName_posId";
  } else if (selectedOption === "users") {
    return "User Name, User Id;userName_userId";
  } else if (selectedOption === "roles") {
    return "Role Name, Role Id;roleName_roleId";
  } else if (selectedOption.includes("users_single")) {
    return "User Name, User Id;userName_userId";
  } else if (selectedOption.includes("stores_single")) {
    return "Store Name,Store ID;storeName_storeId";
  } else if (selectedOption.includes("pointOfSales_single")) {
    return "POS Name, POS Id;posName_posId";
  } else if (selectedOption.includes("roles_single")) {
    return "Role Name, Role Id;roleName_roleId";
  } else if (selectedOption.includes("roles_single")) {
    return "Role Name, Role Id;roleName_roleId";
  } else if (selectedOption.includes("paymentChannel")) {
    return "Payment Channel Name, Payment Channel ID;channelName_channelId";
  }
};

export const refactorLogsResponseAttribute = (selectedOption: string, log: any) => {
  if (selectedOption === "overview") {
    if (log?.additionalInfo?.userName) {
      return "User Name, User Id;userName_userId";
    } else if (log?.additionalInfo?.storeName) {
      return "Store Name, Store Id;storeName_storeId";
    } else if (log?.additionalInfo?.posName) {
      return "POS Name, POS Id;posName_posId";
    } else if (log?.additionalInfo?.productName) {
      return "Product Name, Product Id;productName_productId";
    } else if (log?.additionalInfo?.invoiceNumber) {
      return "Invoice Number, Subscription Id, Amount;invoiceNumber_subscriptionId_amount";
    } else if (log?.additionalInfo?.invoiceId && log?.additionalInfo?.subscriptionId) {
      return "Plan Type,Subscription Id, Number Of Users;planName_subscriptionId_subscriptionUsers";
    } else if (log?.additionalInfo?.invoiceId && !log?.additionalInfo?.subscriptionId) {
      return "Invoice Name,Invoice Id;invoiceName_invoiceId";
    } else if (log?.additionalInfo?.cardNumber) {
      return "Card Number, ;cardNumber_";
    } else if (log?.additionalInfo?.subscriptionId && log?.logMessage?.includes("Subscription")) {
      return "Subscription Id, ;subscriptionId_";
    } else if (log?.additionalInfo?.referenceNumber) {
      return "Amount, Reference Number, Subscription Id ;amount_referenceNumber_subscriptionId";
    } else if (log?.additionalInfo?.planName) {
      return "Plan Type,Subscription Id, Number Of Users;planName_subscriptionId_subscriptionUsers";
    } else if (log?.additionalInfo?.channelId) {
      return "Payment Channel Name, Payment Channel ID;channelName_channelId";
    } else if (log?.additionalInfo?.taxId) {
      return "Tax Name,Tax Id;taxName_taxId";
    } else if (log?.additionalInfo?.roleId) {
      return "Role Name, Role Id;roleName_roleId";
    }
  }
  if (selectedOption === "subscription") {
    if (log?.additionalInfo?.planName) {
      return "Plan Type,Subscription Id, Number Of Users;planName_subscriptionId_subscriptionUsers";
    } else if (log?.additionalInfo?.invoiceNumber) {
      return "Invoice Number, Subscription Id, Amount;invoiceNumber_subscriptionId_amount";
    } else if (log?.additionalInfo?.cardNumber) {
      return "Card Number, ;cardNumber_";
    } else if (log?.additionalInfo?.subscriptionId && log?.logMessage?.includes("Subscription")) {
      return "Subscription Id, ;subscriptionId_";
    } else if (log?.additionalInfo?.userId) {
      return "User Name, User Id, ;userName_userId";
    } else if (log?.additionalInfo?.referenceNumber) {
      return "Amount, Reference Number, Subscription Id ;amount_referenceNumber_subscriptionId";
    }
  } else if (selectedOption.includes("subscription_details")) {
    if (log?.additionalInfo?.planName) {
      return "Plan Type,Subscription Id, Number Of Users;planName_subscriptionId_subscriptionUsers";
    } else if (log?.additionalInfo?.invoiceNumber) {
      return "Invoice Number, Subscription Id, Amount;invoiceNumber_subscriptionId_amount";
    } else if (log?.additionalInfo?.cardNumber) {
      return "Card Number, ;cardNumber_";
    } else if (log?.additionalInfo?.subscriptionId && log?.logMessage?.includes("Subscription")) {
      return "Subscription Id ;subscriptionId_";
    } else if (log?.additionalInfo?.userId) {
      return "User Name, User Id, ;userName_userId";
    } else if (log?.additionalInfo?.referenceNumber) {
      return "Amount, Reference Number, Subscription Id ;amount_referenceNumber_subscriptionId";
    }
  } else if (selectedOption.includes("pointOfSale")) {
    if (log?.additionalInfo?.printerName) {
      return "Printer Name, Printer Id, POS Name, POS Id;printerName_printerId_posName_posId";
    }
    if (log?.additionalInfo?.posName) {
      return "POS Name, POS Id;posName_posId";
    }
  }
};

export const refactorPlans = (user: any, value: string) => {
  if (user?.companiesFilter?.planList?.includes(value)) {
    return true;
  } else return false;
};
export const refactorColoumns = (user: any, value: string) => {
  if (user?.columHeaders?.includes(value)) {
    return true;
  } else return false;
};

export const refactorRegisterValueAndLabel = (startDate: any, endDate: any) => {
  let registerVal;
  let registerLabel;

  let regStartDate = moment(startDate).format("YYYY/MM/DD");
  let regEndDate = moment(endDate).format("YYYY/MM/DD");

  if (regStartDate === moment().subtract(1, "days").format("YYYY/MM/DD") && regEndDate === moment().subtract(1, "days").format("YYYY/MM/DD")) {
    registerVal = "Yesterday";
    registerLabel = "Yesterday";
  } else if (regStartDate === moment().subtract(1, "week").format("YYYY/MM/DD") && regEndDate === moment().format("YYYY/MM/DD")) {
    registerVal = "LastWeek";
    registerLabel = "Last Week";
  } else if (regStartDate === moment().subtract(7, "days").format("YYYY/MM/DD") && regEndDate === moment().subtract(1, "days").format("YYYY/MM/DD")) {
    registerVal = "Last7Days";
    registerLabel = "Last 7 Days";
  } else if (regStartDate === moment().subtract(30, "days").format("YYYY/MM/DD") && regEndDate === moment().format("YYYY/MM/DD")) {
    registerVal = "Last30Days";
    registerLabel = "Last 30 Days";
  } else if (regStartDate === moment().startOf("week").format("YYYY/MM/DD") && regEndDate === moment().endOf("week").format("YYYY/MM/DD")) {
    registerVal = "ThisWeek";
    registerLabel = "This Week";
  } else if (
    regStartDate === moment().subtract(1, "month").startOf("month").format("YYYY/MM/DD") &&
    regEndDate === moment().subtract(1, "month").endOf("month").format("YYYY/MM/DD")
  ) {
    registerVal = "LastMonth";
    registerLabel = "Last Month";
  } else if (regStartDate === moment().startOf("month").format("YYYY/MM/DD") && regEndDate === moment().endOf("month").format("YYYY/MM/DD")) {
    registerVal = "ThisMonth";
    registerLabel = "This Month";
  } else if (regStartDate === moment().format("YYYY/MM/DD") && regEndDate === moment().format("YYYY/MM/DD")) {
    registerVal = "Today";
    registerLabel = "Today";
  } else {
    registerVal = "CustomRange";
    registerLabel = "Custom Range";
  }

  return {
    registerLabel,
    registerVal,
  };
};

export const options = [
  { lable: "Today", value: "Today" },
  { lable: "Yesterday", value: "Yesterday" },
  { lable: "This Week", value: "ThisWeek" },
  { lable: "Last Week", value: "LastWeek" },
  { lable: "Last 7 Days", value: "Last7Days" },
  { lable: "This Month", value: "ThisMonth" },
  { lable: "Last Month", value: "LastMonth" },
  { lable: "Last 30 Days", value: "Last30Days" },
  { lable: "Custom Range", value: "CustomRange" },
];

export const pdfHandler = (content: any, img: any, setAttachmentPath: any, setScreenshotLoading: any, pdfBase64: string) => {
  if (content) {
    const pdf = new jsPDF({
      unit: "pt",
      format: "a4",
      orientation: "portrait",
    });
    html2canvas(content, { scale: 2, logging: true, useCORS: true }).then((canvas) => {
      // Convert canvas to Base64 image
      img.current = canvas.toDataURL("image/jpeg", 1);
      if (img.current) {
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(img.current, "JPEG", 0, 0, pdfWidth, pdfHeight);
        const pdfBase64WithPrefix = pdf.output("datauristring");
        pdfBase64 = pdfBase64WithPrefix.split(",")[1];
        if (pdfBase64?.length > 0) {
          setAttachmentPath(pdfBase64);
        }
        setScreenshotLoading(false);
      }
    });
  }
};
