import { useSelector } from "react-redux";
import Table from "../table/Table";
import { StoreState } from "../../../../redux/storeState/storeState";

interface StoreDetailsIProp {
  setSelectedOption: any;
  posHeadData: any;
  pos: any;
  page: number;
  size: number;
  setpage: any;
  setsize: any;
  total: number;
  handlePosSearch: any;
  handleSortPosClick: any;
  handlePosSort: any;
  posSearchText: any;
  selectedPosHeader: any;
  setPosSearchText: any;
  resetHandler: any;
}
function PosDeviceDetails({
  setSelectedOption,
  pos,
  posHeadData,
  page,
  setpage,
  setsize,
  size,
  total,
  handlePosSearch,
  handleSortPosClick,
  handlePosSort,
  posSearchText,
  selectedPosHeader,
  setPosSearchText,
  resetHandler,
}: StoreDetailsIProp) {
  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; POS’s</div>
        <button
          className="back-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Business ID {companyInfo?.businessId}</div>
      <div className="mt-3">
        <Table
          setSelectedOption={setSelectedOption}
          tableHeadData={posHeadData}
          tableBodyData={pos}
          page={Number(page)}
          size={Number(size)}
          setPage={setpage}
          setSize={setsize}
          total={total}
          handleSiteSearch={handlePosSearch}
          handleArrowSortClick={handleSortPosClick}
          handleSort={handlePosSort}
          searchText={posSearchText}
          selectedSiteHeader={selectedPosHeader}
          setsiteSearchText={setPosSearchText}
          subscriptionView={false}
          placeholder="Search POS"
          singleDetailPath="pointOfSales_single"
          showEmptyScreen={pos?.length > 0 ? false : true}
          resetHandler={resetHandler}
        />
      </div>
    </div>
  );
}

export default PosDeviceDetails;
