import { useState } from "react";
import eyeIcon from "../../../src/assets/images/eyeIcon.svg";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import * as authActions from "../.././redux/auth/authAction";
// import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/form-input/input-component";
import { EMAIL_REGEX } from "../../config/validations";

function Login() {
  const dispatch: AppDispatch | any = useDispatch();

  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const [registerInitialValues, setregisterInitialValues] = useState({
    email: "",
    password: "",
  });

  const loginHandler = (e: any) => {
    const loginData = {
      email: registerInitialValues.email,
      password: registerInitialValues.password,
    };

    dispatch(authActions.login(loginData, navigate)).then((res: any) => {
      dispatch(authActions.whoAmI());
    });
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-heading">Login to Fatoraty Control Panel</div>
        <div className="login-subheading">Enter your username and password</div>
        <form className="form-controls">
          <Input
            type="text"
            name="email"
            placeholder="Email"
            value={registerInitialValues.email}
            onChange={(e: any) => setregisterInitialValues({ ...registerInitialValues, email: e.target.value })}
          />
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            suffix={eyeIcon}
            name="password"
            suffixAction={() => {
              setshowPassword(!showPassword);
            }}
            value={registerInitialValues.password}
            onChange={(e: any) => setregisterInitialValues({ ...registerInitialValues, password: e.target.value })}
          />
          <div className="btn-wrapper">
            <Link to="/forgot-password" style={{ textDecoration: "none" }}>
              {" "}
              <p>Forgot Password?</p>
            </Link>
            <button
              disabled={
                registerInitialValues.email === "" ||
                registerInitialValues.password === "" ||
                (EMAIL_REGEX.test(registerInitialValues.email) ? false : true)
              }
              type="button"
              onClick={loginHandler}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
