import { useSelector } from "react-redux";
import Table from "../table/Table";
import { StoreState } from "../../../../redux/storeState/storeState";
interface PosDetailsIProp {
  setSelectedOption: any;
  printerHeadData: any;
  printers: any;
  page: number;
  size: number;
  setpage: any;
  setsize: any;
  total: number;
  handlePrinterSearch: any;
  handleSortPrinterClick: any;
  handlePrinterSort: any;
  printerSearchText: any;
  selectedPrinterHeader: any;
  setPrinterSearchText: any;
  posForEdit?: any;
  resetSearchingHandler?: any;
}
function SinglePosDetail({
  handlePrinterSearch,
  handlePrinterSort,
  handleSortPrinterClick,
  page,
  printerHeadData,
  printerSearchText,
  printers,
  selectedPrinterHeader,
  setPrinterSearchText,
  setSelectedOption,
  setpage,
  setsize,
  size,
  posForEdit,
  total,
  resetSearchingHandler,
}: PosDetailsIProp) {
  let posInformation = [
    {
      label: "Name",
      value: posForEdit?.name,
    },
    {
      label: "Store",
      value: posForEdit?.store,
    },
    {
      label: "Code",
      value: posForEdit?.code,
    },
  ];

  let generalSetting = [
    {
      label: "Use camera xcan barcodes",
      value: "Enabled",
    },
    {
      label: "Home screen item layout",
      value: posForEdit?.layout,
    },
  ];

  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));

  let refactoredPrinters = printers?.map((item: any) => {
    return {
      ...item,
      id: item?.businessId,
      name: item?.payLoad?.name,
      interface: item?.payLoad?.interface,
      model: item?.payLoad?.printermodel,
      paperWidth: item?.payLoad?.paperwidth,
      printNotes: item?.payLoad?.printreceipts ? "Enabled" : "Disabled",
      printCustomerInfo: item?.payLoad?.printreceipts ? "Enabled" : "Disabled",
    };
  });
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; POS's</div>
        <button
          className="back-btn"
          onClick={() => {
            setSelectedOption(`pointOfSales`);
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">POS ID {posForEdit?.businessId}</div>
      <div className="payment-card-wrap mt-3 w-100">
        <p className="pos-title">Information</p>
        {posInformation.map((item: any, index: number) => {
          return (
            <div key={index} className="pos-wrap my-2">
              <div className="py-1">{item?.label}</div>
              <div>{item?.value}</div>
            </div>
          );
        })}
      </div>
      <div className="payment-card-wrap mt-3 w-100">
        <p className="pos-title small">General Settings</p>
        {generalSetting.map((item: any, index: number) => {
          return (
            <div key={index} className="pos-wrap small my-2">
              <div className="py-2">{item.label}</div>
              <div style={{ color: item?.value?.includes("Enabled") ? "#2D9CDB" : "#4F4F4F" }}>{item?.value}</div>
            </div>
          );
        })}
      </div>
      <div className="payment-card-wrap mt-3 w-100">
        <p className="pos-title">Printers</p>
        <div className="mt-3">
          <Table
            setSelectedOption={setSelectedOption}
            tableHeadData={printerHeadData}
            tableBodyData={refactoredPrinters}
            page={page}
            size={size}
            setPage={setpage}
            setSize={setsize}
            total={total}
            handleSiteSearch={handlePrinterSearch}
            handleArrowSortClick={handleSortPrinterClick}
            handleSort={handlePrinterSort}
            searchText={printerSearchText}
            selectedSiteHeader={selectedPrinterHeader}
            setsiteSearchText={setPrinterSearchText}
            subscriptionView={false}
            placeholder="Search Printers"
            singleDetailPath="none"
            resetHandler={resetSearchingHandler}
          />
        </div>{" "}
      </div>
    </div>
  );
}

export default SinglePosDetail;
