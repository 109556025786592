import { useEffect, useRef, useState } from "react";
import CompanySideInfo from "./CompanySideInfo";
import CompanyLogs from "./CompanyLogs";
import * as companyAction from "../../../redux/companies/companiesAction";
import { CompanyLogsForEditDTO, CompanySubscriptionDTO } from "../../../interfaces/companyInterface";
import { useSidebar } from "../../useSidebarProvider";
// import { jsPDF } from "jspdf";

import {
  pdfHandler,
  refactorLogsAdditionalFeilds,
  refactorLogsAdditionalInfo,
  refactorLogsTilte,
  sortTableHeader,
} from "../../../helper/helperFunctions";
import CompanyInformation from "./info/CompanyInformation";
import CompanyDetails from "./CompanyDetails";
import InvoiceDetails from "./subscription/InvoiceDetails";
import PaymentChannels from "./payment-channel/PaymentChannels";
import TaxDetails from "./taxes/TaxDetails";
import StoreDetails from "./stores/storeDetails";
import SingleStoreDetails from "./stores/SingleStoreDetails";
import PosDeviceDetails from "./pos/PosDeviceDetails";
import SinglePosDetail from "./pos/SinglePosDetail";
import UserDetails from "./users/UserDetails";
import SingleUserDetails from "./users/SingleUserDetails";
import RolesDetails from "./roles/RolesDetails";
import SingleRoleDetails from "./roles/SingleRoleDetails";
import CompanySubscription from "./subscription/CompanySubscription";
import SubscriptionDetails from "./subscription/SubscriptionDetail";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../redux/storeState/storeState";
import { AppDispatch } from "../../../redux/store";

function CompanyOverview() {
  //eslint-disable-next-line
  const [subscriptionData, setsubscriptionData] = useState<CompanySubscriptionDTO[]>();
  const [userPage, setuserPage] = useState(0);
  const [userSize, setuserSize] = useState(0);
  const [userSearchText, setuserSearchText] = useState("");
  const [userTotal, setuserTotal] = useState(0);
  //eslint-disable-next-line
  const [userSortAt, setuserSortAt] = useState("buisnessId");
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [userChartsData, setuserChartsData] = useState<any>([]);
  const [subscriptionChartsData, setSubscriptionChartsData] = useState<any>([]);
  const [screenshotLoading, setScreenshotLoading] = useState(false);
  const userTableHeader = [
    {
      id: "1",
      sortBy: "desc",
      label: "ID",
      value: "businessId",
      sortAt: "businessId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "NAME",
      value: "name",
      sortAt: "name",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "EMAIL",
      value: "email",
      sortAt: "email",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "ROLE",
      value: "role",
      sortAt: "role",
    },
    {
      id: "5",
      sortBy: "desc",
      label: "LOGINS",
      value: "loginAttempts",
      sortAt: "logins",
    },
  ];
  const rolesTableHeader = [
    {
      id: "1",
      sortBy: "desc",
      label: "ID",
      value: "businessId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "NAME",
      value: "name",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "USERS",
      value: "users",
    },
  ];
  //eslint-disable-next-line
  const [allcompanyRoles, setallcompanyRoles] = useState<any>([
    {
      id: 1003,
      name: "Supervisor",
      users: 3,
      isDeleted: false,
    },
    {
      id: 1005,
      name: "Manager",
      users: 3,
      isDeleted: true,
    },
    {
      id: 1007,
      name: "Inspector",
      users: 3,
      isDeleted: false,
    },
  ]);
  //eslint-disable-next-line
  const [allTaxes, setallTaxes] = useState([
    {
      id: 1,
      name: "VAT",
      scheme: "Included in the price",
      rate: "15%",
    },
  ]);
  const [invoiceSize, setInvoiceSize] = useState(10);
  //eslint-disable-next-line
  const [paymentCardData, setpaymentCardData] = useState<any>({});
  const [channelspage, setchannelspage] = useState(1);
  const [rolesPage, setrolesPage] = useState(0);
  const [rolesSize, setrolesSize] = useState(0);
  const [rolesTotal, setrolesTotal] = useState(0);
  const [roleSearchText, setroleSearchText] = useState("");
  //eslint-disable-next-line
  const [channelsTotal, setchannelsTotal] = useState(0);
  const [channelsSize, setchannelsSize] = useState(0);
  const [channelSearchText, setchannelSearchText] = useState("");
  const [taxPage, setTaxPage] = useState(0);
  const [taxSize, setTaxSize] = useState(0);
  //eslint-disable-next-line
  const [taxesTotal, settaxesTotal] = useState(0);
  const [taxSearchText, setTaxSearchText] = useState("");
  const [isSubsDateOpen, setisSubsDateOpen] = useState(false);
  //eslint-disable-next-line
  const [allPaymentChannels, setallPaymentChannels] = useState([
    { id: "1001", name: "Bank transfer", status: "Enabled", default: false, isDeleted: false },
    { id: "1003", name: "Card", status: "Enabled", default: true, isDeleted: false },
    { id: "1005", name: "Visa Card", status: "Enabled", default: false, isDeleted: true },
    { id: "1005", name: "Cash", status: "Enabled", default: false, isDeleted: false },
  ]);
  const [attachmentPath, setAttachmentPath] = useState("");
  //eslint-disable-next-line
  const [subscriptionTableData, setsubscriptionTableData] = useState([
    {
      id: "QzqKjkR2pnkMr1Syu",
      service: "User management",
      plan: "Monthly",
      startDate: "Feb 15, 2024",
      endDate: "Feb 15, 2024",
      status: "Active",
      amount: 234,
    },
  ]);
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [subscriptionSelectedDate, setsubscriptionSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [invoiceSelectedDate, setinvoiceSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [storesTotal, setStoresTotal] = useState(0);
  const [roleForEditDetails, setroleForEditDetails] = useState({});
  const [storeSearchText, setstoreSearchText] = useState("");
  //eslint-disable-next-line
  const [storesSize, setStoresSize] = useState(10);
  const [storePage, setstorePage] = useState(0);
  const [storeSize, setstoreSize] = useState(0);
  const [invoiceSearchText, setinvoiceSearchText] = useState("");
  const [posSize, setposSize] = useState(0);
  const [posPage, setposPage] = useState(0);
  const [posTotal, setposTotal] = useState(0);
  const [posSearchText, setposSearchText] = useState("");
  const [posSortAt, setposSortAt] = useState("id");
  const [printerPage, setprinterPage] = useState(0);
  const [printerSize, setprinterSize] = useState(0);
  //eslint-disable-next-line
  const [printerTotal, setprinterTotal] = useState(0);
  //eslint-disable-next-line
  const [printerSortAt, setprinterSortAt] = useState("id");
  const [printerSearchText, setprinterSearchText] = useState("");

  const [selectedRolesHeader, setselectedRolesHeader] = useState(rolesTableHeader[0]);
  const invoiceHeadData = [
    {
      id: "1",
      sortBy: "desc",
      label: "Number",
      value: "invoiceNumber",
      sortAt: "invoiceNumber",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "Date",
      value: "date",
      sortAt: "invoiceDate",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "Type",
      value: "invoiceType",
      sortAt: "invoiceType",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "Billing Period",
      value: "billingPeriod",
      sortAt: "invoiceBillingPeriod",
    },
    {
      id: "5",
      sortBy: "desc",
      label: "Service",
      value: "service",
      sortAt: "invoiceService",
    },
    {
      id: "6",
      sortBy: "desc",
      label: "Plan",
      value: "plan",
      sortAt: "invoicePlan",
    },
    {
      id: "7",
      sortBy: "desc",
      label: "Amount",
      value: "amount",
      sortAt: "invoicPrice",
    },
  ];
  const channelsHeadData = [
    {
      id: "1",
      sortBy: "desc",
      label: "ID",
      value: "businessId",
      sortAt: "paymentChannelBusinessId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "NAME",
      value: "name",
      sortAt: "paymentChannelName",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "STATUS",
      value: "status",
      sortAt: "paymentChannelStatus",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "DEFAULT",
      value: "default",
      sortAt: "paymentChannelDefault",
    },
  ];
  const subscriptionHeadData = [
    {
      id: "1",
      sortBy: "desc",
      label: "Subscription ID",
      value: "subscriptionId",
      sortAt: "subscriptionId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "Service",
      value: "service",
      sortAt: "service",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "Plan",
      value: "plan",
      sortAt: "plan",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "Start Date",
      value: "subscriptionStartedAt",
      sortAt: "startDate",
    },
    {
      id: "5",
      sortBy: "desc",
      label: "End Date",
      value: "subscriptionStartedAt",
      sortAt: "endDate",
    },
    {
      id: "6",
      sortBy: "desc",
      label: "Status",
      value: "status",
      sortAt: "status",
    },
    {
      id: "7",
      sortBy: "desc",
      label: "Amount",
      value: "amount",
      sortAt: "amount",
    },
  ];
  const taxesHeadData = [
    {
      id: "1",
      sortBy: "desc",
      label: "ID",
      value: "businessId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "NAME",
      value: "name",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "SCHEME",
      value: "scheme",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "RATE",
      value: "rate",
    },
  ];
  const storesHeadData = [
    {
      id: "1",
      sortBy: "desc",
      label: "ID",
      value: "businessId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "NAME",
      value: "name",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "EMAIL",
      value: "email",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "POS",
      value: "pos",
    },
  ];
  const posHeadData = [
    {
      id: "1",
      sortBy: "desc",
      label: "ID",
      value: "businessId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "NAME",
      value: "name",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "STORE",
      value: "store",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "CODE",
      value: "code",
    },
  ];
  const printerHeadData = [
    {
      id: "1",
      sortBy: "desc",
      label: "ID",
      value: "id",
      sortAt: "printerBusinessId",
    },
    {
      id: "2",
      sortBy: "desc",
      label: "NAME",
      value: "name",
      sortAt: "printerName",
    },
    {
      id: "3",
      sortBy: "desc",
      label: "PRITER MODEL",
      value: "model",
      sortAt: "printerModel",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "INTERFACE",
      value: "interface",
      sortAt: "printerInterface",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "PEPAR WIDTH",
      value: "paperWidth",
      sortAt: "printerWidth",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "PRINT NOTES",
      value: "printNotes",
      sortAt: "printerNotes",
    },
    {
      id: "4",
      sortBy: "desc",
      label: "PRINT CUSTOMER INFO",
      value: "printCustomerInfo",
      sortAt: "printerCustomerInfo",
    },
  ];
  //eslint-disable-next-line
  const [selectedInvoiceHeader, setselectedInvoiceHeader] = useState(invoiceHeadData[0]);
  const [selectedTaxHeader, setSelectedTaxHeader] = useState(taxesHeadData[0]);
  const [selectedStoreHeader, setSelectedStoreHeader] = useState(storesHeadData[0]);
  const [selectedChannelHeader, setselectedChannelHeader] = useState(channelsHeadData[0]);
  const [selectedPosHeader, setselectedPosHeader] = useState(posHeadData[0]);
  //eslint-disable-next-line
  const [selectedSubscriptionHeader, setSelectedSubscriptionHeader] = useState(subscriptionHeadData[0]);
  const [channelSortAt, setchannelSortAt] = useState("paymentChannelBusinessId");
  //eslint-disable-next-line
  const [taxSortAt, setTaxSortAt] = useState("id");
  const [invoicePage, setinvoicePage] = useState(0);
  //eslint-disable-next-line
  const [invoiceTotal, setinvoiceTotal] = useState(0);
  const [subscriptionPage, setSubscriptionPage] = useState(0);
  const [subscriptionSize, setSubscriptionSize] = useState(0);
  //eslint-disable-next-line
  const [subscriptionTotal, setsubscriptionTotal] = useState(0);
  const [totalLogsCount, settotalLogsCount] = useState(0);
  const [companyInitialState, setcompanyInitialState] = useState({
    name: "",
    businessId: "",
    plan: "",
    status: "",
    industry: "",
    email: "",
    phone: "",
    registerDate: "",
    lastActivity: "",
    country: "",
    image: "",
    isSuspended: false,
    companyChartsToShow: [
      {
        title: "",
        value: 0,
        perDayCalculation: [],
      },
    ],
    xAxisData: [""],
  });
  const [companyLogs, setcompanyLogs] = useState<CompanyLogsForEditDTO[]>([]);
  const [size, setsize] = useState(10);
  const [logSearchText, setlogSearchText] = useState("");
  const [closeSearchBarIfValue, setCloseSearchBarIfValue] = useState(true);
  const [chartsLoading, setchartsLoading] = useState(true);
  const [storeDetails, setstoreDetails] = useState({});
  const [invoiceDetails, setinvoiceDetails] = useState({});
  //eslint-disable-next-line
  const [invoiceData, setinvoiceData] = useState([
    {
      number: "A1-00239",
      date: "Feb 15, 2024",
      type: "Invoice",
      billingPeriod: "Feb 15, 24 - Mar 15, 24",
      service: "User management (5)",
      plan: "Monthly",
      amount: "SAR 109.25",
    },
  ]);
  //eslint-disable-next-line
  const [storeSortAt, setstoreSortAt] = useState("businessId");
  const [showInvoiceSendModal, setshowInvoiceSendModal] = useState(false);
  const [singleUserData, setsingleUserData] = useState({});
  //eslint-disable-next-line
  const [singlePosData, setsinglePosData] = useState({});
  const [allStores, setallStores] = useState([
    {
      id: 1003,
      name: "Huawei Nakheel Mall",
      email: "info@shopnow.com",
      pos: 3,
    },
  ]);
  const [allPos, setallPos] = useState([
    {
      id: 1005,
      name: "POS 2",
      store: "Dammam",
      code: 3,
      isDeleted: false,
      _id: "",
    },
    {
      id: 1005,
      name: "POS 2",
      store: "Dammam",
      code: 3,
      isDeleted: true,
      _id: "",
    },
    {
      id: 1005,
      name: "POS 2",
      store: "Store 2",
      code: 3,
      isDeleted: false,
      _id: "",
    },
  ]);
  //eslint-disable-next-line
  const [allPrinters, setallPrinters] = useState<any>([]);

  const { id } = useParams();

  const {
    companyForEdit,
    allModuleLogsData,
    totalLogs,
    allStoresData,
    allStoresPage,
    allStoresSize,
    storesTotalCount,
    storeForEdit,
    allPosData,
    allPosPage,
    allPosSize,
    posTotalCount,
    alluserData,
    alluserPage,
    alluserSize,
    userTotalCount,
    userForEdit,
    allUserChartsData,
    allRolesData,
    allRolesPage,
    allRolesSize,
    rolesTotalCount,
    roleForEdit,
    paymentChannels,
    channelDataPage,
    channelTotalCount,
    channelDataSize,
    allTaxesData,
    taxDataPage,
    taxDataSize,
    taxTotalCount,
    subscriptionChartsToShow,
    paymentCardForEdit,
    allSubscriptions,
    companySubscriptionPage,
    companySubscriptionSize,
    companySubscriptionTotal,
    allInvoices,
    invoiceDataPage,
    invoiceDataSize,
    invoiceDataTotal,
    invoiceForEdit,
    posPrinters,
    posPrinterPage,
    posPrinterSize,
    posPrinterTotal,
  } = useSelector((state: StoreState) => ({
    companyForEdit: state.companies.companySideInfoData,
    allModuleLogsData: state.companies.allModuleLogs,
    totalLogs: state.companies.logsTotal,
    allStoresData: state.companies.allStores,
    allStoresPage: state.companies.storesPage,
    allStoresSize: state.companies.storeSize,
    storesTotalCount: state.companies.storeTotalCount,
    storeForEdit: state.companies.storeForEdit,
    allPosData: state.companies.allPos,
    allPosPage: state.companies.posPage,
    allPosSize: state.companies.posSize,
    posTotalCount: state.companies.posTotalCount,
    alluserData: state.companies.allUsers,
    alluserPage: state.companies.userPage,
    alluserSize: state.companies.userSize,
    userTotalCount: state.companies.userTotalCount,
    userForEdit: state.companies.userForEdit,
    allUserChartsData: state.companies.userChartsData,
    allRolesData: state.companies.allRoles,
    allRolesPage: state.companies.rolesPage,
    allRolesSize: state.companies.roleSize,
    rolesTotalCount: state.companies.rolesTotalCount,
    roleForEdit: state.companies.roleForEdit,
    paymentChannels: state.companies.paymentChannels,
    channelDataSize: state.companies.paymentChannelSize,
    channelDataPage: state.companies.paymentChannelPage,
    channelTotalCount: state.companies.paymentChannelTotal,
    allTaxesData: state.companies.allTaxes,
    taxDataPage: state.companies.taxesPage,
    taxDataSize: state.companies.taxesSize,
    taxTotalCount: state.companies.taxesTotal,
    subscriptionChartsToShow: state.companies.subscriptionCharts,
    paymentCardForEdit: state.companies.companyPaymentCardData,
    allSubscriptions: state.companies.companySubscriptionData,
    companySubscriptionPage: state.companies.subscriptionPage,
    companySubscriptionSize: state.companies.subscriptionSize,
    companySubscriptionTotal: state.companies.subscriptionTotal,
    allInvoices: state.companies.subscriptionInvoices,
    invoiceDataPage: state.companies.invoicesPage,
    invoiceDataSize: state.companies.invoicesSize,
    invoiceDataTotal: state.companies.invoicesTotal,
    invoiceForEdit: state.companies.invoiceForEdit,
    posPrinters: state.companies.printers,
    posPrinterPage: state.companies.printerPage,
    posPrinterSize: state.companies.printerSize,
    posPrinterTotal: state.companies.printerTotal,
  }));

  const [slectedLable, setslectedLable] = useState("Last 30 Days");
  const [selectedValue, setselectedValue] = useState("Last30Days");
  const [subscriptionSelectedValue, setsubscriptionSelectedValue] = useState("Last30Days");
  const [subscriptionSelectedLabel, setsubscriptionSelectedLabel] = useState("Last 30 Days");
  const [invoiceSelectedValue, setInvoiceSelectedValue] = useState("Last30Days");
  const [invoiceSelectedLabel, setInvoiceSelectedLabel] = useState("Last 30 Days");
  //eslint-disable-next-line
  const [companyLogsLoading, setcompanyLogsLoading] = useState(true);
  //eslint-disable-next-line
  const [companyLoading, setcompanyLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("info");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [subscriptionSearchText, setSubscriptionSearchText] = useState("");
  const [selectedUserHeader, setselectedUserHeader] = useState(userTableHeader[0]);
  const [selectedPrinterHeader, setselectedPrinterHeader] = useState(printerHeadData[0]);
  const [posForEdit, setposForEdit] = useState({});

  let options = [
    { lable: "Today", value: "Today" },
    { lable: "Yesterday", value: "Yesterday" },
    { lable: "This Week", value: "ThisWeek" },
    { lable: "Last Week", value: "LastWeek" },
    { lable: "Last 7 Days", value: "Last7Days" },
    { lable: "This Month", value: "ThisMonth" },
    { lable: "Last Month", value: "LastMonth" },
    { lable: "Last 30 Days", value: "Last30Days" },
    { lable: "Custom Range", value: "CustomRange" },
  ];
  const dateOptions = [
    { lable: "Today", value: "Today" },
    { lable: "Yesterday", value: "Yesterday" },
    { lable: "This Week", value: "ThisWeek" },
    { lable: "Last Week", value: "LastWeek" },
    { lable: "Last 7 Days", value: "Last7Days" },
    { lable: "This Month", value: "ThisMonth" },
    { lable: "Last Month", value: "LastMonth" },
    { lable: "Last 30 Days", value: "Last30Days" },
    { lable: "Custom Range", value: "CustomRange" },
  ];
  //eslint-disable-next-line
  const [allUsers, setallUsers] = useState<any>([]);
  const [selectedCountryOption, setselectedCountryOption] = useState("");
  const [selectedCurrency, setselectedCurrency] = useState("");
  const handleSelectedItem = (option: any) => {
    setselectedCountryOption(option.name);
  };
  const handleSelectedCurrency = (option: any) => {
    setselectedCurrency(option.code);
  };
  const [isEditCase, setisEditCase] = useState(false);
  const [showExitModal, setshowExitModal] = useState(false);
  const [showSuccessUpdation, setshowSuccessUpdation] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [searchCountryValue, setSearchCountryValue] = useState("");
  const [searchCurrencyValue, setSearchCurrencyValue] = useState("");

  const handleEdit = () => {
    if (!isEditCase) {
      setisEditCase(true);
    } else {
      setshowSuccessUpdation(true);
      handleModifyCompanyInfo();
      setisEditCase(false);
    }
  };
  const handleBack = () => {
    if (isEditCase) {
      setshowExitModal(true);
    } else {
      setisEditCase(false);
      window.history.back();
    }
  };
  const handleCancel = () => {
    setshowExitModal(false);
  };
  const handleConfirmation = () => {
    setisEditCase(false);
    setshowExitModal(false);
    setselectedCountryOption(companyForEdit?.country);
    setselectedCurrency(companyForEdit?.currency);
  };
  const dispatch: AppDispatch | any = useDispatch();
  useEffect(() => {
    if (id && companyForEdit && selectedOption === "info") {
      dispatch(companyAction.getCompanyInfoById(id, selectedDate.startDate, selectedDate.endDate));
    } else if (id && selectedOption === "stores") {
      dispatch(
        companyAction.getAllStores({
          companyId: id,
          page: storePage ? storePage : 1,
          size: storeSize ? storeSize : 10,
          searchName: storeSearchText,
          sortAt: selectedStoreHeader.value,
          sortBy: selectedStoreHeader.sortBy,
        })
      );
    } else if (selectedOption.includes("stores_single")) {
      let storeId = selectedOption.split("_").pop();
      if (storeId) {
        dispatch(companyAction.getStoreById(storeId));
      }
    } else if (selectedOption === "pointOfSales") {
      dispatch(
        companyAction.getAllPos({
          companyId: id!,
          page: posPage,
          size: posSize,
          sortAt: selectedPosHeader.value,
          sortBy: selectedPosHeader.sortBy,
          searchName: posSearchText,
        })
      );
    } else if (selectedOption.includes("pointOfSales_single")) {
      let posId = selectedOption.split("_").pop();
      let selectedPos = allPos.filter((item) => item?._id === posId);
      setposForEdit(selectedPos[0]);
      if (posId && posId !== "single") {
        dispatch(
          companyAction.getAllPrintersByPosId({
            posId: posId,
            page: printerPage,
            size: printerSize,
            sortAt: selectedPrinterHeader?.sortAt,
            sortBy: selectedPrinterHeader?.sortBy,
            searchName: printerSearchText,
          })
        );
      }
    } else if (selectedOption === "users") {
    } else if (selectedOption.includes("pointOfSales_single")) {
      let posId = selectedOption.split("_").pop();
      let selectedPos = allPos.filter((item) => item?._id === posId);
      setposForEdit(selectedPos[0]);
    } else if (selectedOption.includes("subscription_single")) {
      let invoiceId = selectedOption.split("_").pop();
      if (invoiceId) {
        dispatch(companyAction.getSubscriptionInvoiceById(invoiceId)).then(() => {
          setScreenshotLoading(true);
        });
      }
    } else if (selectedOption === "users") {
      dispatch(
        companyAction.getAllUsers({
          companyId: id!,
          page: userPage ? userPage : 1,
          size: userSize ? userSize : 10,
          sortAt: selectedUserHeader.sortAt,
          sortBy: selectedUserHeader.sortBy,
          searchName: userSearchText,
          startDate: selectedDate.startDate,
          endDate: selectedDate.endDate,
        })
      );
    } else if (selectedOption.includes("users_single")) {
      let userId = selectedOption.split("_").pop();
      if (userId && userId !== undefined) {
        dispatch(companyAction.getUserById(userId));
      }
    } else if (selectedOption === "roles") {
      dispatch(
        companyAction.getAllRoles({
          companyId: id!,
          page: rolesPage,
          size: rolesSize,
          searchName: roleSearchText,
          sortAt: selectedRolesHeader.value,
          sortBy: selectedRolesHeader.sortBy,
        })
      );
    } else if (selectedOption.includes("roles_single")) {
      let roleId = selectedOption.split("_").pop();
      if (roleId) {
        dispatch(companyAction.getRoleById(roleId));
      }
    }
    // eslint-disable-next-line
  }, [selectedOption]);
  useEffect(() => {
    setcompanyInitialState(companyForEdit);
    setselectedCountryOption(companyForEdit.country);
    setselectedCurrency(companyForEdit.currency);
    // eslint-disable-next-line
  }, [companyForEdit]);

  useEffect(() => {
    // eslint-disable-next-line
    setsingleUserData(userForEdit);
  }, [userForEdit]);

  // <---------------->
  const handleChannelSort = (item: any) => {
    sortTableHeader(item, setchannelSortAt);
    setselectedChannelHeader(item);
    if (selectedChannelHeader.value === item.value) {
      if (selectedChannelHeader.sortBy === "asc") {
        setselectedChannelHeader({ ...selectedChannelHeader, sortBy: "desc" });
      } else {
        setselectedChannelHeader({ ...selectedChannelHeader, sortBy: "asc" });
      }
    }
  };
  useEffect(() => {
    //do api call here
    dispatch(
      companyAction.getAllPaymentChannels({
        companyId: id!,
        searchName: channelSearchText,
        sortAt: selectedChannelHeader.sortAt,
        sortBy: selectedChannelHeader.sortBy,
        page: channelspage,
        size: channelsSize,
      })
    );
    let sortIcon = document.getElementById("sort-icon");
    if (selectedChannelHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedChannelHeader.sortBy, channelSortAt]);

  const handleArrowSortChannelClick = () => {
    if (selectedChannelHeader.sortBy === "asc") {
      setselectedChannelHeader({ ...selectedChannelHeader, sortBy: "desc" });
    } else {
      setselectedChannelHeader({ ...selectedChannelHeader, sortBy: "asc" });
    }
  };
  const handleTaxSort = (item: any) => {
    sortTableHeader(item, setTaxSortAt);
    setSelectedTaxHeader(item);
    if (selectedTaxHeader.value === item.value) {
      if (selectedTaxHeader.sortBy === "asc") {
        setSelectedTaxHeader({ ...selectedTaxHeader, sortBy: "desc" });
      } else {
        setSelectedTaxHeader({ ...selectedTaxHeader, sortBy: "asc" });
      }
    }
  };
  useEffect(() => {
    //do api call here
    dispatch(
      companyAction.getAllTaxesById({
        companyId: id!,
        page: taxPage,
        size: taxSize,
        searchName: taxSearchText,
        sortAt: selectedTaxHeader.value,
        sortBy: selectedTaxHeader.sortBy,
      })
    );
    let sortIcon = document.getElementById("sort-icon");
    if (selectedTaxHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedTaxHeader.sortBy, taxSortAt]);

  const handleSortTaxClick = () => {
    if (selectedTaxHeader.sortBy === "asc") {
      setSelectedTaxHeader({ ...selectedTaxHeader, sortBy: "desc" });
    } else {
      setSelectedTaxHeader({ ...selectedTaxHeader, sortBy: "asc" });
    }
  };
  const handleStoresSort = (item: any) => {
    sortTableHeader(item, setstoreSortAt);
    setSelectedStoreHeader(item);
    if (selectedStoreHeader.value === item.value) {
      if (selectedStoreHeader.sortBy === "asc") {
        setSelectedStoreHeader({ ...selectedStoreHeader, sortBy: "desc" });
      } else {
        setSelectedStoreHeader({ ...selectedStoreHeader, sortBy: "asc" });
      }
    }
  };
  useEffect(() => {
    dispatch(
      companyAction.getAllStores({
        companyId: id!,
        page: storePage ? storePage : 1,
        size: storeSize ? storeSize : 10,
        searchName: storeSearchText,
        sortAt: selectedStoreHeader.value,
        sortBy: selectedStoreHeader.sortBy,
      })
    );
    let sortIcon = document.getElementById("sort-icon");
    if (selectedStoreHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedStoreHeader.sortBy, selectedStoreHeader.value]);

  const handleArrowSortStoreClick = () => {
    if (selectedStoreHeader.sortBy === "asc") {
      setSelectedStoreHeader({ ...selectedStoreHeader, sortBy: "desc" });
    } else {
      setSelectedStoreHeader({ ...selectedStoreHeader, sortBy: "asc" });
    }
  };
  useEffect(() => {
    dispatch(
      companyAction.getAllPos({
        companyId: id!,
        page: storePage ? storePage : 1,
        size: storeSize ? storeSize : 10,
        searchName: storeSearchText,
        sortAt: selectedPosHeader.value,
        sortBy: selectedPosHeader.sortBy,
      })
    );
    let sortIcon = document.getElementById("sort-icon");
    if (selectedPosHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedPosHeader.sortBy, posSortAt]);

  const handleSorPosClick = () => {
    if (selectedPosHeader.sortBy === "asc") {
      setselectedPosHeader({ ...selectedPosHeader, sortBy: "desc" });
    } else {
      setselectedPosHeader({ ...selectedPosHeader, sortBy: "asc" });
    }
  };
  const handlePosSort = (item: any) => {
    sortTableHeader(item, setposSortAt);
    setselectedPosHeader(item);
    if (selectedPosHeader.value === item.value) {
      if (selectedPosHeader.sortBy === "asc") {
        setselectedPosHeader({ ...selectedPosHeader, sortBy: "desc" });
      } else {
        setselectedPosHeader({ ...selectedPosHeader, sortBy: "asc" });
      }
    }
  };
  useEffect(() => {
    if (selectedOption.includes("pointOfSales_single")) {
      let posId = selectedOption.split("_").pop();
      if (posId && posId !== "single") {
        dispatch(
          companyAction.getAllPrintersByPosId({
            posId: posId,
            page: printerPage,
            size: printerSize,
            sortAt: selectedPrinterHeader?.sortAt,
            sortBy: selectedPrinterHeader?.sortBy,
            searchName: printerSearchText,
          })
        );
      }
    }
    let sortIcon = document.getElementById("sort-icon");
    if (selectedPrinterHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedPrinterHeader.sortBy, selectedPrinterHeader.sortAt]);

  const handleSorPrinterClick = () => {
    if (selectedPrinterHeader.sortBy === "asc") {
      setselectedPrinterHeader({ ...selectedPrinterHeader, sortBy: "desc" });
    } else {
      setselectedPrinterHeader({ ...selectedPrinterHeader, sortBy: "asc" });
    }
  };
  const handlePrinterSort = (item: any) => {
    sortTableHeader(item, setprinterSortAt);
    setselectedPrinterHeader(item);
    if (selectedPrinterHeader.sortAt === item.sortAt) {
      if (selectedPrinterHeader.sortBy === "asc") {
        setselectedPrinterHeader({ ...selectedPrinterHeader, sortBy: "desc" });
      } else {
        setselectedPrinterHeader({ ...selectedPrinterHeader, sortBy: "asc" });
      }
    }
  };

  useEffect(() => {
    dispatch(
      companyAction.getAllRoles({
        companyId: id!,
        page: rolesPage ? rolesPage : 1,
        size: rolesSize ? rolesSize : 10,
        searchName: roleSearchText,
        sortAt: selectedRolesHeader.value,
        sortBy: selectedRolesHeader.sortBy,
      })
    );
    let sortIcon = document.getElementById("sort-icon");
    if (selectedRolesHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedRolesHeader.sortBy, selectedRolesHeader.value]);

  const handleSortRolesClick = () => {
    if (selectedRolesHeader.sortBy === "asc") {
      setselectedRolesHeader({ ...selectedRolesHeader, sortBy: "desc" });
    } else {
      setselectedRolesHeader({ ...selectedRolesHeader, sortBy: "asc" });
    }
  };

  const handleRolesSort = (item: any) => {
    sortTableHeader(item, setprinterSortAt);
    setselectedRolesHeader(item);
    if (selectedRolesHeader.value === item.value) {
      if (selectedRolesHeader.sortBy === "asc") {
        setselectedRolesHeader({ ...selectedRolesHeader, sortBy: "desc" });
      } else {
        setselectedRolesHeader({ ...selectedRolesHeader, sortBy: "asc" });
      }
    }
  };

  useEffect(() => {
    if (selectedValue !== "CustomRange") {
      const selectedOption = options.find((item) => item.value === selectedValue);
      setslectedLable(selectedOption?.lable || "");
    } else {
      setslectedLable(`${selectedDate.startDate} ~ ${selectedDate.endDate}`);
    }
    // eslint-disable-next-line
  }, [selectedValue]);

  useEffect(() => {
    setselectedItem(selectedOption);
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    if (selectedDate.startDate && selectedDate.endDate) {
      setchartsLoading(true);
      let singleLogSearch = selectedOption.split("_").shift();
      singleLogSearch = singleLogSearch?.slice(0, singleLogSearch?.length + 1);
      let actionId;
      if (!selectedOption.includes("subscription_details")) {
        actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop()!?.length >= 23 ? selectedOption?.split("_").pop() : "";
      } else {
        actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop() !== "details" ? selectedOption?.split("_").pop() : "";
      }
      dispatch(
        companyAction.getAllModuleLogs({
          companyId: id!,
          limit: size,
          searchName: logSearchText,
          startDate: selectedDate.startDate,
          endDate: selectedDate.endDate,
          logSearchFor: selectedOption.includes("_") ? singleLogSearch : selectedOption,
          actionId: actionId,
        })
      ).then(() => {
        if (selectedOption === "info" || selectedOption === "overview") {
          dispatch(companyAction.getCompanyInfoById(id!, selectedDate.startDate, selectedDate.endDate)).then(() => {
            setcompanyLogsLoading(false);
            setchartsLoading(false);
          });
        } else if (selectedOption === "users") {
          dispatch(
            companyAction.getAllUsers({
              companyId: id!,
              page: userPage ? userPage : 1,
              size: userSize ? userSize : 10,
              searchName: userSearchText,
              sortAt: selectedUserHeader.sortAt,
              sortBy: selectedUserHeader.sortBy,
              startDate: selectedDate.startDate,
              endDate: selectedDate.endDate,
            })
          ).then(() => {
            setcompanyLogsLoading(false);
            setchartsLoading(false);
          });
        } else if (selectedOption === "subscription") {
          if (id) {
            dispatch(companyAction.getSubscriptionChartsData(id, selectedDate.startDate, selectedDate.endDate)).then(() => {
              setcompanyLogsLoading(false);
              setchartsLoading(false);
            });
          }
        } else if (selectedOption.includes("subscription_details")) {
          if (id) {
            dispatch(companyAction.getSubscriptionChartsData(id, selectedDate.startDate, selectedDate.endDate)).then(() => {
              setcompanyLogsLoading(false);
              setchartsLoading(false);
            });
          }
        } else {
          setcompanyLogsLoading(false);
          setchartsLoading(false);
        }
      });
    }

    // eslint-disable-next-line
  }, [selectedOption, selectedDate.startDate, selectedDate.endDate]);

  useEffect(() => {
    setSubscriptionChartsData(subscriptionChartsToShow);
    // eslint-disable-next-line
  }, [subscriptionChartsToShow]);

  useEffect(() => {
    let singleLogSearch = selectedOption.split("_").shift();
    singleLogSearch = singleLogSearch?.slice(0, singleLogSearch?.length + 1);
    let actionId;
    if (!selectedOption.includes("subscription_details")) {
      actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop()!?.length >= 23 ? selectedOption?.split("_").pop() : "";
    } else {
      actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop() !== "details" ? selectedOption?.split("_").pop() : "";
    }

    dispatch(
      companyAction.getAllModuleLogs({
        companyId: id!,
        limit: size,
        searchName: logSearchText,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        logSearchFor: selectedOption.includes("_") ? singleLogSearch : selectedOption,
        actionId: actionId,
      })
    ).then(() => {
      setcompanyLogsLoading(false);
      // setcompanyLoading(false);
      // setchartsLoading(false);
    });
    //eslint-disable-next-line
  }, [selectedDate.startDate, selectedDate.endDate, selectedOption]);
  useEffect(() => {
    if (selectedOption === "paymentChannel") {
      if (id) {
        dispatch(
          companyAction.getAllPaymentChannels({
            companyId: id,
            searchName: channelSearchText,
            sortAt: selectedChannelHeader.sortAt,
            sortBy: selectedChannelHeader.sortBy,
            page: channelspage,
            size: channelsSize,
          })
        );
      }
    } else if (selectedOption === "taxes") {
      dispatch(
        companyAction.getAllTaxesById({
          companyId: id!,
          page: taxPage,
          size: taxSize,
          searchName: taxSearchText,
          sortAt: selectedTaxHeader.value,
          sortBy: selectedTaxHeader.sortBy,
        })
      );
    }

    // eslint-disable-next-line
  }, [selectedOption, selectedDate.startDate, selectedDate.endDate]);

  useEffect(() => {
    setallPaymentChannels(paymentChannels);
    // eslint-disable-next-line
  }, [paymentChannels]);

  useEffect(() => {
    if (channelTotalCount > 0 && channelsTotal === 0) {
      setchannelsTotal(channelTotalCount);
      setchannelsSize(channelDataSize);
      setchannelspage(channelDataPage);
    }
    // eslint-disable-next-line
  }, [channelTotalCount]);

  useEffect(() => {
    if (selectedOption === "paymentChannel") {
      dispatch(
        companyAction.getAllPaymentChannels({
          companyId: id!,
          searchName: channelSearchText,
          sortAt: selectedChannelHeader.sortAt,
          sortBy: selectedChannelHeader.sortBy,
          page: channelspage,
          size: channelsSize,
        })
      );
    }

    // eslint-disable-next-line
  }, [channelspage, channelsSize]);

  const handlePaymentChannelSearch = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(
          companyAction.getAllPaymentChannels({
            companyId: id!,
            searchName: searchString,
            sortAt: selectedChannelHeader.sortAt,
            sortBy: selectedChannelHeader.sortBy,
            page: channelspage,
            size: channelsSize,
          })
        );
      }, 200);
    };
  })();

  const resetPaymentChannelHandler = () => {
    setchannelSearchText("");
    dispatch(
      companyAction.getAllPaymentChannels({
        companyId: id!,
        searchName: "",
        sortAt: selectedChannelHeader.sortAt,
        sortBy: selectedChannelHeader.sortBy,
        page: channelspage,
        size: channelsSize,
      })
    );
  };
  useEffect(() => {
    setallPrinters(posPrinters);
    // eslint-disable-next-line
  }, [posPrinters]);

  useEffect(() => {
    setprinterTotal(posPrinterTotal);
    setprinterSize(posPrinterSize);
    setprinterPage(posPrinterPage);
    // eslint-disable-next-line
  }, [posPrinterTotal]);

  useEffect(() => {
    if (selectedOption?.includes("pointOfSales_single")) {
      let posId = selectedOption.split("_").pop();

      if (posId && posId !== "single") {
        dispatch(
          companyAction.getAllPrintersByPosId({
            posId: posId,
            page: printerPage,
            size: printerSize,
            sortAt: selectedPrinterHeader?.sortAt,
            sortBy: selectedPrinterHeader?.sortBy,
            searchName: printerSearchText,
          })
        );
      }
    }

    // eslint-disable-next-line
  }, [printerSize, printerPage]);

  useEffect(() => {
    //do api call here
    dispatch(
      companyAction.getAllTaxesById({
        companyId: id!,
        page: taxPage,
        size: taxSize,
        searchName: taxSearchText,
        sortAt: selectedTaxHeader.value,
        sortBy: selectedTaxHeader.sortBy,
      })
    );
    let sortIcon = document.getElementById("sort-icon");
    if (selectedTaxHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedTaxHeader.sortBy, taxSortAt]);

  const handlePrinterSearch = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        let posId = selectedOption.split("_").pop();
        if (posId && posId !== "single") {
          dispatch(
            companyAction.getAllPrintersByPosId({
              posId: posId,
              page: printerPage,
              size: printerSize,
              sortAt: selectedPrinterHeader?.sortAt,
              sortBy: selectedPrinterHeader?.sortBy,
              searchName: searchString,
            })
          );
        }
      }, 200);
    };
  })();

  const resetPrinterHandler = () => {
    setprinterSearchText("");
    let posId = selectedOption.split("_").pop();
    if (posId && posId !== "single") {
      dispatch(
        companyAction.getAllPrintersByPosId({
          posId: posId,
          page: printerPage,
          size: printerSize,
          sortAt: selectedPrinterHeader?.sortAt,
          sortBy: selectedPrinterHeader?.sortBy,
          searchName: "",
        })
      );
    }
  };
  const resetPosHandler = () => {
    setposSearchText("");
    dispatch(
      companyAction.getAllPos({
        companyId: id!,
        page: storePage ? storePage : 1,
        size: storeSize ? storeSize : 10,
        searchName: storeSearchText,
        sortAt: selectedStoreHeader.value,
        sortBy: selectedStoreHeader.sortBy,
      })
    );
  };
  const resetUserHandler = () => {
    setuserSearchText("");
    dispatch(
      companyAction.getAllUsers({
        companyId: id!,
        page: userPage ? userPage : 1,
        size: userSize ? userSize : 10,
        searchName: "",
        sortAt: selectedUserHeader.sortAt,
        sortBy: selectedUserHeader.sortBy,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
      })
    );
  };
  const resetRolesHandler = () => {
    setroleSearchText("");
    dispatch(
      companyAction.getAllRoles({
        companyId: id!,
        page: rolesPage ? rolesPage : 1,
        size: rolesSize ? rolesSize : 10,
        searchName: "",
        sortAt: selectedRolesHeader.value,
        sortBy: selectedRolesHeader.sortBy,
      })
    );
  };
  const resetSubscriptionHandler = () => {
    setSubscriptionSearchText("");
    dispatch(
      companyAction.getSubscriptionsByCompanyId({
        companyId: id!,
        page: subscriptionPage ? subscriptionPage : 1,
        size: subscriptionSize ? subscriptionSize : 10,
        searchName: "",
        sortAt: selectedSubscriptionHeader.sortAt,
        sortBy: selectedSubscriptionHeader.sortBy,
        startDate: subscriptionSelectedDate.startDate,
        endDate: subscriptionSelectedDate.endDate,
      })
    );
  };
  const resetTaxesHandler = () => {
    setTaxSearchText("");
    dispatch(
      companyAction.getAllTaxesById({
        companyId: id!,
        page: taxPage,
        size: taxSize,
        searchName: "",
        sortAt: selectedTaxHeader.value,
        sortBy: selectedTaxHeader.sortBy,
      })
    );
  };
  const resetInvoiceHandler = () => {
    setinvoiceSearchText("");
    let subscriptionId = selectedOption && selectedOption?.includes("subscription_details") && selectedOption?.split("_")?.pop();
    if (subscriptionId && subscriptionId !== "details") {
      dispatch(
        companyAction.getSubscriptionInvoicesBySubscriptionId({
          subscriptionId: subscriptionId,
          page: invoicePage ? invoicePage : 1,
          size: invoiceSize ? invoiceSize : 10,
          searchName: "",
          sortAt: selectedInvoiceHeader.sortAt,
          sortBy: selectedInvoiceHeader.sortBy,
          startDate: invoiceSelectedDate.startDate,
          endDate: invoiceSelectedDate.endDate,
        })
      );
    }
  };
  const resetStoresHandler = () => {
    setstoreSearchText("");
    dispatch(
      companyAction.getAllStores({
        companyId: id!,
        page: storePage ? storePage : 1,
        size: storeSize ? storeSize : 10,
        searchName: "",
        sortAt: selectedStoreHeader.value,
        sortBy: selectedStoreHeader.sortBy,
      })
    );
  };

  useEffect(() => {
    setallTaxes(allTaxesData);
    // eslint-disable-next-line
  }, [allTaxesData]);

  useEffect(() => {
    if (taxTotalCount > 0 && taxesTotal === 0) {
      setTaxPage(taxDataPage);
      setTaxSize(taxDataSize);
      settaxesTotal(taxTotalCount);
    }
    // eslint-disable-next-line
  }, [taxTotalCount]);

  useEffect(() => {
    if (selectedOption === "taxes") {
      dispatch(
        companyAction.getAllTaxesById({
          companyId: id!,
          page: taxPage,
          size: taxSize,
          searchName: taxSearchText,
          sortAt: selectedTaxHeader.value,
          sortBy: selectedTaxHeader.sortBy,
        })
      );
    }

    // eslint-disable-next-line
  }, [taxPage, taxSize]);

  const handleSearchTax = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(
          companyAction.getAllTaxesById({
            companyId: id!,
            page: taxPage,
            size: taxSize,
            searchName: searchString,
            sortAt: selectedTaxHeader.value,
            sortBy: selectedTaxHeader.sortBy,
          })
        );
      }, 200);
    };
  })();

  useEffect(() => {
    setpaymentCardData(paymentCardForEdit);
    // eslint-disable-next-line
  }, [paymentCardForEdit]);

  useEffect(() => {
    setinvoiceDetails(invoiceForEdit);
    // eslint-disable-next-line
  }, [invoiceForEdit]);

  useEffect(() => {
    if (allModuleLogsData && id) {
      setcompanyLogs(allModuleLogsData);
      settotalLogsCount(totalLogs);
    }
    // eslint-disable-next-line
  }, [allModuleLogsData]);

  const handleSearchLogs = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        let singleLogSearch = selectedOption.split("_").shift();
        singleLogSearch = singleLogSearch?.slice(0, singleLogSearch?.length + 1);
        let actionId;
        if (!selectedOption.includes("subscription_details")) {
          actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop()!?.length >= 23 ? selectedOption?.split("_").pop() : "";
        } else {
          actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop() !== "details" ? selectedOption?.split("_").pop() : "";
        }
        dispatch(
          companyAction.getAllModuleLogs({
            companyId: id!,
            limit: size,
            searchName: searchString,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
            logSearchFor: selectedOption.includes("_") ? singleLogSearch : selectedOption,
            actionId: actionId,
          })
        ).then(() => {
          setcompanyLogsLoading(true);
        });
        if (searchString !== "") {
          setCloseSearchBarIfValue(false);
        } else {
          setCloseSearchBarIfValue(true);
        }
      }, 200);
    };
  })();

  const resetSearchingHandler = () => {
    let singleLogSearch = selectedOption.split("_").shift();
    singleLogSearch = singleLogSearch?.slice(0, singleLogSearch?.length + 1);
    let actionId;
    if (!selectedOption.includes("subscription_details")) {
      actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop()!?.length >= 23 ? selectedOption?.split("_").pop() : "";
    } else {
      actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop() !== "details" ? selectedOption?.split("_").pop() : "";
    }
    dispatch(
      companyAction.getAllModuleLogs({
        companyId: id!,
        limit: size,
        searchName: "",
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        logSearchFor: selectedOption.includes("_") ? singleLogSearch : selectedOption,
        actionId: actionId,
      })
    ).then(() => {
      setcompanyLogsLoading(true);
    });
  };
  const LoadMoreLogs = () => {
    let singleLogSearch = selectedOption.split("_").shift();
    singleLogSearch = singleLogSearch?.slice(0, singleLogSearch?.length + 1);
    let actionId;
    if (!selectedOption.includes("subscription_details")) {
      actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop()!?.length >= 23 ? selectedOption?.split("_").pop() : "";
    } else {
      actionId = selectedOption?.includes("_") && selectedOption?.split("_")?.pop() !== "details" ? selectedOption?.split("_").pop() : "";
    }

    dispatch(
      companyAction.getAllModuleLogs({
        companyId: id!,
        limit: size + 10,
        searchName: logSearchText,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        logSearchFor: selectedOption.includes("_") ? singleLogSearch : selectedOption,
        actionId: actionId,
      })
    ).then(() => {
      setsize(size + 10);
    });
  };

  useEffect(() => {
    setallStores(allStoresData);
    // eslint-disable-next-line
  }, [allStoresData]);

  useEffect(() => {
    setStoresTotal(storesTotalCount);
    setstorePage(allStoresPage);
    setstoreSize(allStoresSize);

    // eslint-disable-next-line
  }, [storesTotalCount]);
  useEffect(() => {
    if (selectedOption === "stores") {
      dispatch(
        companyAction.getAllStores({
          companyId: id!,
          page: storePage ? storePage : 1,
          size: storeSize ? storeSize : 10,
          searchName: storeSearchText,
          sortAt: selectedStoreHeader.value,
          sortBy: selectedStoreHeader.sortBy,
        })
      );
    }
    // eslint-disable-next-line
  }, [storePage, storeSize]);

  const handleSearchStores = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(
          companyAction.getAllStores({
            companyId: id!,
            page: storePage ? storePage : 1,
            size: storeSize ? storeSize : 10,
            searchName: searchString,
            sortAt: selectedStoreHeader.value,
            sortBy: selectedStoreHeader.sortBy,
          })
        );
      }, 200);
    };
  })();

  useEffect(() => {
    setstoreDetails(storeForEdit);
    // eslint-disable-next-line
  }, [storeForEdit]);

  useEffect(() => {
    setallPos(allPosData);
    // eslint-disable-next-line
  }, [allPosData]);
  useEffect(() => {
    setposTotal(posTotalCount);
    setposPage(allPosPage);
    setposSize(allPosSize);

    // eslint-disable-next-line
  }, [posTotalCount]);
  useEffect(() => {
    if (selectedOption === "pointOfSales") {
      dispatch(
        companyAction.getAllPos({
          companyId: id!,
          page: storePage ? storePage : 1,
          size: storeSize ? storeSize : 10,
          searchName: storeSearchText,
          sortAt: selectedStoreHeader.value,
          sortBy: selectedStoreHeader.sortBy,
        })
      );
    }
    // eslint-disable-next-line
  }, [posPage, posSize]);

  const handleSearchPos = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(
          companyAction.getAllPos({
            companyId: id!,
            page: storePage ? storePage : 1,
            size: storeSize ? storeSize : 10,
            searchName: searchString,
            sortAt: selectedStoreHeader.value,
            sortBy: selectedStoreHeader.sortBy,
          })
        );
      }, 200);
    };
  })();

  useEffect(() => {
    setallUsers(alluserData);
    setuserChartsData(allUserChartsData);

    //eslint-disable-next-line
  }, [alluserData]);
  useEffect(() => {
    setuserTotal(userTotalCount);
    setuserPage(alluserPage);
    setuserSize(alluserSize);

    // eslint-disable-next-line
  }, [userTotalCount]);
  useEffect(() => {
    if (selectedOption === "users") {
      dispatch(
        companyAction.getAllUsers({
          companyId: id!,
          page: userPage ? userPage : 1,
          size: userSize ? userSize : 10,
          searchName: userSearchText,
          sortAt: selectedUserHeader.sortAt,
          sortBy: selectedUserHeader.sortBy,
          startDate: selectedDate.startDate,
          endDate: selectedDate.endDate,
        })
      );
    }
    // eslint-disable-next-line
  }, [userPage, userSize]);

  const handleSearchUsers = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(
          companyAction.getAllUsers({
            companyId: id!,
            page: userPage ? userPage : 1,
            size: userSize ? userSize : 10,
            searchName: searchString,
            sortAt: selectedUserHeader.sortAt,
            sortBy: selectedUserHeader.sortBy,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
          })
        );
      }, 200);
    };
  })();

  //roles
  useEffect(() => {
    setallcompanyRoles(allRolesData);
    // eslint-disable-next-line
  }, [allRolesData]);
  
  useEffect(() => {
    setrolesTotal(rolesTotalCount);
    setrolesPage(allRolesPage);
    setrolesSize(allRolesSize);
    // eslint-disable-next-line
  }, [rolesTotalCount]);
  useEffect(() => {
    if (selectedOption === "roles") {
      dispatch(
        companyAction.getAllRoles({
          companyId: id!,
          page: rolesPage ? rolesPage : 1,
          size: rolesSize ? rolesSize : 10,
          searchName: roleSearchText,
          sortAt: selectedRolesHeader.value,
          sortBy: selectedRolesHeader.sortBy,
        })
      );
    }
    // eslint-disable-next-line
  }, [rolesPage, rolesSize]);

  const handleSearchRoles = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(
          companyAction.getAllRoles({
            companyId: id!,
            page: rolesPage ? rolesPage : 1,
            size: rolesSize ? rolesSize : 10,
            searchName: searchString,
            sortAt: selectedRolesHeader.value,
            sortBy: selectedRolesHeader.sortBy,
          })
        );
      }, 200);
    };
  })();

  const { collapsed, setselectedItem } = useSidebar();

  useEffect(() => {
    if (selectedOption === "subscription") {
      if (id) {
        dispatch(companyAction.getPaymentCardByCompanyId(id));
      }
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    setsingleUserData(userForEdit);
    // eslint-disable-next-line
  }, [userForEdit]);

  let img = useRef<any>(null);
  let content: HTMLElement = document.getElementById("invoice-content")!;
  let pdfBase64: any;

  useEffect(() => {
    if (content) {
      pdfHandler(content, img, setAttachmentPath, setScreenshotLoading, pdfBase64);
    }
    //eslint-disable-next-line
  }, [content, screenshotLoading]);

  const handleSendInvoice = () => {
    if (selectedOption.includes("subscription_single") && selectedOption.split("_").pop()!.length > 0 && img && attachmentPath) {
      if (attachmentPath.length > 0) {
        let companyEmail = companyForEdit?.email;
        let invoiceDetails = {
          amountPaid: invoiceForEdit?.paymentMetaData?.amount?.toString() || "0",
          invoiceNumber: invoiceForEdit?.invoiceNumber,
          paymentDate: invoiceForEdit?.created_at,
          pathToAttachment: attachmentPath,
        };
        dispatch(companyAction.sendInvoiceEmailToCompany(companyEmail, invoiceDetails));
      }
    }
    setshowInvoiceSendModal(false);
    setShowSuccessModal(true);
  };

  useEffect(() => {
    if (roleForEdit) {
      setroleForEditDetails(roleForEdit);
    }
    //eslint-disable-next-line
  }, [roleForEdit]);

  const handleArrowUserSortClick = () => {
    setuserSortAt(selectedUserHeader.value!);

    if (selectedUserHeader.sortBy === "asc") {
      setselectedUserHeader({ ...selectedUserHeader, sortBy: "desc" });
    } else {
      setselectedUserHeader({ ...selectedUserHeader, sortBy: "asc" });
    }
  };

  const handleUserSort = (item: any) => {
    setuserSortAt(item.value);
    setselectedUserHeader(item);
    if (selectedUserHeader.value === item.value) {
      if (selectedUserHeader.sortBy === "asc") {
        setselectedUserHeader({ ...selectedUserHeader, sortBy: "desc" });
      } else {
        setselectedUserHeader({ ...selectedUserHeader, sortBy: "asc" });
      }
    }
  };
  useEffect(() => {
    if (selectedOption === "users") {
      dispatch(
        companyAction.getAllUsers({
          companyId: id!,
          page: userPage ? userPage : 1,
          size: userSize ? userSize : 10,
          searchName: userSearchText,
          sortAt: selectedUserHeader.sortAt,
          sortBy: selectedUserHeader.sortBy,
          startDate: selectedDate.startDate,
          endDate: selectedDate.endDate,
        })
      );
    }

    let sortIcon = document.getElementById("sort-icon");
    if (selectedUserHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedUserHeader.sortAt, selectedUserHeader.sortBy]);

  const handleModifyCompanyInfo = () => {
    const dataToModify = {
      country: selectedCountryOption,
      currency: selectedCurrency,
    };
    if (id) {
      dispatch(companyAction.updatedCompanyInfo(id, dataToModify)).then(() => {
        dispatch(companyAction.getCompanyInfoById(id, selectedDate.startDate, selectedDate.endDate));
      });
    }
  };

  useEffect(() => {
    setsubscriptionData(allSubscriptions);
    // eslint-disable-next-line
  }, [allSubscriptions]);
  useEffect(() => {
    setsubscriptionTotal(companySubscriptionTotal);
    setSubscriptionPage(companySubscriptionPage);
    setSubscriptionSize(companySubscriptionSize);

    // eslint-disable-next-line
  }, [companySubscriptionTotal]);
  useEffect(() => {
    if (selectedOption === "subscription") {
      dispatch(
        companyAction.getSubscriptionsByCompanyId({
          companyId: id!,
          page: subscriptionPage ? subscriptionPage : 1,
          size: subscriptionSize ? subscriptionSize : 10,
          searchName: subscriptionSearchText,
          sortAt: selectedSubscriptionHeader.sortAt,
          sortBy: selectedSubscriptionHeader.sortBy,
          startDate: subscriptionSelectedDate.startDate,
          endDate: subscriptionSelectedDate.endDate,
        })
      );
    }
    // eslint-disable-next-line
  }, [subscriptionPage, subscriptionSize, subscriptionSelectedDate.startDate, subscriptionSelectedDate.endDate, selectedOption]);

  const handleSearchSubscription = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(
          companyAction.getSubscriptionsByCompanyId({
            companyId: id!,
            page: subscriptionPage ? subscriptionPage : 1,
            size: subscriptionSize ? subscriptionSize : 10,
            searchName: searchString,
            sortAt: selectedSubscriptionHeader.sortAt,
            sortBy: selectedSubscriptionHeader.sortBy,
            startDate: subscriptionSelectedDate.startDate,
            endDate: subscriptionSelectedDate.endDate,
          })
        );
      }, 200);
    };
  })();
  const handleSortSubscriptionClick = () => {
    if (selectedSubscriptionHeader.sortBy === "asc") {
      setSelectedSubscriptionHeader({ ...selectedSubscriptionHeader, sortBy: "desc" });
    } else {
      setSelectedSubscriptionHeader({ ...selectedSubscriptionHeader, sortBy: "asc" });
    }
  };
  const handleSubscriptionSort = (item: any) => {
    setSelectedSubscriptionHeader(item);
    if (selectedSubscriptionHeader.value === item.value) {
      if (selectedSubscriptionHeader.sortBy === "asc") {
        setSelectedSubscriptionHeader({ ...selectedSubscriptionHeader, sortBy: "desc" });
      } else {
        setSelectedSubscriptionHeader({ ...selectedSubscriptionHeader, sortBy: "asc" });
      }
    }
  };

  useEffect(() => {
    setinvoiceData(allInvoices);
    // eslint-disable-next-line
  }, [allInvoices]);
  useEffect(() => {
    setinvoiceTotal(invoiceDataTotal);
    setinvoicePage(invoiceDataPage);
    setInvoiceSize(invoiceDataSize);
    // eslint-disable-next-line
  }, [invoiceDataTotal]);

  useEffect(() => {
    if (selectedOption.includes("subscription_details")) {
      let subscriptionId = selectedOption.split("_").pop();
      if (subscriptionId && subscriptionId !== "details") {
        dispatch(
          companyAction.getSubscriptionInvoicesBySubscriptionId({
            subscriptionId: subscriptionId,
            page: invoicePage ? invoicePage : 1,
            size: invoiceSize ? invoiceSize : 10,
            searchName: invoiceSearchText,
            sortAt: selectedInvoiceHeader.sortAt,
            sortBy: selectedInvoiceHeader.sortBy,
            startDate: invoiceSelectedDate.startDate,
            endDate: invoiceSelectedDate.endDate,
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [invoicePage, invoiceSize, invoiceSelectedDate.startDate, invoiceSelectedDate.endDate, selectedOption]);

  useEffect(() => {
    if (selectedOption.includes("subscription_details")) {
      let subscriptionId = selectedOption.split("_")?.pop();
      if (subscriptionId && subscriptionId !== "details") {
        let currentSubscription = subscriptionData?.find((item) => item.subscriptionId === subscriptionId);
        if (selectedSubscription) {
          setSelectedSubscription(currentSubscription!);
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  const handleSearchInvoices = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        let subscriptionId = selectedOption.split("_").pop();
        if (subscriptionId && subscriptionId !== "details") {
          dispatch(
            companyAction.getSubscriptionInvoicesBySubscriptionId({
              subscriptionId: subscriptionId,
              page: invoicePage ? invoicePage : 1,
              size: invoiceSize ? invoiceSize : 10,
              searchName: searchString,
              sortAt: selectedInvoiceHeader.sortAt,
              sortBy: selectedInvoiceHeader.sortBy,
              startDate: invoiceSelectedDate.startDate,
              endDate: invoiceSelectedDate.endDate,
            })
          );
        }
      }, 200);
    };
  })();
  const handleSortInvoicesClick = () => {
    if (selectedInvoiceHeader.sortBy === "asc") {
      setselectedInvoiceHeader({ ...selectedInvoiceHeader, sortBy: "desc" });
    } else {
      setselectedInvoiceHeader({ ...selectedInvoiceHeader, sortBy: "asc" });
    }
  };
  const handleInvoicesSort = (item: any) => {
    setselectedInvoiceHeader(item);
    if (selectedInvoiceHeader.value === item.value) {
      if (selectedInvoiceHeader.sortBy === "asc") {
        setselectedInvoiceHeader({ ...selectedInvoiceHeader, sortBy: "desc" });
      } else {
        setselectedInvoiceHeader({ ...selectedInvoiceHeader, sortBy: "asc" });
      }
    }
  };
  useEffect(() => {
    let subscriptionId = selectedOption && selectedOption?.includes("subscription_details") && selectedOption?.split("_")?.pop();
    if (subscriptionId && subscriptionId !== "details") {
      dispatch(
        companyAction.getSubscriptionInvoicesBySubscriptionId({
          subscriptionId: subscriptionId,
          page: invoicePage ? invoicePage : 1,
          size: invoiceSize ? invoiceSize : 10,
          searchName: invoiceSearchText,
          sortAt: selectedInvoiceHeader.sortAt,
          sortBy: selectedInvoiceHeader.sortBy,
          startDate: invoiceSelectedDate.startDate,
          endDate: invoiceSelectedDate.endDate,
        })
      );
    }

    let sortIcon = document.getElementById("sort-icon");
    if (selectedInvoiceHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedInvoiceHeader.sortBy, selectedInvoiceHeader.sortAt]);
  useEffect(() => {
    dispatch(
      companyAction.getSubscriptionsByCompanyId({
        companyId: id!,
        page: subscriptionPage ? subscriptionPage : 1,
        size: subscriptionSize ? subscriptionSize : 10,
        searchName: subscriptionSearchText,
        sortAt: selectedSubscriptionHeader.sortAt,
        sortBy: selectedSubscriptionHeader.sortBy,
        startDate: subscriptionSelectedDate.startDate,
        endDate: subscriptionSelectedDate.endDate,
      })
    );
    let sortIcon = document.getElementById("sort-icon");
    if (selectedSubscriptionHeader.sortBy === "asc") {
      sortIcon?.classList.add("toggle-icon");
    } else sortIcon?.classList.remove("toggle-icon");
    // eslint-disable-next-line
  }, [selectedSubscriptionHeader.sortBy, selectedSubscriptionHeader.sortAt]);
  return (
    <div>
      <div className="company-wrap row m-0 pb-2" style={{ paddingLeft: !collapsed ? "17.5rem" : "3.5rem ", overflow: "hidden" }}>
        <div className="col-lg-3  col-md-12" style={{ height: "100%", position: "relative", bottom: 0 }}>
          <CompanySideInfo
            companyInfo={companyInitialState}
            setcompanyLoading={setcompanyLoading}
            logSearchText={logSearchText}
            selectedDate={selectedDate}
            size={size}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>

        <div className=" col-lg-6 col-md-12 " style={{ height: "100%" }}>
          {selectedOption === "info" && (
            <CompanyInformation
              companyState={companyInitialState}
              handleBack={handleBack}
              handleCancel={handleCancel}
              handleConfirmation={handleConfirmation}
              handleEdit={handleEdit}
              handleSelectedCurrency={handleSelectedCurrency}
              handleSelectedItem={handleSelectedItem}
              isEditCase={isEditCase}
              selectedCurrency={selectedCurrency}
              selectedOption={selectedCountryOption}
              setSelectedOption={setSelectedOption}
              setshowSuccessUpdation={setshowSuccessUpdation}
              showExitModal={showExitModal}
              showSuccessUpdation={showSuccessUpdation}
              selectedModule={selectedOption}
              searchCountryValue={searchCountryValue}
              setSearchCountryValue={setSearchCountryValue}
              searchCurrencyValue={searchCurrencyValue}
              setSearchCurrencyValue={setSearchCurrencyValue}
            />
          )}
          {selectedOption === "overview" && (
            <CompanyDetails
              chartsToShow={companyInitialState.companyChartsToShow}
              xAxisData={companyInitialState.xAxisData}
              chartsLoading={chartsLoading}
            />
          )}
          {/* here invoice data is shown */}
          {selectedOption.includes("subscription_details") && (
            <SubscriptionDetails
              subscriptionData={selectedSubscription}
              setSelectedOption={setSelectedOption}
              chartsLoading={chartsLoading}
              chartsToShow={subscriptionChartsData}
              paymentCardData={paymentCardData}
              handleInvoiceSort={handleInvoicesSort}
              handleSortInvoiceClick={handleSortInvoicesClick}
              invoiceSearchText={invoiceSearchText}
              setInvoiceSearchText={setinvoiceSearchText}
              page={invoicePage}
              selectedInvoiceHeader={selectedInvoiceHeader}
              invoiceHeadData={invoiceHeadData}
              setPage={setinvoicePage}
              setSize={setInvoiceSize}
              size={invoiceSize}
              total={invoiceTotal}
              isOpen={isDateOpen}
              options={dateOptions}
              selectedDate={invoiceSelectedDate}
              selectedValue={invoiceSelectedValue}
              setIsOpen={setIsDateOpen}
              setSelectedDate={setinvoiceSelectedDate}
              setselectedValue={setInvoiceSelectedValue}
              slectedLable={invoiceSelectedLabel}
              setslectedLable={setInvoiceSelectedLabel}
              companyInvoices={invoiceData}
              handleInvoiceSearch={handleSearchInvoices}
              resetHandler={resetInvoiceHandler}
            />
          )}
          {selectedOption === "subscription" && (
            <CompanySubscription
              subscriptionData={subscriptionData}
              setSelectedOption={setSelectedOption}
              chartsLoading={chartsLoading}
              chartsToShow={subscriptionChartsData}
              paymentCardData={paymentCardData}
              handleSubscriptionSearch={handleSearchSubscription}
              handleSubscriptionSort={handleSubscriptionSort}
              handleSortSubscriptionClick={handleSortSubscriptionClick}
              subscriptionSearchText={subscriptionSearchText}
              setSubscriptionSearchText={setSubscriptionSearchText}
              page={subscriptionPage}
              selectedSubscriptionHeader={selectedSubscriptionHeader}
              subscriptionHeadData={subscriptionHeadData}
              setPage={setSubscriptionPage}
              setSize={setSubscriptionSize}
              size={subscriptionSize}
              total={subscriptionTotal}
              isOpen={isSubsDateOpen}
              options={options}
              selectedDate={subscriptionSelectedDate}
              selectedValue={subscriptionSelectedValue}
              setIsOpen={setisSubsDateOpen}
              setSelectedDate={setsubscriptionSelectedDate}
              setselectedValue={setsubscriptionSelectedValue}
              slectedLable={subscriptionSelectedLabel}
              setslectedLable={setsubscriptionSelectedLabel}
              companySubscription={subscriptionTableData}
              resetHandler={resetSubscriptionHandler}
            />
          )}
          {selectedOption.includes("subscription_single") && (
            <InvoiceDetails
              setSelectedOption={setSelectedOption}
              invoiceData={invoiceDetails}
              companyDetails={companyInitialState}
              handleSendInvoice={handleSendInvoice}
              showInvoiceSendModal={showInvoiceSendModal}
              onCancel={() => setshowInvoiceSendModal(false)}
              handleOpenInvoice={() => setshowInvoiceSendModal(true)}
              setShowSuccessModal={setShowSuccessModal}
              showSuccessModal={showSuccessModal}
              subscriptionData={subscriptionData}
            />
          )}
          {selectedOption === "paymentChannel" && (
            <PaymentChannels
              page={channelspage}
              setpage={setchannelspage}
              size={channelsSize}
              setsize={setchannelsSize}
              total={channelsTotal}
              setSelectedOption={setSelectedOption}
              channelsSearchText={channelSearchText}
              setChannelsSearchText={setchannelSearchText}
              paymentChannelHeadData={channelsHeadData}
              selectedPaymentChannelHeader={selectedChannelHeader}
              handlePaymentChannelSearch={handlePaymentChannelSearch}
              handleChannelsSort={handleChannelSort}
              handleSortChannelClick={handleArrowSortChannelClick}
              paymentChannels={allPaymentChannels}
              resetHandler={resetPaymentChannelHandler}
            />
          )}
          {selectedOption === "taxes" && (
            <TaxDetails
              handleSortTaxClick={handleSortTaxClick}
              handleTaxesSearch={handleSearchTax}
              handleTaxesSort={handleTaxSort}
              page={taxPage}
              size={taxSize}
              setpage={setTaxPage}
              setsize={setTaxSize}
              setSelectedOption={setSelectedOption}
              setTaxSearchText={setTaxSearchText}
              taxSearchText={taxSearchText}
              taxes={allTaxes}
              taxesHeadData={taxesHeadData}
              total={taxesTotal}
              selectedTaxHeader={selectedTaxHeader}
              resetHandler={resetTaxesHandler}
            />
          )}
          {selectedOption === "stores" && (
            <StoreDetails
              page={storePage}
              handleSortStoreClick={handleArrowSortStoreClick}
              handleStoreSearch={handleSearchStores}
              handleStoresSort={handleStoresSort}
              selectedStoreHeader={selectedStoreHeader}
              setSelectedOption={setSelectedOption}
              setStoreSearchText={setstoreSearchText}
              setpage={setstorePage}
              setsize={setstoreSize}
              size={storeSize}
              storeSearchText={storeSearchText}
              storesHeadData={storesHeadData}
              stores={allStores}
              total={storesTotal}
              resetHandler={resetStoresHandler}
            />
          )}
          {selectedOption.includes("stores_single") && <SingleStoreDetails setSelectedOption={setSelectedOption} storeForEdit={storeDetails} />}
          {selectedOption === "pointOfSales" && (
            <PosDeviceDetails
              handlePosSearch={handleSearchPos}
              handlePosSort={handlePosSort}
              handleSortPosClick={handleSorPosClick}
              page={posPage}
              setpage={setposPage}
              pos={allPos}
              posHeadData={posHeadData}
              posSearchText={posSearchText}
              setPosSearchText={setposSearchText}
              selectedPosHeader={selectedPosHeader}
              setSelectedOption={setSelectedOption}
              setsize={setposSize}
              size={posSize}
              total={posTotal}
              resetHandler={resetPosHandler}
            />
          )}
          {selectedOption.includes("pointOfSales_single") && (
            <SinglePosDetail
              setSelectedOption={setSelectedOption}
              handlePrinterSearch={handlePrinterSearch}
              handlePrinterSort={handlePrinterSort}
              handleSortPrinterClick={handleSorPrinterClick}
              page={printerPage}
              printerHeadData={printerHeadData}
              printerSearchText={printerSearchText}
              printers={allPrinters}
              selectedPrinterHeader={selectedPrinterHeader}
              setPrinterSearchText={setprinterSearchText}
              setpage={setprinterPage}
              setsize={setprinterSize}
              size={printerSize}
              total={printerTotal}
              posForEdit={posForEdit}
              resetSearchingHandler={resetPrinterHandler}
            />
          )}
          {selectedOption === "users" && (
            <UserDetails
              handleSortUserClick={handleArrowUserSortClick}
              handleUserSearch={handleSearchUsers}
              handleUserSort={handleUserSort}
              page={userPage}
              selectedUserHeader={selectedUserHeader}
              setSelectedOption={setSelectedOption}
              setUserSearchText={setuserSearchText}
              setpage={setuserPage}
              setsize={setuserSize}
              size={userSize}
              total={userTotal}
              user={allUsers}
              userHeadData={userTableHeader}
              userSearchText={userSearchText}
              chartsLoading={chartsLoading}
              chartsToShow={userChartsData}
              resetHandler={resetUserHandler}
            />
          )}
          {selectedOption.includes("users_single") && <SingleUserDetails setSelectedOption={setSelectedOption} userData={singleUserData} />}
          {selectedOption === "roles" && (
            <RolesDetails
              handleRolesSearch={handleSearchRoles}
              handleRolesSort={handleRolesSort}
              handleSortRolesClick={handleSortRolesClick}
              page={rolesPage}
              roles={allcompanyRoles}
              rolesHeadData={rolesTableHeader}
              rolesSearchText={roleSearchText}
              selectedRolesHeader={selectedRolesHeader}
              setRolesSearchText={setroleSearchText}
              setSelectedOption={setSelectedOption}
              setpage={setrolesPage}
              setsize={setrolesSize}
              size={rolesSize}
              total={rolesTotal}
              resetHandler={resetRolesHandler}
            />
          )}
          {selectedOption.includes("roles_single") && <SingleRoleDetails setSelectedOption={setSelectedOption} roleDetails={roleForEditDetails} />}
        </div>
        <div className=" col-lg-3 col-md-12 pb-2" style={{ height: "100%" }}>
          <CompanyLogs
            options={options}
            selectedValue={selectedValue}
            setselectedValue={setselectedValue}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setslectedLable={setslectedLable}
            slectedLable={slectedLable}
            totalCount={totalLogsCount}
            companyLogs={companyLogs[0]?._id ? companyLogs : []}
            searchValue={logSearchText}
            setsearchValue={setlogSearchText}
            handleSearchLogs={handleSearchLogs}
            resetSearchingHandler={resetSearchingHandler}
            closeSearchBarIfValue={closeSearchBarIfValue}
            setCloseSearchBarIfValue={setCloseSearchBarIfValue}
            limit={size}
            LoadMoreLogs={LoadMoreLogs}
            title={refactorLogsTilte(selectedOption)}
            notificationSection={selectedOption === "overview" ? true : false}
            subscription={selectedOption === "subscription" ? true : false}
            seatsData={[]}
            price={0}
            dateSection={selectedOption.includes("subscription_single") ? false : true}
            logSection={selectedOption.includes("subscription_single") ? false : true}
            selectedOption={selectedOption}
            invoiceData={invoiceForEdit}
            isEditCase={isEditCase}
            showAdditionalInfoForLogs={refactorLogsAdditionalInfo(selectedOption)}
            additionalLogsFields={refactorLogsAdditionalFeilds(selectedOption)}
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyOverview;
