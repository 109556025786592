import ReactApexChart from "react-apexcharts";
import { OptionsData } from "./area-chart-interface";
// import { OptionsData } from "./areaChartsInterface";
// import { HeaderDataInterfae } from "../main-page/analytics-main";
// import { i18n } from "../../languageSelector";
// import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";

interface Iprops {
  headData: any;
  dateToShow: boolean;
  analyticsXaxisData: string[];
  totalResult: number;
  color?: any;
}
const ApexChart = ({ totalResult, headData, dateToShow, analyticsXaxisData, color }: Iprops) => {
  // const perDayCalculation = [0, 0, 0, 9, 0, 0, 2, 0, 0, 0, 10, 0];
  const refactorPerdayCalculation = totalResult === 0 ? [] : headData?.perDayCalculation?.map((item: any) => (item === null ? 0 : item));

  const series = [
    {
      name: headData?.title,
      data: refactorPerdayCalculation,
    },
  ];

  const data: OptionsData = {
    chart: {
      type: "area",
      height: "0",
      width: "0",
      toolbar: {
        show: false,
      },

      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      y: {
        formatter: undefined,
        title: {
          // formatter: (seriesName:any) => i18n(`Analytics.${seriesName}`),
        },
      },
    },
    responsive: [
      {
        breakpoint: 900,
        options: {},
      },
    ],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    labels: analyticsXaxisData,

    xaxis: {
      position: "bottom",
      labels: { show: dateToShow ? true : false, rotate: 0 },
      // labels: { show: false },
      min: 1,
      max: analyticsXaxisData?.length,
    },

    yaxis: { labels: { show: dateToShow ? true : false } },
    // yaxis: { labels: { show: false } },
    // labels: dates,

    legend: {
      horizontalAlign: "left",
    },

    colors: [color],
    exporting: {
      enabled: false,
    },
  };

  return (
    <div>
      <ReactApexChart width={"100%"} height={"100%"} options={data} series={series} type="area" />
    </div>
  );
};

export default ApexChart;
