import axios from "axios";

export const AUTH_URL = "admin";

export function login(data: object) {
  return axios.post(`${AUTH_URL}/login`, data);
}
export function changePassword(data: object) {
  return axios.patch(`${AUTH_URL}/change_password`, data);
}

export function whoAmI() {
  return axios.get(`${AUTH_URL}/whoAmI`);
}
export function passwordForgot(data: object) {
  return axios.post(`${AUTH_URL}/forgot_password`, data);
}
export function passwordReset(data: object, token: string) {
  return axios.post(`${AUTH_URL}/reset/${token ? `${token}` : ""}`, data);
}
