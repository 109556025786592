import { createSlice } from "@reduxjs/toolkit";
export interface CompaniesState {
  loading: boolean;
  listLoading: boolean;
  actionsLoading: boolean;
  error: boolean;
  companies: [];
  page: number;
  size: number;
  countries: any[];
  totalCount: number;
  deletedCompanies: [];
  deletedPage: number;
  deletedSize: number;
  deletedTotalCount: number;
  deletedCountries: any[];
  companySideInfoData: any;
  allModuleLogs: any[];
  logsTotal: number;
  allStores: [];
  storesPage: number;
  storeSize: number;
  storeTotalCount: number;
  storeForEdit: any;
  allPos: [];
  posPage: number;
  posSize: number;
  posTotalCount: number;
  allUsers: [];
  userPage: number;
  userSize: number;
  userTotalCount: number;
  userForEdit: any;
  userChartsData: any;
  allRoles: [];
  rolesPage: number;
  roleSize: number;
  rolesTotalCount: number;
  roleForEdit: any;
  companyChartsData: any;
  paymentChannels: any[];
  paymentChannelPage: number;
  paymentChannelSize: number;
  paymentChannelTotal: number;
  allTaxes: any[];
  taxesPage: number;
  taxesSize: number;
  taxesTotal: number;
  companySubscriptionData: any[];
  subscriptionPage: number;
  subscriptionSize: number;
  subscriptionTotal: number;
  subscriptionCharts: any[];
  companyPaymentCardData: any;
  companyFilterCount: number;
  subscriptionInvoices: any[];
  invoicesPage: number;
  invoicesSize: number;
  invoicesTotal: number;
  invoiceForEdit: any;
  deletedFilterCount: number;
  printers: any[];
  printerSize: number;
  printerPage: number;
  printerTotal: number;
}

const initialCompanyState: CompaniesState = {
  actionsLoading: false,
  companies: [],
  countries: [],
  error: false,
  listLoading: false,
  loading: false,
  page: 0,
  size: 0,
  totalCount: 0,
  deletedCompanies: [],
  deletedPage: 0,
  deletedSize: 0,
  deletedTotalCount: 0,
  deletedCountries: [],
  companySideInfoData: {},
  allModuleLogs: [],
  logsTotal: 0,
  allStores: [],
  storeSize: 0,
  storesPage: 0,
  storeTotalCount: 0,
  storeForEdit: {},
  allPos: [],
  posPage: 0,
  posSize: 0,
  posTotalCount: 0,
  allUsers: [],
  userPage: 0,
  userSize: 0,
  userTotalCount: 0,
  userForEdit: {},
  userChartsData: {},
  allRoles: [],
  roleSize: 0,
  rolesPage: 0,
  rolesTotalCount: 0,
  roleForEdit: {},
  companyChartsData: {},
  paymentChannelPage: 0,
  paymentChannels: [],
  paymentChannelSize: 0,
  paymentChannelTotal: 0,
  allTaxes: [],
  taxesPage: 0,
  taxesSize: 0,
  taxesTotal: 0,
  companySubscriptionData: [],
  subscriptionCharts: [],
  companyPaymentCardData: {},
  subscriptionPage: 0,
  subscriptionSize: 0,
  subscriptionTotal: 0,
  companyFilterCount: 0,
  subscriptionInvoices: [],
  invoicesPage: 0,
  invoicesSize: 0,
  invoicesTotal: 0,
  invoiceForEdit: {},
  deletedFilterCount: 0,
  printerPage: 0,
  printers: [],
  printerSize: 0,
  printerTotal: 0,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState: initialCompanyState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.loading = true;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = true;
      }
    },
    getAllComanies: (state, action) => {
      const { companies, page, totalCount, size, filterCount } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.companies = companies;
      state.page = page;
      state.size = size;
      state.totalCount = totalCount;
      state.companyFilterCount = filterCount;
    },
    getAllCountries: (state, action) => {
      const { countries } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.countries = countries;
    },
    getAllDeletedCompanies: (state, action) => {
      const { companies, page, totalCount, size, filterCount } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.deletedCompanies = companies;
      state.deletedPage = page;
      state.deletedSize = size;
      state.deletedTotalCount = totalCount;
      state.deletedFilterCount = filterCount;
    },
    getAllDeletedCountries: (state, action) => {
      const { countries } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.deletedCountries = countries;
    },
    getCompanyInfoById: (state, action) => {
      const { companyInfo, companyChartsData } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.companySideInfoData = companyInfo;
      state.companyChartsData = companyChartsData;
    },
    getAllModuleLogs: (state, action) => {
      const { logs, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.allModuleLogs = logs;
      state.logsTotal = total;
    },
    getAllStores: (state, action) => {
      const { stores, page, size, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.allStores = stores;
      state.storeTotalCount = total;
      state.storesPage = page;
      state.storeSize = size;
    },
    getStoreById: (state, action) => {
      const { storeForEdit } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.storeForEdit = storeForEdit;
    },
    getAllPos: (state, action) => {
      const { pos, page, size, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.allPos = pos;
      state.posTotalCount = total;
      state.posPage = page;
      state.posSize = size;
    },
    getAllUsers: (state, action) => {
      const { users, page, size, total, userChartsData } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.allUsers = users;
      state.userTotalCount = total;
      state.userPage = page;
      state.userSize = size;
      state.userChartsData = userChartsData;
    },
    getUserById: (state, action) => {
      const { user } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.userForEdit = user;
    },
    getAllRoles: (state, action) => {
      const { roles, page, size, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.allRoles = roles;
      state.rolesTotalCount = total;
      state.rolesPage = page;
      state.roleSize = size;
    },
    getRoleById: (state, action) => {
      const { roleForEdit } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.roleForEdit = roleForEdit;
    },
    getAllPaymentChannels: (state, action) => {
      const { paymentChannels, page, size, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.paymentChannels = paymentChannels;
      state.paymentChannelSize = size;
      state.paymentChannelTotal = total;
      state.paymentChannelPage = page;
    },
    getAllTaxesById: (state, action) => {
      const { allTaxes, page, size, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.allTaxes = allTaxes;
      state.taxesSize = size;
      state.taxesTotal = total;
      state.taxesPage = page;
    },
    getSubscriptionChartsData: (state, action) => {
      const { subscriptionData } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.subscriptionCharts = subscriptionData;
    },
    getPaymentCardByCompanyId: (state, action) => {
      const { paymentCard } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.companyPaymentCardData = paymentCard;
    },
    getSubscriptionsByCompanyId: (state, action) => {
      const { subscriptions, page, size, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.companySubscriptionData = subscriptions;
      state.subscriptionPage = page;
      state.subscriptionSize = size;
      state.subscriptionTotal = total;
    },
    getSubscriptionInvoicesBySubscriptionId: (state, action) => {
      const { invoices, page, size, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.subscriptionInvoices = invoices;
      state.invoicesPage = page;
      state.invoicesSize = size;
      state.invoicesTotal = total;
    },
    getSubscriptionInvoiceById: (state, action) => {
      const { invoiceForEdit } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.invoiceForEdit = invoiceForEdit;
    },
    getAllPrintersByPosId: (state, action) => {
      const { printers, page, size, total } = action.payload;
      state.loading = false;
      state.actionsLoading = false;
      state.printers = printers;
      state.printerPage = page;
      state.printerSize = size;
      state.printerTotal = total;
    },
  },
});
