import { useSelector } from "react-redux";
import Table from "../table/Table";
import { StoreState } from "../../../../redux/storeState/storeState";

interface TaxDetailsIProp {
  setSelectedOption: any;
  taxesHeadData: any;
  taxes: any;
  page: number;
  size: number;
  setpage: any;
  setsize: any;
  total: number;
  handleTaxesSearch: any;
  handleSortTaxClick: any;
  handleTaxesSort: any;
  taxSearchText: any;
  selectedTaxHeader: any;
  setTaxSearchText: any;
  resetHandler: any;
}
function TaxDetails({
  setSelectedOption,
  taxes,
  taxesHeadData,
  page,
  setpage,
  setsize,
  size,
  total,
  handleTaxesSearch,
  handleSortTaxClick,
  handleTaxesSort,
  taxSearchText,
  selectedTaxHeader,
  setTaxSearchText,
  resetHandler,
}: TaxDetailsIProp) {
  const refactoredTaxes = taxes?.map((item: any) => {
    return { ...item, scheme: item?.type, rate: item?.taxRate };
  });

  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));
  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; Taxes</div>
        <button
          className="back-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Business ID {companyInfo?.businessId}</div>
      <div>
        <Table
          setSelectedOption={setSelectedOption}
          tableHeadData={taxesHeadData}
          tableBodyData={refactoredTaxes}
          page={Number(page)}
          size={Number(size)}
          setPage={setpage}
          setSize={setsize}
          total={total}
          handleSiteSearch={handleTaxesSearch}
          handleArrowSortClick={handleSortTaxClick}
          handleSort={handleTaxesSort}
          searchText={taxSearchText}
          selectedSiteHeader={selectedTaxHeader}
          setsiteSearchText={setTaxSearchText}
          subscriptionView={false}
          placeholder="Search taxes"
          //  singleDetailPath={`paymentChannel_single`}
          singleDetailPath="none"
          showEmptyScreen={refactoredTaxes?.length > 0 ? false : true}
          resetHandler={resetHandler}
        />
      </div>
    </div>
  );
}

export default TaxDetails;
