import React, { useRef } from "react";
interface ConfirmationModalIProps {
  title?: string;
  btnText: string;
  reason?: string;
  subject?: string;
  message?: string;
  actions?: boolean;
  btnColor: string;
  onCancel: () => void;
  onConfirmation: () => void;
  setshowCConfirmModal?: any;
  tableView?: boolean;
  handleSucccessConfirmation?: any;
  handleReason?: any;
  showResumeOption?: boolean;
  setEmailMessage?: any;
  setEmailSubject?: any;
  companyOverview?: any;
  primaryBtnColor?: string;
  disabled?: boolean;
}

function ConfirmationModal({
  title,
  btnText,
  reason,
  subject,
  message,
  actions,
  btnColor,
  onCancel,
  onConfirmation,
  handleReason,
  showResumeOption,
  setEmailMessage,
  setEmailSubject,
  companyOverview,
  handleSucccessConfirmation,
  primaryBtnColor,
  tableView,
  disabled,
}: ConfirmationModalIProps) {
  const modalRef = useRef<any>(null);
  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleSucccessConfirmation();
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${tableView ? "confirmation-table-modal shadow" : "confirmation-modal shadow"}`}
      style={{ height: reason === "" || reason ? "300px" : subject === "" || subject ? "355px" : "135px" }}
      ref={modalRef}
    >
      <p>{title}</p>
      {reason || reason === "" ? (
        !showResumeOption ? (
          <div className="reason">
            <textarea placeholder="Reason for suspension" value={reason} onChange={handleReason}></textarea>
          </div>
        ) : null
      ) : null}
      {actions ? (
        <div className="actions-wrap">
          <button onClick={onCancel} style={{ color: primaryBtnColor || "#828282" }}>
            Cancel
          </button>
          <button onClick={onConfirmation} disabled={disabled} className="action-btn" style={{ color: btnColor }}>
            {btnText}
          </button>
        </div>
      ) : null}
      {(subject === "" || subject) && (message === "" || message) ? (
        <div className="email-wrap">
          <div className="d-flex justify-content-between align-items-center m-0 p-0 mb-3" style={{ marginTop: "-12px !important" }}>
            <div className="send-email">Send Email</div>
            <div className="send-btn" onClick={onConfirmation}>
              SEND
            </div>
          </div>
          <div style={{ textAlign: "start" }}>
            <label>Subject</label>
            <br />
            <input
              className="subject-input"
              placeholder="Subject"
              value={subject}
              onChange={(e: any) => {
                setEmailSubject(e.target.value);
              }}
            />
            <br />
            <label className="mt-2">Message</label>
            <br />
            <div className="reason">
              <textarea
                placeholder="Message"
                style={{ height: "148px" }}
                onChange={(e: any) => {
                  setEmailMessage(e.target.value);
                }}
              >
                {message}
              </textarea>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ConfirmationModal;
