import { useEffect, useRef } from "react";
import { Collapse, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import NavToggler from "../../assets/images/nav-toggler.png";
import { Link, useNavigate } from "react-router-dom";
import BrandLogo from "../../assets/images/brand-logo.svg";
import notificationIcon from "../../assets/images/notificationIcon.svg";
import subscriptionIcon from "../../assets/images/subscription.svg";
import contentIcon from "../../assets/images/contentIcon.svg";
import companiesIcon from "../../assets/images/companies.svg";
import securityIcon from "../../assets/images/securityIcon.svg";
import integrationIcon from "../../assets/images/integrationIcon.svg";
import supportIcon from "../../assets/images/supportIcon.svg";
import settingIcon from "../../assets/images/setting.svg";
import usersIcon from "../../assets/images/usersIcon.svg";
import analysisIcon from "../../assets/images/analysisIcon.svg";
import { useSidebar } from "../../Pages/useSidebarProvider";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

import * as authActions from "../.././redux/auth/authAction";
import { refactoredSelectedOption } from "../../helper/helperFunctions";
function useOutsideAlerter(ref: any, collapsed?: any, setCollapsed?: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref && ref?.current && !ref?.current?.contains(event.target)) {
        if (!collapsed) {
          setCollapsed(true);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, collapsed, setCollapsed]);
}
function MainHeader() {
  let wrapperRef = useRef<any>();
  //   const dispatch: AppDispatch | any = useDispatch();

  const { collapsed, setCollapsed, selectedItem } = useSidebar();
  // const [collapsed, setCollapsed] = useState(false);
  useOutsideAlerter(wrapperRef, collapsed, setCollapsed);
  const toggleNavbar = () => setCollapsed(!collapsed);
const dispatch:AppDispatch|any=useDispatch()
  //logout
  const navigate = useNavigate();
  const handleLogout = () => {
     dispatch(authActions.logout());
     navigate("/");
  };
  // console.log(selectedItem, "option in header");

  return (
    <>
      <header
        style={{
          zIndex: "1000",
          height: "65px",
        }}
        ref={wrapperRef}
      >
        <div>
          <Navbar color="solid" light className="navbar w-100">
            <NavbarToggler onClick={toggleNavbar} className="d-flex align-items-center ">
              {/* {!collapsed && ( */}
              <NavbarBrand
                href="/"
                className="ml-3 "
                style={{ opacity: collapsed ? "0" : "1", position: collapsed ? "absolute" : "static", top: "-400px" }}
              >
                <img src={BrandLogo} alt="Tamam Logo" className="logo-header-img" />
              </NavbarBrand>
              {/* )} */}

              <span
                style={{ marginBottom: !collapsed ? "8px" : "", position: collapsed ? "absolute" : "static" }}
                className={`${collapsed ? "aside-toggler active" : "aside-toggler"}`}
              >
                <img src={NavToggler} alt="nav-toggler" />
              </span>
            </NavbarToggler>
            <h4 className="selected-option mr-auto " style={{ marginLeft: collapsed ? "5rem" : "1rem", flex: 1 }}>
              {" "}
              {`COMPANIES${refactoredSelectedOption(selectedItem)}`}
            </h4>
            <div style={{ position: "absolute", top: "20px", right: "15px" }}>
              <img src={notificationIcon} alt="notifications" />
              <button onClick={handleLogout} className="logout-btn mx-2">
                Logout
              </button>
            </div>
            <div style={{ width: collapsed ? "66px" : "290px" }}>
              <Collapse isOpen={true} navbar id="myCollapse">
                {!collapsed && (
                  <h4 className="mt-2" style={{ marginLeft: "30px" }}>
                    CONTROL PANEL
                  </h4>
                )}

                <Nav navbar className="mt-4">
                  <NavItem className={window.location.href.indexOf("/dashboard") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={analysisIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "DASHBOARD"}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/companies") || window.location.href.indexOf("/company") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/companies" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={companiesIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "COMPANIES"}{" "}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/subscription") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={subscriptionIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "SUBSCRIPTIONS"}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/content") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={contentIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "CONTENT"}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/analysis") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={analysisIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "ANALYSIS"}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/integration") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={integrationIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "INTEGRATION"}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/security") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={securityIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "SECURITY"}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/support") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={supportIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "SUPPORT"}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/settings") > -1 ? "active" : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={settingIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "SETTINGS"}
                    </Link>
                  </NavItem>
                  <NavItem className={window.location.href.indexOf("/users") > -1 ? "active " : ""}>
                    <Link style={{ textDecoration: "none", color: "inherit" }} to="/dashboard" onClick={() => setCollapsed(true)}>
                      <span className="nav-item-icon" style={{ paddingLeft: collapsed ? "0px" : "15px" }}>
                        <img src={usersIcon} alt="IconCompanies" />
                      </span>
                      {!collapsed && "USERS"}
                    </Link>
                  </NavItem>
                  <div className="d-flex  align-items-center justify-content-start w-100 pt-5 text-start px-4">
                    <span className="avatar-wrap">{/* <img src={avatar} alt="avatar" /> */}</span>
                    {/* <small className="mx-2">
                    <span>
                      Hi, <strong>John</strong>
                      <img src={avatar} alt="avatar"/>
                    </span>
                  </small> */}
                  </div>
                </Nav>
              </Collapse>
            </div>
          </Navbar>
        </div>
      </header>
    </>
  );
}

export default MainHeader;
