import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../assets/images/CloseIcon.svg";
import SearchImage from "../../../assets/images/Search.svg";
import CompaniesHeader from "./CompaniesHeader";
import { CountryListDTO, GenaricListDTO } from "../DTO/GenericListDTO";
import ColumnFilter from "../columnFilter/ColumnFilter";
import FilterForm from "../filterForm/filterForm";
import SuccessMessage from "../../../components/modal/SuccessMessage";
import Table from "../table/Table";
import { useDispatch, useSelector } from "react-redux";
import * as companyActions from "../../../redux/companies/companiesAction";
import CompanyActionModal from "../../../components/modal/companyActionModal";
import * as authActions from "../../../redux/auth/authAction";
import { useSidebar } from "../../useSidebarProvider";
import { options, refactorColoumns, refactorPlans, refactorRegisterValueAndLabel, sortTableHeader } from "../../../helper/helperFunctions";
import { AppDispatch } from "../../../redux/store";
import moment from "moment";
import { StoreState } from "../../../redux/storeState/storeState";

function Companies() {
  const [filterDropdownOpen, setfilterDropdownOpen] = useState(false);
  const [columnDropdownOpen, setColumnDropdownOpen] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [groupActionOpen, setGroupActionOpen] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [deleteModaOpen, setdeleteModaOpen] = useState(false);
  const [suspendModaOpen, setSuspendModaOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [showSuccessDeletion, setshowSuccessDeletion] = useState(false);
  const [showSuccessSuspend, setShowSuccessSuspend] = useState(false);
  const [showSuccessEmail, setShowSuccessEmail] = useState(false);
  const [isDeleteCompanies, setisDeleteCompanies] = useState(false);
  const [showSuccessResume, setShowSuccessResume] = useState(false);
  const [showResumeOption, setshowResumeOption] = useState(false);
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState<string>("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(0);
  const [companyFilterCount, setcompanyFilterCount] = useState(0);
  const [resetLoading, setresetLoading] = useState(false);
  const [countrySearchLoading, setCountrySearchLoading] = useState(false);
  // const [slectedLable, setslectedLable] = useState("Last 30 Days");
  // const [selectedValue, setselectedValue] = useState("Last30Days");
  const { companiesData, companiesPage, companiesTotal, user, companiesSize, countryData, companyFilterTotal } = useSelector((state: StoreState) => ({
    companiesData: state?.companies?.companies,
    companiesTotal: state?.companies?.totalCount,
    companiesPage: state?.companies?.page,
    user: state?.auth?.user,
    companiesSize: state?.companies?.size,
    countryData: state?.companies?.countries,
    companyFilterTotal: state?.companies?.companyFilterCount,
  }));

  let refacoredDates = {
    register: refactorRegisterValueAndLabel(
      user && user?.companiesFilter ? user?.companiesFilter?.registerStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
      user && user?.companiesFilter ? user?.companiesFilter?.registerEndDate : moment().format("YYYY/MM/DD")
    ),
    lastActivity: refactorRegisterValueAndLabel(
      user?.companiesFilter?.lastActivityStartDate
        ? user?.companiesFilter?.lastActivityStartDate
        : moment().subtract(30, "days").format("YYYY/MM/DD"),
      user?.companiesFilter?.lastActivityEndDate ? user?.companiesFilter?.lastActivityEndDate : moment().format("YYYY/MM/DD")
    ),
  };

  const [slectedRegisterLable, setslectedRegisterLable] = useState(refacoredDates?.register?.registerLabel);
  const [selectedRegisterValue, setselectedRegisterValue] = useState(refacoredDates?.register?.registerVal);
  const [slectedLastActivityLable, setslectedLastActivityLable] = useState(refacoredDates?.lastActivity?.registerLabel);
  const [selectedLastActivityValue, setselectedLastActivityValue] = useState(refacoredDates?.lastActivity?.registerVal);
  const [isCompanySuspended, setisCompanySuspended] = useState({ value: false, id: 0 });
  // eslint-disable-next-line
  const [countryLoading, setcountryLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [usersFrom, setusersFrom] = useState("");
  const [usersTo, setusersTo] = useState("");
  //plans that can be deleted: free, trial expired, Monthly Expired,Monthly Paused,Annual Expired, Annual Paused
  const [planList, setPlanList] = useState<any[]>([
    {
      id: "1",
      isSelected: refactorPlans(user, "Free_Free"),
      label: "Free",
      value: "Free_Free",
    },
    {
      id: "2",
      isSelected: refactorPlans(user, "Free_Reverted"),
      label: "Free, Reverted",
      value: "Free_Reverted",
    },
    {
      id: "3",
      isSelected: refactorPlans(user, "Free_Suspended"),
      label: "Free, Suspended",
      value: "Free_Suspended",
    },
    {
      id: "4",
      isSelected: refactorPlans(user, "Trial_Running"),
      label: "Trial",
      value: "Trial_Running",
    },
    {
      id: "5",
      isSelected: refactorPlans(user, "Trial_Expired"),
      label: "Trial, Expired",
      value: "Trial_Expired",
    },
    {
      id: "6",
      isSelected: refactorPlans(user, "Trial_Suspended"),
      label: "Trial, Suspended",
      value: "Trial_Suspended",
    },
    {
      id: "7",
      isSelected: refactorPlans(user, "Monthly_Running"),
      label: "Monthly",
      value: "Monthly_Running",
    },

    {
      id: "8",
      isSelected: refactorPlans(user, "Monthly_Trial"),
      label: "Monthly Trial",
      value: "Monthly_Trial",
    },

    {
      id: "9",
      isSelected: refactorPlans(user, "Monthly_Paused"),
      label: "Monthly, Paused",
      value: "Monthly_Paused",
    },
    {
      id: "10",
      isSelected: refactorPlans(user, "Monthly_Suspended"),
      label: "Monthly, Suspended",
      value: "Monthly_Suspended",
    },

    {
      id: "11",
      isSelected: refactorPlans(user, "Annual_Running"),
      label: "Annual",
      value: "Annual_Running",
    },
    {
      id: "12",
      isSelected: refactorPlans(user, "Annual_Trial"),
      label: "Annual, Trial",
      value: "Annual_Trial",
    },

    {
      id: "13",
      isSelected: refactorPlans(user, "Annual_Paused"),
      label: "Annual, Paused",
      value: "Annual_Paused",
    },
    {
      id: "14",
      isSelected: refactorPlans(user, "Annual_Suspended"),
      label: "Annual, Suspended",
      value: "Annual_Suspended",
    },
  ]);

  const [columnHeaders, setColumnHeaders] = useState<GenaricListDTO[]>([
    {
      id: "1",
      label: " BUSINESS ID",
      value: "businessId",
      isSelected: refactorColoumns(user, "businessId"),
      sortBy: "desc",
    },
    {
      id: "2",
      label: " BUSINESS NAME",
      value: "businessName",
      isSelected: refactorColoumns(user, "businessName"),
      sortBy: "desc",
    },
    {
      id: "3",
      label: "Email",
      value: "email",
      isSelected: refactorColoumns(user, "email"),
      sortBy: "desc",
    },
    {
      id: "4",
      label: "Country",
      value: "country",
      isSelected: refactorColoumns(user, "country"),
      sortBy: "desc",
    },
    {
      id: "5",
      label: "User Management",
      value: "userManagement",
      isSelected: refactorColoumns(user, "userManagement"),
      sortBy: "desc",
    },
    {
      id: "6",
      label: "Register Date",
      value: "registerDate",
      isSelected: refactorColoumns(user, "registerDate"),
      sortBy: "desc",
    },

    {
      id: "7",
      label: "Last Activity",
      value: "lastActivity",
      isSelected: refactorColoumns(user, "lastActivity"),
      sortBy: "desc",
    },
    {
      id: "8",
      label: "Users",
      value: "users",
      isSelected: refactorColoumns(user, "users"),
      sortBy: "desc",
    },
  ]);
  const [companiesList, setcompaniesList] = useState<any[]>([]);
  const [userLoading, setuserLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(true);
  const filterRef = useRef<HTMLDivElement>(null);
  const columnRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const groupActionRef = useRef<HTMLDivElement>(null);
  const [companyLoading, setcompanyLoading] = useState(false);
  const [deleteTextColor, setDeleteTextColor] = useState("#EB5757");
  //eslint-disable-next-line
  const [isfilterBtnColorApplied, setIsfilterBtnColorApplied] = useState(false);
  const [countrySearchText, setcountrySearchText] = useState("");
  const [isColumnFilterBtnColorApplied, setIsColumnFilterBtnColorApplied] = useState(false);
  const [closeSearchBarIfValue, setCloseSearchBarIfValue] = useState(true);
  const [deletedCompanyLoading, setdeletedCompanyLoading] = useState(true);
  const [selectedHeader, setselectedHeader] = useState<GenaricListDTO>(columnHeaders[0]);
  const [feildToSort, setfeildToSort] = useState(user?.companiesFilter?.sortAt || "businessId");
  const [suspendBtnDisabled, setSuspendBtnDisabled] = useState(false);
  const [firstCallLoading, setfirstCallLoading] = useState(true);
  // eslint-disable-next-line
  const [sortBy, setSortBy] = useState("asc");
  // const [selectedDate, setSelectedDate] = useState({
  //   startDate: "",
  //   endDate: "",
  // });
  const [selectedRegisterDate, setselectedRegisterDate] = useState({
    startDate: user && user?.companiesFilter ? user?.companiesFilter?.registerStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
    endDate: user && user?.companiesFilter ? user?.companiesFilter?.registerEndDate : moment().format("YYYY/MM/DD"),
  });
  const [selectedLastActivityDate, setselectedLastActivityDate] = useState({
    startDate: user && user?.companiesFilter ? user?.companiesFilter?.registerStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
    endDate: user && user?.companiesFilter ? user?.companiesFilter?.registerEndDate : moment().format("YYYY/MM/DD"),
  });
  const dispatch: AppDispatch | any = useDispatch();

  //eslint-disable-next-line
  const [countryList, setCountryList] = useState<CountryListDTO[]>([]);
  useEffect(() => {
    if (!resetLoading) {
      let userCountries =
        user &&
        user?.companiesFilter?.countries[0] !== "" &&
        user?.companiesFilter?.countries?.filter((item: any) => item?.isSelected)?.map((item: any) => item?.label);
      let country = countryData?.map((item) => {
        return {
          ...item,
          isSelected: user && user?.companiesFilter && userCountries ? (userCountries?.includes(item?.label) ? true : false) : true,
        };
      });
      setCountryList(country);
    }

    //eslint-disable-next-line
  }, [user, countryLoading]);

  useEffect(() => {
    if (selectedRegisterValue !== "CustomRange" && isOpen) {
      const selectedOption = options.find((item) => item.value === selectedRegisterValue);
      if (selectedOption) {
        setslectedRegisterLable(selectedOption?.lable);
      }
    } else if (selectedRegisterValue === "CustomRange" && isOpen) {
      setslectedRegisterLable(`${selectedRegisterDate.startDate} ~ ${selectedRegisterDate.endDate}`);
    }
    // eslint-disable-next-line
  }, [selectedRegisterValue]);

  useEffect(() => {
    if (selectedLastActivityValue !== "CustomRange" && isOpen2) {
      const selectedLastActivity = options.find((item) => item.value === selectedLastActivityValue);
      if (selectedLastActivity) {
        setslectedLastActivityLable(selectedLastActivity?.lable);
      }
    } else if (selectedLastActivityValue === "CustomRange" && isOpen2) {
      setslectedLastActivityLable(`${selectedLastActivityDate.startDate} ~ ${selectedLastActivityDate.endDate}`);
    }

    // eslint-disable-next-line
  }, [selectedLastActivityValue]);

  useEffect(() => {
    dispatch(authActions.whoAmI());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (firstCallLoading) {
      // let country: any = [];
      let plans: any = [];
      dispatch(companyActions.getAllCountries(""))
        .then(() => {
          if (user && countryData) {
            plans = planList?.map((item: any) => {
              return { ...item, isSelected: user && user?.companiesFilter?.planList[0] !== "" ? refactorPlans(user, item.value) : true };
            });
            setPlanList(plans);
            setusersFrom(user?.companiesFilter?.minUsers! || "");
            setusersTo(user?.companiesFilter?.maxUsers! || "");
            setselectedHeader({
              ...selectedHeader,
              sortBy: user && user?.companiesFilter?.sortBy !== "" ? user?.companiesFilter?.sortBy : "desc",
              value: user && user?.companiesFilter?.sortAt !== "" ? user?.companiesFilter?.sortAt : "businessId",
            });
            setselectedRegisterDate({
              ...selectedRegisterDate,
              startDate:
                user && user?.companiesFilter ? user?.companiesFilter?.registerStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
              endDate: user && user?.companiesFilter ? user?.companiesFilter?.registerEndDate : moment().format("YYYY/MM/DD"),
            });
            setselectedLastActivityDate({
              ...selectedLastActivityDate,
              startDate:
                user && user?.companiesFilter ? user?.companiesFilter?.lastActivityStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
              endDate: user && user?.companiesFilter ? user?.companiesFilter?.lastActivityEndDate : moment().format("YYYY/MM/DD"),
            });
            setselectedRegisterValue(refacoredDates?.register.registerVal);
            setslectedRegisterLable(refacoredDates?.register.registerLabel);
            setselectedLastActivityValue(refacoredDates?.lastActivity.registerVal);
            setslectedLastActivityLable(refacoredDates?.lastActivity.registerLabel);
          }
        })
        .finally(() => {
          setfirstCallLoading(false);
          setuserLoading(false);
          setDateLoading(false);
        });
    }
    //eslint-disable-next-line
  }, [firstCallLoading]);

  useEffect(() => {
    if (!firstCallLoading && user) {
      let countries = user?.companiesFilter?.countries?.filter((item: any) => item.isSelected).map((item: any) => item.label);
      let plans = planList?.filter((item) => item.isSelected).map((item) => item.value);
      if (plans?.length > 0) {
        dispatch(
          companyActions.getAllCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : undefined,
            lastActivityStartDate: moment(user?.companiesFilter?.lastActivityStartDate).format("YYYY/MM/DD"),
            lastActivityEndDate: moment(user?.companiesFilter?.lastActivityEndDate).format("YYYY/MM/DD"),
            registerStartDate: moment(user?.companiesFilter?.registerStartDate).format("YYYY/MM/DD"),
            registerEndDate: moment(user?.companiesFilter?.registerEndDate).format("YYYY/MM/DD"),
            maxUsers: +user?.companiesFilter?.maxUsers,
            minUsers: +user?.companiesFilter?.minUsers,
            planList: plans ? plans : undefined,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
            isSuspended: undefined,
            subscriptionPlanLength: planList?.length
          })
        ).then(() => {
          setselectedRegisterDate({
            ...selectedRegisterDate,
            startDate: user && user?.companiesFilter ? user?.companiesFilter?.registerStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
            endDate: user && user?.companiesFilter ? user?.companiesFilter?.registerEndDate : moment().format("YYYY/MM/DD"),
          });
          setselectedLastActivityDate({
            ...selectedLastActivityDate,
            startDate:
              user && user?.companiesFilter ? user?.companiesFilter?.lastActivityStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
            endDate: user && user?.companiesFilter ? user?.companiesFilter?.lastActivityEndDate : moment().format("YYYY/MM/DD"),
          });
          setselectedRegisterValue(refacoredDates?.register.registerVal);
          setslectedRegisterLable(refacoredDates?.register.registerLabel);
          setselectedLastActivityValue(refacoredDates?.lastActivity.registerVal);
          setslectedLastActivityLable(refacoredDates?.lastActivity.registerLabel);
          setusersFrom(user?.companiesFilter?.minUsers);
          setusersTo(user?.companiesFilter?.maxUsers);
          plans = planList?.map((item: any) => {
            return { ...item, isSelected: user && user?.companiesFilter?.planList[0] !== "" ? refactorPlans(user, item.value) : true };
          });
          setPlanList(plans);
          setDateLoading(false);
          setuserLoading(false);
          setcountryLoading(true);
        });
      }
    }
    //eslint-disable-next-line
  }, [firstCallLoading]);

  useEffect(() => {
    if (user && countryData) {
      let header = columnHeaders?.find((item) => item?.value === user?.companiesFilter?.sortAt);
      if (header) {
        setselectedHeader(header);
      }
      setselectedHeader({
        ...selectedHeader,
        sortBy: user && user?.companiesFilter?.sortBy !== "" ? user?.companiesFilter?.sortBy : "desc",
        value: user && user?.companiesFilter?.sortAt !== "" ? user?.companiesFilter?.sortAt : "businessId",
      });
      setfeildToSort(user?.companiesFilter?.sortAt!);
      let plans = planList?.map((item) => {
        return { ...item, isSelected: user && user?.companiesFilter?.planList[0] !== "" ? refactorPlans(user, item.value) : true };
      });
      setPlanList(plans);

      setusersFrom(user?.companiesFilter?.minUsers!);
      setusersTo(user?.companiesFilter?.maxUsers!);
      // if (!firstCallLoading && user && !countryLoading) {
      setselectedRegisterDate({
        ...selectedRegisterDate,
        startDate: user && user?.companiesFilter ? user?.companiesFilter?.registerStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
        endDate: user && user?.companiesFilter ? user?.companiesFilter?.registerEndDate : moment().format("YYYY/MM/DD"),
      });
      setselectedLastActivityDate({
        ...selectedLastActivityDate,
        startDate: user && user?.companiesFilter ? user?.companiesFilter?.lastActivityStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
        endDate: user && user?.companiesFilter ? user?.companiesFilter?.lastActivityEndDate : moment().format("YYYY/MM/DD"),
      });
      //eslint-disable-next-line
      refacoredDates = {
        register: refactorRegisterValueAndLabel(
          user && user?.companiesFilter ? user?.companiesFilter?.registerStartDate : moment().subtract(30, "days").format("YYYY/MM/DD"),
          user && user?.companiesFilter ? user?.companiesFilter?.registerEndDate : moment().format("YYYY/MM/DD")
        ),
        lastActivity: refactorRegisterValueAndLabel(
          user?.companiesFilter?.lastActivityStartDate
            ? user?.companiesFilter?.lastActivityStartDate
            : moment().subtract(30, "days").format("YYYY/MM/DD"),
          user?.companiesFilter?.lastActivityEndDate ? user?.companiesFilter?.lastActivityEndDate : moment().format("YYYY/MM/DD")
        ),
      };
      setselectedRegisterValue(refacoredDates?.register.registerVal);
      setslectedRegisterLable(refacoredDates?.register.registerLabel);
      setselectedLastActivityValue(refacoredDates?.lastActivity.registerVal);
      setslectedLastActivityLable(refacoredDates?.lastActivity.registerLabel);
    }

    let tableHeaderObject = columnHeaders.map((item, index) => {
      return {
        ...item,
        isSelected: user && user?.columHeaders && user?.columHeaders?.length > 0 ? (user?.columHeaders?.includes(item?.value!) ? true : false) : true,
      };
    });
    setColumnHeaders([...tableHeaderObject]);
    // }

    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const companies = companiesData.map((item: any) => {
      return { ...item, isSelected: false };
    });
    setcompaniesList(companies);
    // eslint-disable-next-line
  }, [companiesData]);

  useEffect(() => {
    setPage(Number(companiesPage));
    setTotal(companiesTotal);
    setSize(Number(companiesSize));
    setcompanyFilterCount(companyFilterTotal);
    // eslint-disable-next-line
  }, [companiesTotal, companyFilterTotal]);

  useEffect(() => {
    setPage(Number(companiesPage));
    setTotal(companiesTotal);
    setSize(Number(companiesSize));
    setcompanyFilterCount(companyFilterTotal);
    // eslint-disable-next-line
  }, [companiesTotal, companyFilterTotal]);

  useEffect(() => {
    if (!firstCallLoading && user && countryList?.length > 0 && !countrySearchLoading) {
      let countries = countryList?.length > 0 && countryList?.filter((item: any) => item?.isSelected)?.map((item: any) => item?.label);
      let plans = planList?.filter((item) => item?.isSelected)?.map((item) => item?.value);

      if (plans?.length > 0 && countries && countries?.length > 0) {
        dispatch(
          companyActions.getAllCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : undefined,
            lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
            lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
            registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
            registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
            maxUsers: +usersTo,
            minUsers: +usersFrom,
            planList: plans ? plans : undefined,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
            isSuspended: undefined,
            subscriptionPlanLength: planList?.length
          })
        );
      }
    }

    // eslint-disable-next-line
  }, [page, size]);

  useEffect(() => {
    if (user && !firstCallLoading) {
      let countries = countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);
      let plans = planList.filter((item) => item.isSelected)?.map((item) => item.value);
      dispatch(companyActions.getAllCountries(countrySearchText)).then(() => {
        if (countries?.length > 0 && plans?.length > 0) {
          dispatch(
            companyActions.getAllCompanies({
              page: page === 0 ? 1 : page,
              size: size === 0 ? 10 : size,
              countries: countries ? countries : undefined,
              lastActivityStartDate: moment(selectedLastActivityDate.startDate).format("YYYY/MM/DD"),
              lastActivityEndDate: moment(selectedLastActivityDate.endDate).format("YYYY/MM/DD"),
              registerStartDate: moment(selectedRegisterDate.startDate).format("YYYY/MM/DD"),
              registerEndDate: moment(selectedRegisterDate.endDate).format("YYYY/MM/DD"),
              maxUsers: +usersTo,
              minUsers: +usersFrom,
              planList: plans ? plans : undefined,
              searchName: searchValue,
              sortAt: selectedHeader.value,
              sortBy: selectedHeader.sortBy,
              isSuspended: undefined,
              subscriptionPlanLength: planList?.length
            })
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [isDeleteCompanies]);

  const handleOutsideClick = (event: any) => {
    if (filterRef.current && !filterRef?.current?.contains(event.target)) {
      setfilterDropdownOpen(false);
    }
  };
  const handleOutsideClickColumn = (event: any) => {
    if (columnRef.current && !columnRef.current.contains(event.target)) {
      setColumnDropdownOpen(false);
    }
  };
  const handleOutsideClickSearch = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      if (!closeSearchBarIfValue) {
        setSearchBarOpen(true);
      } else {
        setSearchBarOpen(false);
      }
    }
  };
  //filter color
  useEffect(() => {
    if (countryList?.length > 0 && countryList?.filter((item: any) => !item.isSelected)?.length > 0) {
      setIsfilterBtnColorApplied(true);
    } else if (planList?.length > 0 && planList?.filter((item) => !item.isSelected)?.length > 0) {
      setIsfilterBtnColorApplied(true);
    } else if (usersFrom?.length > 0 || usersTo.length > 0) {
      setIsfilterBtnColorApplied(true);
    } else if (countrySearchText?.length > 0) {
      setIsfilterBtnColorApplied(true);
    } else {
      setIsfilterBtnColorApplied(false);
    }
    // eslint-disable-next-line
  }, [countryList, planList, usersFrom, usersTo]);
  const handleOutsideClickGroupAction = (event: any) => {
    if (groupActionRef.current && !groupActionRef.current.contains(event.target)) {
      setGroupActionOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickColumn);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickColumn);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickGroupAction);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickGroupAction);
    };
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickSearch);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickSearch);
    };
    // eslint-disable-next-line
  }, [closeSearchBarIfValue]);

  //refactoring countries
  useEffect(() => {
    if (countryLoading && !resetLoading) {
      dispatch(authActions.whoAmI()).then(() => {
        let userCountries =
          user &&
          user?.companiesFilter?.countries[0] !== "" &&
          user?.companiesFilter?.countries?.filter((item: any) => item?.isSelected)?.map((item: any) => item?.label);
        let refactor = countryData?.map((item) => {
          return {
            ...item,
            isSelected: user && user?.companiesFilter && userCountries ? (userCountries?.includes(item?.label) ? true : false) : true,
          };
        });
        setCountryList(refactor);
        setcountryLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [countryData]);
  //country select All
  const handleSelectAllCountry = () => {
    let refactoredArr;
    if (!countrySearchLoading) {
      if (countryList?.filter((item) => !item.isSelected).length > 0) {
        refactoredArr = countryList.map((item) => {
          return { ...item, isSelected: true };
        });
      } else {
        refactoredArr = countryList.map((item) => {
          return { ...item, isSelected: false };
        });
      }
      let countries = refactoredArr?.filter((item) => item.isSelected)?.map((item) => item.label);
      let plans = planList?.filter((item) => item.isSelected)?.map((item) => item.value);
      dispatch(
        companyActions.getAllCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries ? countries : undefined,
          lastActivityStartDate: moment(selectedLastActivityDate.startDate).format("YYYY/MM/DD"),
          lastActivityEndDate: moment(selectedLastActivityDate.endDate).format("YYYY/MM/DD"),
          registerStartDate: moment(selectedRegisterDate.startDate).format("YYYY/MM/DD"),
          registerEndDate: moment(selectedRegisterDate.endDate).format("YYYY/MM/DD"),
          maxUsers: +usersTo,
          minUsers: +usersFrom,
          planList: plans ? plans : undefined,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
          isSuspended: undefined,
          subscriptionPlanLength: planList?.length
        })
      );
      setCountryList([...refactoredArr]);
    } else {
      let userCountries = user?.companiesFilter?.countries[0] !== "" && user?.companiesFilter?.countries;
      let selectedCountry = countryList?.filter((item: any) => item?.label?.toLowerCase()?.includes(countrySearchText?.toLocaleLowerCase()));
      let refactoredArrs: any = [];

      userCountries?.forEach((userCountry: any) => {
        let matchingCountry = selectedCountry?.find((mainItem: any) => mainItem.label === userCountry.label);
        if (matchingCountry) {
          refactoredArrs.push({
            ...userCountry,
            isSelected: !userCountry.isSelected,
          });
        } else {
          refactoredArrs.push(userCountry);
        }
      });
      setCountryList([...refactoredArrs]);

      let countries = refactoredArrs?.filter((item: any) => item.isSelected)?.map((subItem: any) => subItem.label);
      let plans = planList?.filter((item) => item.isSelected)?.map((item) => item.value);
      dispatch(
        companyActions.getAllCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries ? countries : undefined,
          lastActivityStartDate: moment(selectedLastActivityDate.startDate).format("YYYY/MM/DD"),
          lastActivityEndDate: moment(selectedLastActivityDate.endDate).format("YYYY/MM/DD"),
          registerStartDate: moment(selectedRegisterDate.startDate).format("YYYY/MM/DD"),
          registerEndDate: moment(selectedRegisterDate.endDate).format("YYYY/MM/DD"),
          maxUsers: +usersTo,
          minUsers: +usersFrom,
          planList: plans ? plans : undefined,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
          isSuspended: undefined,
          subscriptionPlanLength: planList?.length
        })
      );
      setcountrySearchText("");
    }
  };
  const handleSelectAllPlan = () => {
    let refactoredArr;
    if (planList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = planList.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = planList.map((item) => {
        return { ...item, isSelected: false };
      });
    }

    let countries = refactoredArr?.filter((item) => item.isSelected)?.map((subItem: any) => subItem.label);
    let plans = refactoredArr.filter((item) => item.isSelected)?.map((item) => item.value);
    dispatch(
      companyActions.getAllCompanies({
        page: page === 0 ? 1 : page,
        size: size === 0 ? 10 : size,
        countries: countries?.length > 0 ? countries : undefined,
        lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
        lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
        registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
        registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
        maxUsers: +usersTo,
        minUsers: +usersFrom,
        planList: plans ? plans : undefined,
        searchName: searchValue,
        sortAt: selectedHeader.value,
        sortBy: selectedHeader.sortBy,
        isSuspended: undefined,
        subscriptionPlanLength: planList?.length
      })
    );
    setPlanList([...refactoredArr]);
  };

  //country single select
  const handleSelectCountry = (id: string, label: string) => {
    if (!countrySearchLoading) {
      let refactoredArr = countryList.map((item) => {
        if (item.label === label) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return item;
        }
      });
      setCountryList([...refactoredArr]);
      let countries = refactoredArr?.filter((item) => item.isSelected)?.map((subItem) => subItem.label);
      let plans = planList?.filter((item) => item.isSelected)?.map((item) => item.value);
      dispatch(
        companyActions.getAllCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries.length > 0 ? countries : undefined,
          lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
          lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
          registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
          registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
          maxUsers: +usersTo,
          minUsers: +usersFrom,
          planList: plans ? plans : undefined,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
          isSuspended: undefined,
          subscriptionPlanLength: planList?.length
        })
      );
    } else if (countrySearchLoading) {
      let userCountries = user?.companiesFilter?.countries[0] !== "" && user?.companiesFilter?.countries;
      let refactoredArrs = userCountries.map((item: any) => {
        if (item.label === label) {
          return { ...item, isSelected: !item.isSelected };
        } else {
          return item;
        }
      });
      let countries = refactoredArrs?.filter((item: any) => item.isSelected)?.map((subItem: any) => subItem.label);
      setCountryList([...refactoredArrs]);
      let plans = planList?.filter((item) => item.isSelected)?.map((item) => item.value);

      dispatch(
        companyActions.getAllCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries.length > 0 ? countries : undefined,
          lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
          lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
          registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
          registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
          maxUsers: +usersTo,
          minUsers: +usersFrom,
          planList: plans ? plans : undefined,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
          isSuspended: undefined,
          subscriptionPlanLength: planList?.length
        })
      );
      setcountrySearchText("");
    }
  };

  const handleSelectPlan = (id: string) => {
    let refactoredArr = planList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    let countries = countryList?.filter((item) => item.isSelected)?.map((subItem) => subItem.label);
    let plans = refactoredArr.filter((item) => item.isSelected)?.map((item) => item.value);
    dispatch(
      companyActions.getAllCompanies({
        page: page === 0 ? 1 : page,
        size: size === 0 ? 10 : size,
        countries: countries.length > 0 ? countries : undefined,
        lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
        lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
        registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
        registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
        maxUsers: +usersTo,
        minUsers: +usersFrom,
        planList: plans ? plans : undefined,
        searchName: searchValue,
        sortAt: selectedHeader.value,
        sortBy: selectedHeader.sortBy,
        isSuspended: undefined,
        subscriptionPlanLength: planList?.length
      })
    );
    setPlanList([...refactoredArr]);
  };

  const handleCountrySearch = (() => {
    return (searchString: any) => {
      setTimeout(() => {
        dispatch(companyActions.getAllCountries(searchString)).then((res: any) => {
          setcountryLoading(true);
        });
      }, 200);
    };
  })();

  const handleSelectColumn = (id: string) => {
    let refactoredArr = columnHeaders.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    const filterSelectedHeader = refactoredArr.filter((item) => item.isSelected).map((subItem) => subItem.value);
    companyTableHandler(filterSelectedHeader?.length > 0 ? filterSelectedHeader : [""]);

    setColumnHeaders([...refactoredArr]);
  };
  const handleSelectAllColumns = () => {
    let refactoredArr;
    if (columnHeaders?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = columnHeaders.map((item) => {
        return { ...item, isSelected: true };
      });
    } else {
      refactoredArr = columnHeaders.map((item) => {
        if (item.value === "businessId" || item.value === "businessName") {
          return { ...item, isSelected: true };
        } else return { ...item, isSelected: false };
      });
    }
    const filterSelectedHeader = refactoredArr.filter((item) => item.isSelected).map((subItem) => subItem.value);
    companyTableHandler(filterSelectedHeader?.length > 0 ? filterSelectedHeader : [""]);
    setColumnHeaders([...refactoredArr]);
  };

  const companyTableHandler = (dataToUpdate: any) => {
    dispatch(companyActions.updateAdminUserById({ id: user?._id!, columns: dataToUpdate }));
  };

  const handleReset = () => {
    let plans: any;
    let refactoredCountry: any;
    dispatch(companyActions.getAllCountries("")).then(() => {
      let resetCountryList = countryData?.map((item) => {
        return { ...item, isSelected: true };
      });
      setCountryList(resetCountryList);
      let resetPlans = planList?.map((item) => {
        return { ...item, isSelected: true };
      });
      setPlanList(resetPlans);
      plans = resetPlans?.map((item) => item?.value);
      refactoredCountry = resetCountryList?.map((item) => item.label);
      setusersFrom("");
      setusersTo("");
      setselectedLastActivityDate({
        ...selectedLastActivityDate,
        startDate: moment().subtract(30, "days").format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      });
      setselectedRegisterDate({
        ...selectedRegisterDate,
        startDate: moment().subtract(30, "days").format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      });
      setselectedRegisterValue("Last30Days");
      setselectedLastActivityValue("Last30Days");
      setslectedRegisterLable("Last 30 Days");
      setslectedLastActivityLable("Last 30 Days");
      setcountrySearchText("");
      setresetLoading(true);

      dispatch(
        companyActions.getAllCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: refactoredCountry,
          lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
          lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
          registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
          registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
          maxUsers: +usersTo,
          minUsers: +usersFrom,
          planList: plans ? plans : undefined,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
          isSuspended: undefined,
          subscriptionPlanLength: planList?.length
        })
      );
    });
    setTimeout(() => {
      setresetLoading(false);
    }, 800);
  };

  useEffect(() => {
    if (resetLoading) {
      dispatch(companyActions.getAllCountries("")).then(() => {
        let refactoredCountry = countryData?.map((item) => item.label);
        let allPlans = planList?.map((item) => {
          return { ...item, isSelected: true };
        });
        let plans = allPlans?.map((item) => item.value);
        setPlanList(allPlans);
        setCountryList(countryData);
        setusersFrom("");
        setusersTo("");
        setselectedLastActivityDate({
          ...selectedLastActivityDate,
          startDate: moment().subtract(30, "days").format("YYYY/MM/DD"),
          endDate: moment().format("YYYY/MM/DD"),
        });
        setselectedRegisterDate({
          ...selectedRegisterDate,
          startDate: moment().subtract(30, "days").format("YYYY/MM/DD"),
          endDate: moment().format("YYYY/MM/DD"),
        });
        setselectedRegisterValue("Last30Days");
        setselectedLastActivityValue("Last30Days");
        setslectedRegisterLable("Last 30 Days");
        setslectedLastActivityLable("Last 30 Days");
        setcountrySearchText("");
        dispatch(
          companyActions.getAllCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: refactoredCountry,
            lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
            lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
            registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
            registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
            maxUsers: +usersTo,
            minUsers: +usersFrom,
            planList: plans ? plans : undefined,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
            isSuspended: undefined,
            subscriptionPlanLength: planList?.length
          })
        );
      });
    }
    //eslint-disable-next-line
  }, [resetLoading]);

  const resetSearchingHandler = () => {
    let plans = planList.filter((item) => item.isSelected)?.map((item) => item.value);
    let countries = countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);

    dispatch(
      companyActions.getAllCompanies({
        page: page === 0 ? 1 : page,
        size: size === 0 ? 10 : size,
        countries: countries ? countries : [],
        lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
        lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
        registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
        registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
        maxUsers: +usersTo,
        minUsers: +usersFrom,
        planList: plans ? plans : undefined,
        searchName: "",
        sortAt: selectedHeader.value,
        sortBy: selectedHeader.sortBy,
        isSuspended: undefined,
        subscriptionPlanLength: planList?.length
      })
    );
  };

  const handleSelectTableCheckbox = (id: string) => {
    let refactoredArr = companiesList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setcompaniesList([...refactoredArr]);
  };

  const handleSelectAllTableCheckbox = () => {
    let refactoredArr: any;
    if (companiesList && companiesList?.filter((item) => !item.isSelected).length > 0) {
      refactoredArr = companiesList.map((item) => {
        return { ...item, isSelected: true };
      });
      setcompaniesList([...refactoredArr!]);
    } else {
      if (companiesList) {
        refactoredArr =
          companiesList &&
          companiesList?.map((item) => {
            return { ...item, isSelected: false };
          });
        setcompaniesList([...refactoredArr!]);
      }
    }
  };

  useEffect(() => {
    if (!userLoading) {
      let plans = planList.filter((item) => item.isSelected)?.map((item) => item.value);
      let countries = countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);

      if (plans && plans?.length > 0 && countries?.length > 0) {
        dispatch(
          companyActions.getAllCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries,
            lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
            lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
            registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
            registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
            maxUsers: +usersTo,
            minUsers: +usersFrom,
            planList: plans ? plans : undefined,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
            isSuspended: undefined,
            subscriptionPlanLength: planList?.length
          })
        );
      }
    }
    //eslint-disable-next-line
  }, [usersFrom, usersTo]);

  useEffect(() => {
    if (!dateLoading && !resetLoading && !countrySearchLoading) {
      let plans = planList?.filter((item) => item?.isSelected).map((item) => item?.value);
      let countries = countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);
      if (plans && plans?.length > 0 && countries?.length > 0) {
        dispatch(
          companyActions.getAllCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries,
            lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
            lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
            registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
            registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
            maxUsers: +usersTo,
            minUsers: +usersFrom,
            planList: plans ? plans : undefined,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
            isSuspended: undefined,
            subscriptionPlanLength: planList?.length
          })
        );
      }
    }
    //eslint-disable-next-line
  }, [selectedLastActivityDate, selectedRegisterDate]);

  const getSelectedFilters = () => {
    let countries = countryList?.filter((item: any) => item.isSelected).map((item: any) => item.label);
    let plans = planList.filter((item) => item.isSelected).map((item) => item.value);
    let lastActivityStartDate = moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD");
    let lastActivityEndDate = moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD");
    let registerStartDate = moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD");
    let registerEndDate = moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD");
    let sortAt = selectedHeader.value;
    let sortBy = selectedHeader.sortBy;
    let pageNo = page === 0 ? 1 : page;
    let limit = size === 0 ? 1 : size;
    return {
      countries,
      plans: plans ? plans : undefined,
      usersFrom,
      usersTo,
      lastActivityEndDate,
      lastActivityStartDate,
      registerStartDate,
      registerEndDate,
      searchValue,
      sortAt,
      sortBy,
      pageNo,
      limit,
      subscriptionPlanLength: planList?.length
      
    };
  };

  const handleCancel = () => {
    if (suspendModaOpen) {
      setSuspendModaOpen(false);
      setMessage("");
    } else if (deleteModaOpen) {
      setdeleteModaOpen(false);
    } else if (emailModalOpen) {
      setEmailModalOpen(false);
    } else if (showResumeOption) {
      setshowResumeOption(false);
      setMessage("");
    }
  };

  //suspend selected companies
  const suspendCompanies = () => {
    let selectedCompanyIdsForSuspension = companiesList?.filter((item: any) => item.isSelected === true).map((item) => item.id);
    dispatch(companyActions.suspendSelectedCompanies(selectedCompanyIdsForSuspension, reason, true)).then(() => {
      setReason("");
      let plans = planList.filter((item) => item.isSelected)?.map((item) => item.value);
      let countries = countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);
      dispatch(
        companyActions.getAllCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries ? countries : [],
          lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
          lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
          registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
          registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
          maxUsers: +usersTo,
          minUsers: +usersFrom,
          planList: plans ? plans : undefined,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
          isSuspended: undefined,
          subscriptionPlanLength: planList?.length
        })
      );
    });
    setSuspendModaOpen(false);
    setMessage("");
    setShowSuccessSuspend(true);
    setGroupActionOpen(false);
  };
  //resume selected companies
  const resumeCompanies = () => {
    setcompanyLoading(true);
    let selectedCompanyIdsForResume = companiesList?.filter((item: any) => item.isSelected === true).map((item) => item.id);
    dispatch(companyActions.suspendSelectedCompanies(selectedCompanyIdsForResume, reason, false)).then(() => {
      setReason("");
      let plans = planList.filter((item) => item.isSelected)?.map((item) => item.value);
      let countries = countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);
      dispatch(
        companyActions.getAllCompanies({
          page: page === 0 ? 1 : page,
          size: size === 0 ? 10 : size,
          countries: countries ? countries : [],
          lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
          lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
          registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
          registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
          maxUsers: +usersTo,
          minUsers: +usersFrom,
          planList: plans ? plans : undefined,
          searchName: searchValue,
          sortAt: selectedHeader.value,
          sortBy: selectedHeader.sortBy,
          isSuspended: undefined,
          subscriptionPlanLength: planList?.length
        })
      );
    });
    setshowResumeOption(false);
    setMessage("");
    setShowSuccessResume(true);
    setGroupActionOpen(false);
  };

  // delete selected companies
  const deleteCompanies = () => {
    let selectedCompanyIdsForDeletion = companiesList?.filter((item: any) => item.isSelected === true).map((item) => item.id);
    dispatch(companyActions.deleteSelectedCompanies(selectedCompanyIdsForDeletion))
      .then(() => {
        setReason("");
        dispatch(authActions.whoAmI());
      })
      .then(() => {
        let plans = planList.filter((item) => item.isSelected)?.map((item) => item.value);
        let countries = countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);
        dispatch(
          companyActions.getAllCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : [],
            lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
            lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
            registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
            registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
            maxUsers: +usersTo,
            minUsers: +usersFrom,
            planList: plans ? plans : undefined,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
            isSuspended: undefined,
            subscriptionPlanLength: planList?.length
          })
        ).then(() => {
          dispatch(companyActions.getAllCountries("")).then(() => setcountryLoading(true));
        });
      });

    setdeleteModaOpen(false);
    setshowSuccessDeletion(true);
    setGroupActionOpen(false);
  };

  //send email to companies
  const sendEmailToCompanie = () => {
    let selectedCompaniesToSendEmail = companiesList?.filter((item: any) => item.isSelected === true).map((item: any) => item.email);
    if (selectedCompaniesToSendEmail?.length > 0) {
      dispatch(companyActions.sendEmailToCompany(selectedCompaniesToSendEmail, { message: emailMessage, subject: emailSubject })).then(() => {
        setEmailSubject("");
        setEmailMessage("");
      });
    }

    setEmailModalOpen(false);
    setShowSuccessEmail(true);
    setGroupActionOpen(false);
  };
  const handleConfirmation = () => {
    if (suspendModaOpen) {
      suspendCompanies();
    } else if (showResumeOption) {
      resumeCompanies();
    } else if (deleteModaOpen) {
      deleteCompanies();
    } else if (emailModalOpen) {
      sendEmailToCompanie();
    }
  };

  const handleSearchCompany = (() => {
    let timeoutId: NodeJS.Timeout | undefined;
    return (searchString: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        let countries = countryList?.length > 0 && countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);
        let plans = planList.filter((item) => item.isSelected)?.map((item) => item.value);
        dispatch(
          companyActions.getAllCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : undefined,
            lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
            lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
            registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
            registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
            maxUsers: +usersTo,
            minUsers: +usersFrom,
            planList: plans ? plans : undefined,
            searchName: searchString,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
            isSuspended: undefined,
            subscriptionPlanLength: planList?.length
          })
        );
        if (searchString !== "") {
          setCloseSearchBarIfValue(false);
        } else {
          setCloseSearchBarIfValue(true);
        }
      }, 200);
    };
  })();
  const handleSort = (item: any) => {
    sortTableHeader(item, setfeildToSort);
    setselectedHeader(item);
    if (selectedHeader.value === item.value) {
      if (selectedHeader.sortBy === "asc") {
        setselectedHeader({ ...selectedHeader, sortBy: "desc" });
      } else {
        setselectedHeader({ ...selectedHeader, sortBy: "asc" });
      }
    }
  };

  //sorting

  useEffect(() => {
    if (countryList?.length > 0 && user && !firstCallLoading) {
      let countries = countryList?.filter((item: any) => item.isSelected)?.map((item: any) => item.label);
      let plans = planList.filter((item) => item.isSelected)?.map((item) => item.value);
      if (plans?.length > 0 && countries?.length > 0) {
        dispatch(
          companyActions.getAllCompanies({
            page: page === 0 ? 1 : page,
            size: size === 0 ? 10 : size,
            countries: countries ? countries : undefined,
            lastActivityStartDate: moment(selectedLastActivityDate?.startDate).format("YYYY/MM/DD"),
            lastActivityEndDate: moment(selectedLastActivityDate?.endDate).format("YYYY/MM/DD"),
            registerStartDate: moment(selectedRegisterDate?.startDate).format("YYYY/MM/DD"),
            registerEndDate: moment(selectedRegisterDate?.endDate).format("YYYY/MM/DD"),
            maxUsers: +usersTo,
            minUsers: +usersFrom,
            planList: plans ? plans : undefined,
            searchName: searchValue,
            sortAt: selectedHeader.value,
            sortBy: selectedHeader.sortBy,
            isSuspended: undefined,
            subscriptionPlanLength: planList?.length
          })
        );
      }

      let sortIcon = document.getElementById("sort-icon");
      if (selectedHeader.sortBy === "asc") {
        sortIcon?.classList.add("toggle-icon");
      } else sortIcon?.classList.remove("toggle-icon");
    }

    // eslint-disable-next-line
  }, [selectedHeader?.sortBy, selectedHeader?.value]);

  const handleOpenResumeModal = () => {
    let finded = companiesList?.filter((item: any) => item.isSelected).map((item) => item.isSuspended);
    if (finded) {
      setisCompanySuspended({ value: true, id: 0 });
      setshowResumeOption(true);
    }
  };
  //suspend reason
  const handleReason = (event: any) => {
    setReason(event.target.value);
  };

  useEffect(() => {
    if (companiesList?.length > 0) {
      let finded = companiesList?.filter((item: any) => item.isSelected).filter((item) => !item.isSuspended);
      if (finded.length > 0) {
        setisCompanySuspended({ ...isCompanySuspended, id: 0, value: false });
      } else {
        setisCompanySuspended({ value: true, id: 0 });
      }
    }
    // eslint-disable-next-line
  }, [companiesList, companyLoading]);

  useEffect(() => {
    //selected company is able to delete if its on free plan, expired trial or paused subscription
    if (companiesList?.length > 0) {
      let status = companiesList.filter(
        (item: any) =>
          item?.isSelected &&
          item?.subscription?.currentPlan?.status !== "Paused" &&
          (item?.subscription?.currentPlan?.status !== "Free" ||
            (item?.subscription?.currentPlan?.plan !== "Free" && item?.subscription?.currentPlan?.status !== "Running")) &&
          !item?.subscription?.userTrial?.isTrialCancelled &&
          item?.subscription?.currentPlan?.status !== "Expired"
      );
      if (status?.length > 0) {
        setDeleteTextColor("#828282");
      } else {
        setDeleteTextColor("#EB5757");
      }
    }
    // eslint-disable-next-line
  }, [groupActionOpen, planList]);

  useEffect(() => {
    let selectedColumns = columnHeaders.filter((item) => !item.isSelected)?.length > 0;
    if (selectedColumns) {
      setIsColumnFilterBtnColorApplied(true);
    } else {
      setIsColumnFilterBtnColorApplied(false);
    }
  }, [columnHeaders]);

  const handleArrowSortClick = () => {
    if (selectedHeader.sortBy === "asc") {
      setselectedHeader({ ...selectedHeader, sortBy: "desc" });
    } else {
      setselectedHeader({ ...selectedHeader, sortBy: "asc" });
    }
  };
  const openSuspendModalHandler = () => {
    let allCompanies = companiesList.filter((item) => item.isSelected && item.isSuspended);
    if (allCompanies?.length > 0) {
      setSuspendModaOpen(false);
    } else {
      setSuspendModaOpen(true);
    }
  };
  useEffect(() => {
    let allCompanies = companiesList.filter((item) => item.isSelected && item.isSuspended);
    if (allCompanies?.length > 0) {
      setSuspendBtnDisabled(false);
    } else {
      setSuspendBtnDisabled(true);
    }
    // eslint-disable-next-line
  }, [groupActionOpen]);

  useEffect(() => {
    if (countrySearchText?.length > 0) {
      setCountrySearchLoading(true);
    } else
      setTimeout(() => {
        setCountrySearchLoading(false);
      }, 800);
  }, [countrySearchText]);

  const { collapsed, setselectedItem } = useSidebar();
  setselectedItem("");
  return (
    <div className="pb-2" style={{ overflowX: "hidden", background: "rgb(242, 243, 248)" }}>
      <div>
        <CompaniesHeader isDeleteCompanies={isDeleteCompanies} setisDeleteCompanies={setisDeleteCompanies} />
        {!isDeleteCompanies ? (
          <div className="companies-main-wrap" style={{ marginLeft: collapsed ? "4.5rem" : "18.7rem" }}>
            <div className="d-flex justify-content-between align-items-center" style={{ gap: "25px" }}>
              <div className="filters">
                <div
                  className={
                    companiesList?.length && companiesList.filter((e) => e.isSelected).length > 0 ? "normal filter-div " : "disabled filter-div "
                  }
                  style={{ whiteSpace: "nowrap", position: "relative" }}
                  ref={groupActionRef}
                >
                  <span
                    onClick={() => {
                      setGroupActionOpen(!groupActionOpen);
                      setDateLoading(true);
                    }}
                  >
                    Action
                  </span>
                  {groupActionOpen && companiesList?.filter((e) => e.isSelected).length > 0 ? (
                    <CompanyActionModal
                      suspendModalOpen={suspendModaOpen}
                      emailModalOpen={emailModalOpen}
                      deleteModaOpen={deleteModaOpen}
                      showResumeOption={showResumeOption}
                      openDeleteModalHandler={() => setdeleteModaOpen(true)}
                      openEmailModalHandler={() => setEmailModalOpen(true)}
                      openSuspendModalHandler={openSuspendModalHandler}
                      openResumeHandler={handleOpenResumeModal}
                      reason={reason}
                      // setReason={setReason}
                      handleReason={handleReason}
                      message={message}
                      setMessage={setMessage}
                      setEmailMessage={setEmailMessage}
                      setEmailSubject={setEmailSubject}
                      emailMessage={emailMessage}
                      emailSubject={emailSubject}
                      onCancel={handleCancel}
                      onConfirmation={handleConfirmation}
                      isCompanySuspended={isCompanySuspended.value}
                      textDeleteColor={deleteTextColor}
                      style={{ top: "-10px" }}
                      disabled={suspendBtnDisabled}
                    />
                  ) : null}
                  {showSuccessDeletion ? (
                    <SuccessMessage setshowSuccessCompletion={setshowSuccessDeletion} message="Company deleted successfully " />
                  ) : (
                    ""
                  )}
                  {showSuccessSuspend ? (
                    <SuccessMessage setshowSuccessCompletion={setShowSuccessSuspend} message="Company suspended successfully " />
                  ) : null}
                  {showSuccessEmail ? (
                    <SuccessMessage setshowSuccessCompletion={setShowSuccessEmail} message="The email was successfully sent" />
                  ) : null}
                  {showSuccessResume ? (
                    <SuccessMessage setshowSuccessCompletion={setShowSuccessResume} message="Company resumed successfully" />
                  ) : null}
                </div>
                <div className={`filter-wrap ${isfilterBtnColorApplied ? "active" : "normal "}`} ref={filterRef} style={{ position: "relative" }}>
                  <span
                    onClick={() => {
                      setfilterDropdownOpen(!filterDropdownOpen);
                    }}
                  >
                    Filter
                  </span>
                  {filterDropdownOpen ? (
                    <FilterForm
                      countryList={countryList}
                      countrySearchText={countrySearchText}
                      planList={planList}
                      setCountryList={setCountryList}
                      setPlanList={setPlanList}
                      handleSelectAllCountry={handleSelectAllCountry}
                      handleSelectAllPlan={handleSelectAllPlan}
                      handleSelectCountry={handleSelectCountry}
                      handleSelectPlan={handleSelectPlan}
                      handleReset={handleReset}
                      handleCountrySearch={handleCountrySearch}
                      options={options}
                      selectedValue={selectedRegisterValue}
                      setselectedValue={setselectedRegisterValue}
                      slectedLable={slectedRegisterLable}
                      setslectedLable={setslectedRegisterLable}
                      setSelectedDate={setselectedRegisterDate}
                      selectedDate={selectedRegisterDate}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      isOpen2={isOpen2}
                      setIsOpen2={setIsOpen2}
                      usersFrom={usersFrom}
                      usersTo={usersTo}
                      handlerUserTo={(e: any) => setusersTo(e.target.value)}
                      handleUserFrom={(e: any) => setusersFrom(e.target.value)}
                      selectedLastActivityValue={selectedLastActivityValue}
                      setselectedLastActivityValue={setselectedLastActivityValue}
                      slectedLastActivityLable={slectedLastActivityLable}
                      setslectedLastActivityLable={setslectedLastActivityLable}
                      setSelectedLastActivityDate={setselectedLastActivityDate}
                      selectedLastActivityDate={selectedLastActivityDate}
                      setCountrySearchText={setcountrySearchText}
                    />
                  ) : null}
                </div>
                <div className={`filter-div ${isColumnFilterBtnColorApplied ? "active" : "normal"}`} ref={columnRef} style={{ position: "relative" }}>
                  <span
                    onClick={() => {
                      setColumnDropdownOpen(!columnDropdownOpen);
                    }}
                  >
                    Columns
                  </span>
                  {columnDropdownOpen ? (
                    <ColumnFilter
                      columnHeaders={columnHeaders}
                      setColumnHeaders={setColumnHeaders}
                      isHeaderSelected={columnDropdownOpen}
                      handleSelectAllColumns={handleSelectAllColumns}
                      handleSelectColumn={handleSelectColumn}
                    />
                  ) : null}
                </div>
              </div>
              <div className="searchIcon-wrap" ref={searchRef} style={{ position: "relative", width: searchBarOpen ? "100%" : "inherit" }}>
                <div style={{ width: "100%", height: "34px" }}>
                  {searchBarOpen ? (
                    <input
                      value={searchValue}
                      onChange={(e: any) => {
                        setsearchValue(e.target.value);
                        handleSearchCompany(e.target.value);
                      }}
                      type="text"
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        outline: "none",
                        background: "#F2F2F2",
                        borderRadius: "6px",
                        padding: "1rem",
                        color: searchValue.length ? "#4F4F4F" : "#BDBDBD",
                        fontWeight: !searchValue.length ? 500 : 700,
                      }}
                      className="searchBar"
                      placeholder="Search"
                    />
                  ) : null}

                  <div style={{ position: "absolute", top: 3, right: 20 }}>
                    {searchValue.length && searchBarOpen ? (
                      <img
                        src={closeIcon}
                        alt="search icon"
                        onClick={() => {
                          setsearchValue("");
                          resetSearchingHandler();
                          setCloseSearchBarIfValue(true);
                        }}
                      />
                    ) : (
                      <img
                        src={SearchImage}
                        alt="search icon"
                        onClick={() => {
                          setSearchBarOpen(!searchBarOpen);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Table
                tableHeaderState={columnHeaders}
                companiesList={companiesList}
                handleSelectTableCheckbox={handleSelectTableCheckbox}
                handleSelectAllTableCheckbox={handleSelectAllTableCheckbox}
                page={+page}
                setSize={setSize}
                setPage={setPage}
                size={+size}
                total={+total}
                setTotal={setTotal}
                handleSort={handleSort}
                companySuspend={isCompanySuspended}
                setCompanyLoading={setcompanyLoading}
                countryList={countryList}
                planList={planList}
                setDeleteCompanyLoading={setdeletedCompanyLoading}
                deleteCompanyLoading={deletedCompanyLoading}
                sortField={feildToSort}
                sortBy={selectedHeader?.sortBy}
                searchValue={searchValue}
                getSelectedFilters={getSelectedFilters}
                setSortField={setfeildToSort}
                setSortBy={setSortBy}
                handleArrowSortClick={handleArrowSortClick}
                selectedHeader={selectedHeader}
                filterCount={companyFilterCount}
                setcountryLoading={setcountryLoading}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Companies;
