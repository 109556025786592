import React, { useRef, useState } from "react";
// import searchIcon from "../../../src/assets/images/charm_search.svg";
// import dropdownIcon from "../../assets/images/dropdownIcon.svg";
// import { i18n } from "../../pages/private/languageSelector";

const CustomMultiSelect = ({
  options,
  onSelect,
  handleSelectAll,
  handleSelectedItem,
  handleSearch,
  formField,
  countrySearchText,
  setCountrySearchText,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef<any>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="custom-multi-select" ref={selectRef}>
      <div className="select-field" onClick={toggleDropdown}>
        {options?.filter((item: any) => item.isSelected)?.length === 0 ? (
          <span style={{ marginBottom: "10px", color: "#828282", fontSize: "14px", fontWeight: 400 }}>Select option</span>
        ) : options?.filter((item: any) => !item.isSelected)?.length === 0 ? (
          <span style={{ fontWeight: 500, fontSize: "14px", marginBottom: "10px", color: "rgba(51, 51, 51, 1)" }}>All</span>
        ) : options?.filter((item: any) => item.isSelected)?.length > 0 && options?.filter((item: any) => !item.isSelected)?.length > 0 ? (
          options
            ?.filter((option: any) => option.isSelected)
            .map((item: any, index: number) => {
              return (
                <span key={index} className="selectedSubItems">
                  {item.label}
                </span>
              );
            })
        ) : null}
        <div className={`dropdown-icon ${isOpen ? "open" : ""}`}>{/* <img src={dropdownIcon} alt="dropdown" style={{marginBottom:"10px"}} /> */}</div>
      </div>
      {isOpen && (
        <div className="multi-select-dropdown" style={{ padding: "0px !important" }}>
          <>
            {formField === "COUNTRY" ? (
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  // placeholder={i18n("global.Search")}
                  placeholder="Search"
                  value={countrySearchText}
                  onChange={(e) => {
                    setCountrySearchText(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  className="search-input"
                  // Add class for styling
                />
              </div>
            ) : null}

            {options?.length > 0 ? (
              <>
                {" "}
                <div className="d-flex select-all" style={{ alignItems: "center" }}>
                  <input
                    style={{ height: "16px", width: "16px", border: "none", background: "#F2F2F2" }}
                    type="checkbox"
                    checked={options?.filter((item: any) => !item.isSelected)?.length > 0 ? false : true}
                    onChange={handleSelectAll}
                    className="checkbox"
                  />
                  {/* <span style={{ margin: "0px 15px", fontWeight: 500, fontSize: "14px" }}>{i18n("Analytics.All")}</span> */}
                  <span style={{ margin: "0px 15px", fontWeight: 500, fontSize: "14px", color: "rgba(51, 51, 51, 1)" }}>All</span>
                </div>{" "}
                <hr style={{ marginTop: "0.15rem", marginBottom: "0.35rem" }} />
                <div className="options-container">
                  {" "}
                  {/* Container for options */}
                  {options?.map((option: any) => (
                    <label key={option.value} className="option-label">
                      {" "}
                      {/* Added class for styling */}
                      <div
                        onChange={() => handleSelectedItem(option.id, option.label)}
                        className="d-flex"
                        style={{ alignItems: "center", cursor: "pointer" }}
                      >
                        <input
                          style={{ height: "18px", width: "18px", border: "none", background: "#F2F2F2" }}
                          type="checkbox"
                          checked={option.isSelected}
                          className="checkbox"
                        />
                        <span
                          className="label-checkbox"
                          style={{ color: option.isDeleted === true ? "#EB5757" : "#333333" && option?.isArchived === true ? "#F2994A" : "#333333" }}
                        >
                          {/* {option.label.includes("Default Site") ? i18n(`Analytics.${option.label}`) : option.label} */}
                          {option.label}
                        </span>
                      </div>
                    </label>
                  ))}
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center", color: "#4f4f4f", margin: "auto", width: "100%", marginTop: "auto", marginBottom: "auto" }}>
                No Options
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;
