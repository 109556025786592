import Skeleton from "react-loading-skeleton";
import Table from "../table/Table";
import SingleDummyChart from "../../charts/SingleChart";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../redux/storeState/storeState";

interface StoreDetailsIProp {
  setSelectedOption: any;
  userHeadData: any;
  user: any;
  page: number;
  size: number;
  setpage: any;
  setsize: any;
  total: number;
  handleUserSearch: any;
  handleSortUserClick: any;
  handleUserSort: any;
  userSearchText: any;
  selectedUserHeader: any;
  setUserSearchText: any;
  chartsLoading: boolean;
  xAxisData?: any;
  chartsToShow: any;
  resetHandler: any;
}
function UserDetails({
  setSelectedOption,
  user,
  userHeadData,
  page,
  setpage,
  setsize,
  size,
  total,
  handleUserSearch,
  handleSortUserClick,
  handleUserSort,
  selectedUserHeader,
  setUserSearchText,
  userSearchText,
  chartsLoading,
  xAxisData,
  chartsToShow,
  resetHandler,
}: StoreDetailsIProp) {
  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));

  return (
    <div>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; Users</div>
        <button
          className="back-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Business ID {companyInfo?.businessId}</div>
      <div className="mt-3 h-100" style={{ display: "flex", gap: "10px", width: "100%" }}>
        {!chartsLoading && chartsToShow && chartsToShow?.chartsData && chartsToShow?.chartsData[0] ? (
          <>
            <SingleDummyChart
              title={chartsToShow?.chartsData[0]?.title}
              analyticsXaxisData={chartsToShow?.xAxisData}
              className=" shadow-lg mt-3  w-100 "
              selectedItem={chartsToShow?.chartsData[0]}
              color="#219653"
              totalResult={chartsToShow?.chartsData[0]?.value}
              dateToShow
            />
          </>
        ) : (
          <>
            <div className="bg-white circle p-2 w-100">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
          </>
        )}
      </div>
      <div className="mt-3">
        <Table
          setSelectedOption={setSelectedOption}
          tableHeadData={userHeadData}
          tableBodyData={user}
          page={Number(page)}
          size={Number(size)}
          setPage={setpage}
          setSize={setsize}
          total={total}
          handleSiteSearch={handleUserSearch}
          handleArrowSortClick={handleSortUserClick}
          handleSort={handleUserSort}
          searchText={userSearchText}
          selectedSiteHeader={selectedUserHeader}
          setsiteSearchText={setUserSearchText}
          subscriptionView={false}
          placeholder="Search users"
          singleDetailPath="users_single"
          resetHandler={resetHandler}
        />
      </div>
    </div>
  );
}

export default UserDetails;
