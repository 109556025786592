//validations
// eslint-disable-next-line
export const EMAIL_REGEX =
  /^[^\s@]+@[^\s@]+\.[a-z]{2,3}(?:\.[a-z]{2,3}(?![?:,"])){0,1}$/i;

// eslint-disable-next-line
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z]).{8,20}$/;
//Messages
export const AN_ERROR_OCCURED = "An error occured please try again later.";
export const REQUIRED_FIELD = "This is a required field.";
export const INVALID_EMAIL = "Invalid email format.";
export const INVALID_DATE = "Invalid date format.";
export const INVALID_PHONE = "Invalid phone number";
export const INVALID_PASSWORD =
  "Password must be at least eight characters long, Contain letters and numbers";
export const EMAIL_SENT = "Email sent successfully.";
export const EMAIL_VERIFIED = "Email verified.";
export const INVALID_CODE = "Invalid code or the code has expired.";
export const INVALID_LINK = "Invalid link or the link has expired.";
export const COMPANY_CREATED = "Company created.";
export const PASSOWRD_RESET = "Password changes successfully";

export const MIN_LENGTH = (length:number) => {
  return `Minimum ${length} symbols`;
};

export const MAX_LENGTH = (length:number) => {
  return `Maximum ${length} symbols`;
};
export const MIN_NUM_LENGTH = (length:number) => {
  return `Minimum ${length} numbers`;
};

export const MAX_NUM_LENGTH = (length:number) => {
  return `Maximum ${length} numbers`;
};
// eslint-disable-next-line
export const DATE_REGEX = /^[0-9]{2}[\/]{1}[0-9]{2}$/g;
