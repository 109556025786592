import React, { useRef, useState } from "react";
import CustomPagination from "../../../../components/pagination/CustomPagination";
import sortArrow from "../../../../assets/images/sortArrow.svg";
import searchIcon from "../../../../assets/images/searchIcon.svg";
import closeIcon from "../../../../assets/images/CloseIcon.svg";
import { handleTableDataColor } from "../../../../helper/helperFunctions";
import CustomDateSelect from "../../../../components/form-input/multi-date-select";
import dateIcon from "../../../../assets/images/dateIconSubscription.svg";
import emptyImg from "../../../../assets/images/emptyImage.svg";
function Table({
  setSelectedOption,
  tableBodyData,
  page,
  size,
  setPage,
  setSize,
  total,
  searchText,
  handleSiteSearch,
  setsiteSearchText,
  tableHeadData,
  selectedSiteHeader,
  handleArrowSortClick,
  handleSort,
  subscriptionView,
  placeholder,
  singleDetailPath,
  selectedDate,
  setIsOpen,
  isOpen,
  setselectedValue,
  selectedValue,
  options,
  setslectedLable,
  slectedLable,
  setSelectedDate,
  subscriptionTitle,
  showEmptyScreen,
  resetHandler,
}: {
  setSelectedOption: any;
  tableBodyData: any;
  page: number;
  size: number;
  setPage: any;
  setSize: any;
  total: number;
  searchText: string;
  handleSiteSearch: any;
  setsiteSearchText: any;
  tableHeadData: any;
  selectedSiteHeader: any;
  handleArrowSortClick: any;
  handleSort: any;
  subscriptionView?: boolean;
  placeholder?: string;
  singleDetailPath: string;
  selectedDate?: any;
  setIsOpen?: any;
  isOpen?: boolean;
  setselectedValue?: any;
  selectedValue?: any;
  options?: any;
  setslectedLable?: any;
  slectedLable?: any;
  setSelectedDate?: any;
  subscriptionTitle?: string;
  showEmptyScreen?: boolean;
  resetHandler?: any;
}) {
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [closeSearchBarIfValue, setCloseSearchBarIfValue] = useState(true);
  const searchRef = useRef<any>();
  const handleOutsideClickSearch = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      if (!closeSearchBarIfValue) {
        setSearchBarOpen(true);
      } else {
        setSearchBarOpen(false);
      }
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickSearch);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClickSearch);
    };

    // eslint-disable-next-line
  }, [closeSearchBarIfValue]);
  return (
    <div className="payment-card-wrap mt-2 w-100 h-100">
      {subscriptionView ? (
        <div>
          <div className="d-flex justify-content-between" style={{ gap: ".5rem", marginTop: 10, marginLeft: 10 }}>
            <div style={{ color: "#333333", fontWeight: 500, fontSize: 18, marginTop: "2px" }}>{subscriptionTitle}</div>
            <div className="searchIcon-wrap" ref={searchRef} style={{ position: "relative", width: searchBarOpen ? "100%" : "inherit" }}>
              <div style={{ width: "100%", height: "34px" }}>
                {searchBarOpen ? (
                  <input
                    value={searchText}
                    onChange={(e: any) => {
                      setsiteSearchText(e.target.value);
                      handleSiteSearch(e.target.value);
                    }}
                    type="text"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      outline: "none",
                      background: "#F2F2F2",
                      borderRadius: "6px",
                      padding: "1rem",
                      color: searchText?.length ? "#4F4F4F" : "#BDBDBD",
                      fontWeight: !searchText?.length ? 500 : 700,
                    }}
                    className="searchBar"
                    placeholder="Search"
                  />
                ) : null}

                <div style={{ position: "absolute", top: 3, right: 20 }}>
                  {searchText?.length && searchBarOpen ? (
                    <img
                      src={closeIcon}
                      alt="search icon"
                      onClick={() => {
                        setsiteSearchText("");
                        resetHandler();
                        setCloseSearchBarIfValue(true);
                      }}
                    />
                  ) : (
                    <img
                      src={searchIcon}
                      alt="search icon"
                      onClick={() => {
                        setSearchBarOpen(!searchBarOpen);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* filter date search  */}
          <>
            <div className="date-wrapper pr-3 py-3 float-right">
              <div>{/* {selectedDate?.startDate}-{selectedDate?.endDate} */}</div>
              <div>
                <img src={dateIcon} alt="date icon" onClick={() => setIsOpen(!isOpen)} />
              </div>
            </div>
            <CustomDateSelect
              setselectedValue={setselectedValue}
              selectedValue={selectedValue}
              options={options}
              setslectedLable={setslectedLable}
              slectedLable={slectedLable}
              setSelectedDate={setSelectedDate}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              subscriptionView
            />
          </>
        </div>
      ) : (
        <div className="payment-card-wrap mt-2 w-100 h-100">
          {/* <div className="d-flex justify-content-between" style={{ gap: ".5rem", marginTop: 10, marginLeft: 10 }}> */}
          <div className="searchIcon-wrap" ref={searchRef} style={{ position: "relative", width: searchBarOpen ? "100%" : "inherit" }}>
            <div style={{ width: "100%", height: "34px" }}>
              {searchBarOpen ? (
                <input
                  value={searchText}
                  onChange={(e: any) => {
                    setsiteSearchText(e.target.value);
                    handleSiteSearch(e.target.value);
                  }}
                  type="text"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    outline: "none",
                    background: "#F2F2F2",
                    borderRadius: "6px",
                    padding: "1rem",
                    color: searchText?.length ? "#4F4F4F" : "#BDBDBD",
                    fontWeight: !searchText?.length ? 500 : 700,
                  }}
                  className="searchBar"
                  placeholder="Search"
                />
              ) : null}

              <div style={{ position: "absolute", top: 3, right: 20 }}>
                {searchText?.length && searchBarOpen ? (
                  <img
                    src={closeIcon}
                    alt="search icon"
                    onClick={() => {
                      setsiteSearchText("");
                      resetHandler();
                      setCloseSearchBarIfValue(true);
                    }}
                  />
                ) : (
                  <img
                    src={searchIcon}
                    alt="search icon"
                    onClick={() => {
                      setSearchBarOpen(!searchBarOpen);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        // </div>
      )}
      <div className={`mt-3 ${!showEmptyScreen ? "custom-table-responsive" : "custom-table-responsive-specific"} table-responsive`}>
        <table className="table-main-customized table">
          <thead>
            <tr className="table-header">
              {tableHeadData?.map((item: any, index: number) => {
                return (
                  <th key={index}>
                    <div className="d-flex">
                      <span
                        style={{ cursor: "pointer", whiteSpace: "nowrap", fontSize: 14, fontWeight: 700, color: "#333333" }}
                        onClick={() => {
                          handleSort(item);
                        }}
                      >
                        {item?.label}
                      </span>
                      {selectedSiteHeader?.label === item?.label && !subscriptionView ? (
                        <span>
                          <img
                            className="ml-2"
                            id="sort-icon"
                            src={sortArrow}
                            alt="sort-arrow"
                            onClick={handleArrowSortClick}
                            style={{ verticalAlign: "initial", cursor: "pointer", zIndex: "99", color: "inherit", marginBottom: "6px" }}
                          />
                        </span>
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {!showEmptyScreen &&
              tableBodyData?.map((item: any, index: number) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      if (singleDetailPath !== "none") {
                        setSelectedOption(`${singleDetailPath}_${item?._id}`);
                      }
                      if (singleDetailPath === "subscription_details") {
                        setSelectedOption(`${singleDetailPath}_${item?.subscriptionId}`);
                      }
                    }}
                    style={{
                      fontSize: "14px",
                      fontWeight: subscriptionView ? 400 : 500,
                      color: !subscriptionView ? "#333333" : "#4F4F4F",
                      borderBottom: subscriptionView ? "1px solid #E0E0E0" : "none",
                    }}
                  >
                    {tableHeadData?.map((subItem: any, index: number) => {
                      return (
                        <td key={index}>
                          <span style={{ color: handleTableDataColor(item, subItem) }}> {item[`${subItem?.value}`]}</span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {showEmptyScreen && (
          <div className="d-flex justify-content-center align-items-center flex-column w-100 pb-4" style={{ height: "300px" }}>
            <div>
              <img src={emptyImg} alt="empty illustration" />
            </div>
            <p className="empty-text mt-3">nothing to show here yet!</p>
          </div>
        )}
        {!showEmptyScreen && <CustomPagination page={page} setSize={setSize} setpage={setPage} size={size} total={total} filterCount={total} />}
      </div>
    </div>
  );
}

export default Table;
