import SingleDummyChart from "../../charts/SingleChart";
import Skeleton from "react-loading-skeleton";
import Table from "../table/Table";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../redux/storeState/storeState";
import moment from "moment";
function CompanySubscription({
  setSelectedOption,
  subscriptionData,
  chartsLoading,
  chartsToShow,
  paymentCardData,
  subscriptionHeadData,
  page,
  size,
  setPage,
  setSize,
  total,
  handleSortSubscriptionClick,
  handleSubscriptionSort,
  selectedSubscriptionHeader,
  subscriptionSearchText,
  isOpen,
  options,
  selectedDate,
  selectedValue,
  setIsOpen,
  setSelectedDate,
  setselectedValue,
  setslectedLable,
  slectedLable,
  companySubscription,
  handleSubscriptionSearch,
  setSubscriptionSearchText,
  resetHandler,
}: {
  setSelectedOption: any;
  subscriptionData: any;
  chartsLoading?: boolean;
  chartsToShow: any;
  paymentCardData?: any;
  subscriptionHeadData?: any;
  page: number;
  size: number;
  setPage: any;
  setSize: any;
  total: number;
  handleSortSubscriptionClick: any;
  handleSubscriptionSort: any;
  subscriptionSearchText: any;
  selectedSubscriptionHeader: any;
  selectedDate?: any;
  setIsOpen?: any;
  isOpen?: boolean;
  setselectedValue?: any;
  selectedValue?: any;
  options?: any;
  setslectedLable?: any;
  slectedLable?: any;
  companySubscription?: any;
  setSelectedDate?: any;
  handleSubscriptionSearch?: any;
  setSubscriptionSearchText?: any;
  resetHandler: any;
}) {
  const { companyInfo } = useSelector((state: StoreState) => ({
    companyInfo: state.companies.companySideInfoData,
  }));
  let refactoredSubscription = subscriptionData.map((item: any) => {
    return {
      ...item,
      plan: item?.currentPlan?.plan,
      status: item?.currentPlan?.status,
      amount: item?.invoiceData?.paymentMetaData?.totalPaid || item?.currentPlan?.price,
      subscriptionStartedAt: `${moment(item?.subscriptionStartedAt).format("MMM DD, YYYY")}`,
      subscriptionEndedAt: `${moment(item?.subscriptionEndedAt).format("MMM DD, YYYY")}`,
      service: "User management",
    };
  });
  return (
    <>
      <div className="main-overview-wrap">
        <div className="title-subscription">Companies &gt; {companyInfo?.businessName} &gt; Subscription</div>
        <button
          className="back-btn"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </button>
      </div>
      <div className="subscription-id">Business ID {companyInfo?.businessId}</div>
      <div className="mt-2" style={{ display: "flex", gap: "10px", width: "100%" }}>
        {!chartsLoading && chartsToShow && chartsToShow[0] ? (
          <>
            <SingleDummyChart
              title={chartsToShow[0][0]?.title}
              analyticsXaxisData={chartsToShow[1]}
              className=" shadow-lg mt-3  w-100 "
              selectedItem={chartsToShow[0][0]}
              color="#219653"
              totalResult={chartsToShow[0][0]?.value}
            />
            <SingleDummyChart
              title={chartsToShow[0][1]?.title}
              analyticsXaxisData={chartsToShow[1]}
              className=" shadow-lg mt-3  w-100 "
              selectedItem={chartsToShow[0][1]}
              color="#9B51E0"
              totalResult={chartsToShow[0][1]?.value}
            />
          </>
        ) : (
          <>
            <div className="bg-white circle p-2 w-100">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
            <div className="bg-white circle p-2 w-100">
              <div className="d-flex justify-content-between align-items-center p-2" style={{ gap: "1rem", width: "100%" }}>
                <Skeleton width={50} />
                <Skeleton width={50} />
              </div>
              <Skeleton count={5} />
            </div>
          </>
        )}
      </div>

      <div className="d-flex mt-1" style={{ gap: "10px" }}>
        <div className="payment-card-wrap mt-2 w-100">
          <p className="title">Payment Card:</p>
          <div className="rows-wrap mb-2">
            <div>
              {paymentCardData?.brand} : <span>**********</span>
              {paymentCardData?.last_four}
            </div>
            <div>
              {paymentCardData?.month} / {paymentCardData?.year}
            </div>
          </div>
          <div className="rows-wrap">
            <div>Credit</div>
            <div>SAR {companyInfo?.subscription?.credit}</div>
          </div>
        </div>
        <div className="payment-card-wrap mt-2 w-100">
          <p className="title">Billing Details :</p>
          <div className="rows-wrap my-2">
            <div>Tax Number</div>
            <div>{companyInfo?.taxNumber}</div>
          </div>
          <div className="rows-wrap">
            <div>Currency</div>
            <div>SAR</div>
          </div>
        </div>
      </div>

      <div>
        <Table
          setSelectedOption={setSelectedOption}
          tableHeadData={subscriptionHeadData}
          tableBodyData={refactoredSubscription}
          page={page}
          size={size}
          setPage={setPage}
          setSize={setSize}
          total={total}
          handleSiteSearch={handleSubscriptionSearch}
          handleArrowSortClick={handleSortSubscriptionClick}
          handleSort={handleSubscriptionSort}
          searchText={subscriptionSearchText}
          selectedSiteHeader={selectedSubscriptionHeader}
          setsiteSearchText={setSubscriptionSearchText}
          subscriptionView={true}
          singleDetailPath={`subscription_details`}
          isOpen={isOpen}
          options={options}
          selectedDate={selectedDate}
          selectedValue={selectedValue}
          setIsOpen={setIsOpen}
          setSelectedDate={setSelectedDate}
          setselectedValue={setselectedValue}
          setslectedLable={setslectedLable}
          slectedLable={slectedLable}
          subscriptionTitle="Subscription"
          resetHandler={resetHandler}
        />
      </div>
    </>
  );
}

export default CompanySubscription;
