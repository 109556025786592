import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppLayout from "./container/app-layout";

function App() {
  return (
    <div>
      <BrowserRouter>
        <AppLayout />
      </BrowserRouter>
    </div>
  );
}

export default App;
