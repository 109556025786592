
import AppRoutes from "../../Routes/AppRoutes"

function AppLayout() {
  return (
     <div>
        <AppRoutes/>
    </div>
  )
}

export default AppLayout